import React, { useState, useEffect, Fragment } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './TabLinks.scss';
import Typography from '@mui/material/Typography';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import { Link, useNavigate } from 'react-router-dom';
import PopOver from 'components/PopOver/PopOver';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children } = props;
  return <div>{children}</div>;
}

export default function TabLinks() {

  // React.useEffect(() => {
  //   const storedValue = sessionStorage.getItem('lastSelectedTab') || 'sic';
  //   setMenuValue(storedValue);
  
  //   // Now, you can conditionally call the appropriate API based on the stored value
  //   if (storedValue === 'isic' || storedValue === 'nace') {
  //     getSearchResultsisic(storedValue);
  //   } else {
  //     getSearchResults();
  //   }
  // }, []);
  
  const storedValue = sessionStorage.getItem('lastSelectedTab') || 'sic';
  const [menuValueSelected, setMenuValue] = React.useState(storedValue);
  const navigate = useNavigate();

  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
    if('isic' == newValue || 'nace' == newValue || 'nic' == newValue || 'uksic' == newValue){
      getSearchResultsisic(newValue);
    }
    else{
      getSearchResults();
    }
    sessionStorage.setItem('lastSelectedTab', newValue);

  };

  const [popUp, showPopUp] = useState(false);
  const [selectedValue, selectedValueMethod] = useState({});
  let [currentTarget, setCurrentTarget] = useState([]);
  let [activeIndex, setActiveIndex] = useState<string>('');

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  let [searchResultsisic, setSearchResultsisic]: any = useState<any[]>([]);

  useEffect(() => {
    const storedValue = sessionStorage.getItem('lastSelectedTab') || 'sic';
    setMenuValue(storedValue);

    if (storedValue === 'isic' || storedValue === 'nace' || storedValue === 'nic' || storedValue === 'uksic') {
      getSearchResultsisic(storedValue);
    } else {
      getSearchResults();
    }

    const handleBeforeUnload = () => {
      // Update sessionStorage value to 'sic' before the page is unloaded
      sessionStorage.setItem('lastSelectedTab', 'sic');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const getSearchResults = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/sic-naics',
      success: (jsonResponse) => { 
        setSearchResults(jsonResponse.data);
      },
      error: (error) => console.log(error)
    });
  };


  const getSearchResultsisic = (x:any) => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/'+x,
      success: (jsonResponse) => {
        var jsonString = JSON.stringify(jsonResponse.data);
        sessionStorage.setItem('selectedtabvalue', jsonString);
        setSearchResults(jsonResponse.data);
      },
      error: (error) => console.log(error)
    });
  };

  const navigateToStructure = (
    event: any,
    type: any,
    menuValueSelected: any
  ) => {
    var data: any = {
      type: type,
      data: menuValueSelected
    };
    if (!Number(menuValueSelected.code_range)) {
      // alert(JSON.stringify(data))
      setCurrentTarget(event.currentTarget);
      activeIndex = event.currentTarget.dataset.src;
      setActiveIndex(activeIndex);
      showPopUp(!popUp);
      selectedValueMethod(data);
    } else {
      // alert(JSON.stringify(data))
      var type = data?.type
      var code = menuValueSelected?.sic_code || menuValueSelected?.code_range;
      navigate('/structure/' + type + '/' + code);
    }
  };

  const [popup, setPop] = useState(false);

  const handleClick = () => {
    setPop(!popup);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={menuValueSelected}
        onChange={menuChange}
        aria-label="secondary tabs example"
        centered
        className="home-tabs"
      >
        <Tab value="sic" label="SIC" />
        <Tab value="naics" label="NAICS" />
        <Tab value="isic" label="ISIC" />
        <Tab value="nace" label="NACE" />
        <Tab value="nic" label="NIC" />
        <Tab value="uksic" label="UKSIC" />
      </Tabs>
      <TabPanel value={menuValueSelected}>
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          style={{ marginTop: '40px' }}
        >
          <div className="row g-2 hover-list ">
            {searchResults?.map((item: any, i: number) => {
              let length_1 = Math.ceil((item?.[menuValueSelected]?.length) / 3);
              let length_2 = length_1 * 2;
              return (
                <Fragment key={i}>
                  <div className="col-md-4">
                    {item?.[menuValueSelected]?.map((data: any, j: number) => {
                      return (
                        <Fragment key={j}>
                          {j < length_1 &&
                            <div style={{ width: '100%' }}
                              className="row"
                              key={j}
                              id={`tab` + j}
                              data-src={data.division}
                              onClick={(event: any) =>
                                navigateToStructure(event, menuValueSelected, data)
                              }>
                              <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data">
                                <div className="col-3 code_range">
                                  <strong>{data.code_range}</strong>
                                </div>
                                <div className="col-8 tab-short-desc align-items-center short_desc">
                                  {data.short_desc}
                                </div>
                                <Fragment>
                                  {Number(data.code_range) ? "" : <div className="col-1 text-end">
                                    {activeIndex.includes(data.division) ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </div>}
                                </Fragment>
                              </div>
                            </div>}
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="col-md-4">
                    {item?.[menuValueSelected]?.map((data: any, j: number) => {
                      return (
                        <Fragment key={j}>
                          {j >= length_1 && j < length_2 && 
                            <div style={{ width: '100%' }}
                              className="row"
                              key={j}
                              id={`tab` + j}
                              data-src={data.division}
                              onClick={(event: any) =>
                                navigateToStructure(event, menuValueSelected, data)
                              }>
                              <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data">
                                <div className="col-3 code_range">
                                  <strong>{data.code_range}</strong>
                                </div>
                                <div className="col-8 tab-short-desc align-items-center short_desc">
                                  {data.short_desc}
                                </div>
                                <Fragment>
                                  {Number(data.code_range) ? "" : <div className="col-1 text-end">
                                    {activeIndex.includes(data.division) ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </div>}
                                </Fragment>
                              </div>
                            </div>}
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="col-md-4">
                    {item?.[menuValueSelected]?.map((data: any, j: number) => {
                      return (
                        <Fragment key={j}>
                          {j >= length_2 &&
                            <div
                              className="row"
                              key={j}
                              id={`tab` + j}
                              data-src={data.division}
                              onClick={(event: any) =>
                                navigateToStructure(event, menuValueSelected, data)
                              }>
                              <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data">
                                <div className="col-3 code_range">
                                  <strong>{data.code_range}</strong>
                                </div>
                                <div className="col-8 tab-short-desc align-items-center short_desc">
                                  {data.short_desc}
                                </div>
                                <Fragment>
                                  {Number(data.code_range) ? "" : <div className="col-1 text-end">
                                    {activeIndex.includes(data.division) ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </div>}
                                </Fragment>
                              </div>
                            </div>}
                        </Fragment>
                      );
                    })}
                  </div>
                </Fragment>
              )
            })}

            {popUp && (
              <PopOver
                showPopup={showPopUp}
                popup={popUp}
                targetElement={currentTarget}
                setActiveIndex={setActiveIndex}
                data={selectedValue}
              ></PopOver>
            )}
          </div>
        </div>
      </TabPanel>
    </Box>
  );
}
