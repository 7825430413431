import React, { useState } from 'react';
import {
  Container,
  Box,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import './NavBar.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import Home from 'components/Home/Home';
import { render } from 'react-dom';
import { display, style } from '@mui/system';
import Menu from 'views/Menu';
import closeCross from '../../assets/close-cross.png';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';

export default function NavBar(this: any, props: any) {

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;

  const [dropDownValue, setdropDownValue] = React.useState(codeType || "sic");
  const changeDropDown = (event: any) => {
    setdropDownValue(event.target.value);
  }
  const selectingDropDown = (event: any) => {
    navigate('/codeSystem/'+event.target.value);
  }

  const [message, setMessage] = useState('');
  const handleChange = (event: any) => {
    setMessage(event.target.value);
  }

  const navigate = useNavigate();
  const location = useLocation();


  const navigateToStructure = () => {
    navigate('/search/' + dropDownValue + '/' + message);
    setToggle(!toggle);
    navigate(0);
  };

  const keyPress = (e: any) => {
    if (e.charCode == 13) {
      navigateToStructure()
    }
  }

  const examplesRef = React.useRef(null);

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 90);
  function moveToRef(ref: any) {
    scrollToRef(ref);
  }
  const [toggle, setToggle] = useState(false)

  const [megaMenuStatus, setMegaMenuStatus] = useState(false)

  const handleClick = () => {
    if (pageurl != "/") {
      setToggle(!toggle);
    }
    else {
      props.onSelectLanguage();
    };
  }

  const openMenu = (status: any) => {
    if (status) {
      // window.scrollTo(0,0);
    }
    setMegaMenuStatus(status);
    // props.onOpenMenu(status);
  }

  const goHome = () => {
    sessionStorage.setItem('lastSelectedTab', 'sic');
    
    // Check if the current route is already the home page
    if (location.pathname === '/') {
      // If on the home page, open in a new tab
      window.open('/', '_blank');
    } else {
      // If on any other page, navigate to the home page
      navigate('/', { replace: true });
    }
  };



  const switchRoute = (value: any) => {
    navigate(value, {
      replace: true
    });
    setMegaMenuStatus(false);
    // props.onOpenMenu(false);
  }

  const pageurl = window.location.pathname;


  const searchRef = React.useRef(null);

  const [codeData, setCodeData]: any = useState({});


  React.useEffect(() => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'allCodes',
      success: (jsonResponse) => {
        setCodeData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  }, []);

  return (
    megaMenuStatus == true ?
      <div className="menu-container">

        <section className="home-search p-5" ref={searchRef}>
          <div className="container">
            <div className="row g-3 menu-header-row">
              <div className="col-md-5" style={{ alignSelf: "center" }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="h1"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                  className="logo project-header-text"
                  style={{
                    fontSize: '1.25rem',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold'
                  }}
                  onClick={goHome}
                >
                  IndustryCodes
                </Typography>
              </div>
              <div className="col-md-6" style={{display:"flex", alignItems:"center"}}>
                <Typography
                  variant="h6"
                  noWrap
                  component="h2"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                  className="logo project-header-text"
                  style={{
                    fontSize: '1.25rem',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    textAlign: "end",
                    marginRight: "20px"
                  }}
                  onClick={goHome}
                >
                  Know about
                </Typography>
                <Box sx={{ width: 280}}>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={dropDownValue}
                      displayEmpty
                      onChange={selectingDropDown}
                      inputProps={{
                        'aria-label': 'Without label',
                        'background-color': '#ffffff',
                      }}
                      style={{ height: "40px"}}
                      className="box-white"
                      sx={{
                        "&.Mui-focused": {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgb(240, 98, 64)',
                          }
                        }
                      }}
                    >
                      {
                        codeData?.data?.data?.map((item: any, i: number) => {
                          return (
                            <MenuItem value={item?.codel}>{item?.codeu}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </Box>
              </div>
              {/* <div className="col-md-4">
                 <TextField 
                  fullWidth
                  style={{ fontSize: '2rem' }}
                  type="text"
                  id="message"
                  name="message"
                  onChange={handleChange}
                  value={message}
                  inputProps={{
                    'aria-label': 'Without label',
                    'background-color': '#ffffff',
                  }}
                  className="box-white"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(240, 98, 64)',
                      }
                    }
                  }}
                />
              </div> */}
              <div className="col-md-1" style={{ display: "flex", alignItems: "center" }}>
                <img src={closeCross} className="close-icon" style={{ cursor: 'pointer' }} onClick={() => openMenu(false)}></img>
              </div>

            </div>
          </div>
        </section>

        <section className="main-banner">
          <div className="container">


            <div className="container-fluid menu-page-text">
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col-sm-4 ">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/')}>Home</p>
                  <span>Discover the ultimate resource for industry classification codes worldwide and unlock valuable insights for your business.</span>
                </div>
                {/* <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/about')}>About IndustryCodes</p>
                  <span>Learn more about our comprehensive platform that collates industry classification systems from around the world, empowering informed decision-making.</span>
                </div> */}
                <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/about')}>About IndustryCodes</p>
                  <span>Learn more about our comprehensive platform that collates industry classification systems from around the world, empowering informed decision-making.</span>
                </div>
                {/* <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/codeSystem')}>Code Systems</p>
                  <span>Gain insights into the purpose and significance of industry classification codes, facilitating market analysis and economic research.</span>
                </div> */}
                <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/codeSystem')}>Code Systems</p>
                  <span>Gain insights into the purpose and significance of industry classification codes, facilitating market analysis and economic research.</span>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/codeConversion')}>Code Conversion</p>
                  <span>Simplify your industry code conversions and cross-referencing with our powerful tools, streamlining data analysis and integration.</span>
                </div>
                <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/contact')}>Contact Us</p>
                  <span>Have questions or feedback? Reach out to our team for assistance, collaborations, or inquiries regarding industry classification codes. We're here to help!</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/termsAndConditions')}>Terms & conditions</p>
                </div>
                <div className="col-sm-4">
                  <p className="menu-option-title" style={{ cursor: "pointer" }} onClick={() => switchRoute('/privacy')}>Privacy</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      :

      <Box>
        <AppBar
          className="header p-3 "
          style={{ backgroundColor: '#252525', boxShadow: 'none' }}
        >
          <div className="container">
            <Toolbar className="p-0" >
              <Typography
                variant="h6"
                noWrap
                component="h1"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                className="logo project-header-text"
                style={{
                  fontSize: '1.25rem',
                  fontFamily: 'Poppins',
                  fontWeight: 'bold'
                }}
                onClick={goHome}
              >
                IndustryCodes
              </Typography>
              {/* <IconButton size="large" aria-label="search" color="inherit"
             onClick={ () => navigateToSearch()}>
               <SearchIcon style={{ fontSize: '1.8rem' }}/>    
            </IconButton> */}

              <IconButton size="large" aria-label="search" color="inherit"
                onClick={handleClick}>
                <SearchIcon style={{ fontSize: '1.8rem' }} />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 4 }}
                onClick={() => openMenu(true)}>
                <MenuIcon style={{ fontSize: '1.8rem' }} />
              </IconButton>

            </Toolbar>
            {toggle ? <section className="home-search p-5">
              <div className="container">
                <div className="row g-3">
                  <div className="col-md-4">
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        value={dropDownValue}
                        displayEmpty
                        onChange={changeDropDown}
                        inputProps={{
                          'aria-label': 'Without label',
                          'background-color': '#ffffff'
                        }}                        
                        className="box-white"
                        sx={{
                          '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'rgb(240, 98, 64)'
                            }
                          }
                        }}
                      >
                       {codeData?.data?.data?.map((item: any, i: number) => {
                        return (
                          <MenuItem value={item?.codel}>{item?.codeu}</MenuItem>
                        );
                      })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    {/* <SearchIcon/> */}
                    <TextField
                      placeholder="Search by Keywords or Code"
                      fullWidth
                      style={{ fontSize: '2rem', backgroundColor: '#ffffff' }}
                      type="text"
                      id="message"
                      name="message"
                      onChange={handleChange}
                      onKeyPress={keyPress}
                      value={message}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'rgb(240, 98, 64)',
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button
                      onClick={() => navigateToStructure()}
                      style={{
                        backgroundColor: message?.length <= 0 ? '' : '#f06240',
                        borderRadius: '3px',
                        minHeight: '48px',
                        height: '100%',
                        textTransform: 'none',
                        fontSize: '1.2rem',
                        width: '100%',
                      }}
                      disabled={message?.length <= 0}
                      variant="contained"
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </section> : ""}
          </div>
        </AppBar>
      </Box>
  );
}
