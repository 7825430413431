import React, { useState } from 'react';

import { Container, Box, Button, TextField, FormControl, Select, MenuItem } from '@mui/material';
import flagImg from 'assets/flag.png';
import arrowdownImg from 'assets/arrowdown.png';
import closeCross from '../assets/close-cross.png'; 
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';


export default function Menu(props: any) {

  React.useEffect(() => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'allCodes',
      success: (jsonResponse) => {
        console.log("jsonResponse")
        setCodeData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  }, []);
  
  const searchRef = React.useRef(null);
  const [dropDownValue, setdropDownValue] = React.useState('sic');

  const changeDropDown = (event: any) => {
    setdropDownValue(event.target.value);
  }

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  } 

  const [message, setMessage] = useState('');


  const [codeData, setCodeData]: any = useState({});

  return (
    <div className="menu-container">

      <section className="home-search p-5" ref={searchRef}>
        <div className="container">
          <div className="row g-3 menu-header-row">
            <div className="col-md-4"></div>
            <div className="col-md-3">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={dropDownValue}
                    displayEmpty
                    onChange={changeDropDown}
                    inputProps={{
                      'aria-label': 'Without label',
                      'background-color': '#ffffff',
                    }}
                    className="box-white"
                    sx={{
                      "&.Mui-focused": {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgb(240, 98, 64)',
                        }
                      }
                    }}
                  >
                    {
                      codeData?.data?.data?.map((item: any, i: number) => {
                        return (
                          <MenuItem value={item?.codel}>{item?.codeu}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-4">
              {/* <SearchIcon/> */}
              <TextField
                placeholder="Search by Keywords or Code"
                fullWidth
                style={{ fontSize: '2rem' }}
                type="text"
                id="message"
                name="message"
                onChange={handleChange}
                value={message}
                inputProps={{
                  'aria-label': 'Without label',
                  'background-color': '#ffffff',
                }}
                className="box-white"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgb(240, 98, 64)',
                    }
                  }
                }}
              />
            </div>
            <div className="col-md-1" style={{ display: "flex", alignItems: "center" }}>
              <img src={closeCross} className="close-icon"></img>

            </div>

          </div>
        </div>
      </section>

      <section className="main-banner">
        <div className="container">


          <div className="container-fluid menu-page-text">
            <div className="row" style={{ marginBottom: "100px", height: '100%', margin: "0px 0px" }}>
              <div className="col-sm-4 ">
                <p className="menu-option-title">Home</p>
                <span>liquam ac neque nisl. Cras nec elit in mauris semper mollis. Nam ultricies lectus sit amet nulla convallis, sit amet condimentum augue dignissim. Sed sit amet nisl tempus.</span>
              </div>
              <div className="col-sm-4">
                <p className="menu-option-title">About</p>
                <span>liquam ac neque nisl. Cras nec elit in mauris semper mollis. Nam ultricies lectus sit amet nulla convallis, sit amet condimentum augue dignissim. Sed sit amet nisl tempus.</span>
              </div>
              <div className="col-sm-4">
                <p className="menu-option-title">Code systems</p>
                <span>liquam ac neque nisl. Cras nec elit in mauris semper mollis. Nam ultricies lectus sit amet nulla convallis, sit amet condimentum augue dignissim. Sed sit amet nisl tempus.</span>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "100px" }}>
              <div className="col-sm-4">
                <p className="menu-option-title">Code conversion</p>
                <span>liquam ac neque nisl. Cras nec elit in mauris semper mollis. Nam ultricies lectus sit amet nulla convallis, sit amet condimentum augue dignissim. Sed sit amet nisl tempus.</span>
              </div>
              <div className="col-sm-4">
                <p className="menu-option-title">Contact</p>
                <span>liquam ac neque nisl. Cras nec elit in mauris semper mollis. Nam ultricies lectus sit amet nulla convallis, sit amet condimentum augue dignissim. Sed sit amet nisl tempus.</span>
              </div>
              <div className="col-sm-4">
                <p className="menu-option-title">What is SIC code ?</p>
                <span>liquam ac neque nisl. Cras nec elit in mauris semper mollis. Nam ultricies lectus sit amet nulla convallis, sit amet condimentum augue dignissim. Sed sit amet nisl tempus.</span>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "100px" }}>
              <div className="col-sm-4">
                <p className="menu-option-title">Terms & conditions</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
