import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box, Button } from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OutlinedInput from '@mui/material/OutlinedInput';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import arrowsImg from 'assets/arrows.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Switch from '@mui/material/Switch';
import BasicAccordion from 'components/BasicAccordion/BasicAccordion';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function Structure() {
  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;


  useEffect(() => {
    window.scrollTo(0, 0);
    getCodeDetail();
    getStructureDetail();
  }, []);

  const navigate = useNavigate();

  const navigateToStructure = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    navigate('/');
  };

  const getStructureDetail = () => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'code/hierarchy',
      params: {
        [codeType]: codeValue,
        code: codeType === 'sic' ? '[2,3,4]' : '[2,3,4,5,6,7,8]',
      },
      success: (jsonResponse) => {
        const modifiedData = removeUnwantedFields(jsonResponse?.data);
        setStructureDetails(modifiedData);
      },
      error: (error) => console.log(error),
    });
  };
  
  const removeUnwantedFields = (data:any) => {
    const { division, naics_dig, naics_code, isic_dig, isic_code, nace_dig, nace_code, nic_dig, nic_code, uksic_dig, uksic_code, short_desc, ...rest } = data;
    return Object.values(rest).map((level1: any) => {
      return {
        ...level1,
        opened: level1.opened === undefined ? false : false,
      };
    });
  };


  

  const getCodeDetail = () => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'code/BasedOnId',
      params: {
        [codeType]: codeValue,
        code: codeType == 'sic' ? '[2,3,4]' : '[2,3,4,5,6,7,8]'
      },
      success: (jsonResponse) => {
        setCodeDetails(jsonResponse?.data?.[0]?.data?.[0]);
      },
      error: (error) => console.log(error)
    });
  };

  let [structureDetails, setStructureDetails]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);

  const [data, setData] = useState('');

  return (
    <div className="App inner-pages">
      <Helmet>
        <title>{`${codeType} ${codeValue} ${codeDetails?.short_desc?.toLowerCase()}`}</title>
        <meta
          name="description"
          content={`${codeType} ${codeValue} : ${codeDetails?.short_desc?.toLowerCase()}`}
        />
        <meta
          name="keywords"
          content={`${codeType} ${codeValue} : ${codeDetails?.short_desc?.toLowerCase()}`}
        />
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center">
              <h2 className="mb-0 text-start">
                Structure of {codeType.toUpperCase()} Code{' '}
              </h2>
              <p className="inner-su-title">
                Section {codeValue} - {codeDetails?.short_desc}
              </p>
              {/* <p className="inner-su-title">
                {codeDetails?.long_desc}
              </p> */}
              <a href="" onClick={(event) => navigateToStructure(event)}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <main className="pb-5">
          <section className="py-5">
            <div className="container py-5">
              <div className="circle-connectors mb-5">
                <ul className="connector-one">
                  <li className="circle circle-pink">
                    <span>Broad</span>
                  </li>
                  <li className="circle-pink">
                    <ChevronRightIcon />
                  </li>

                  {codeType == 'sic' && (
                    <li className="circle circle-green">
                      <span>Specific</span>
                    </li>
                  )}

                  {codeType == 'isic' && (
                    <li className="circle circle-green">
                      <span>Specific</span>
                    </li>
                  )}

                  {codeType == 'nace' && (
                    <li className="circle circle-green">
                      <span>Specific</span>
                    </li>
                  )}

                  {codeType == 'nic' && (
                    <li className="circle circle-green">
                    </li>
                  )}
                  {codeType == 'nic' && (
                    <li className="circle-green">
                      <ChevronRightIcon />
                    </li>
                  )}

                  {codeType == 'uksic' && (
                    <li className="circle circle-green">
                    </li>
                  )}
                  {codeType == 'uksic' && (
                    <li className="circle-green">
                      <ChevronRightIcon />
                    </li>
                  )}

                  {codeType == 'naics' && (
                    <li className="circle circle-green"></li>
                  )}
                  {codeType == 'naics' && (
                    <li className="circle-green">
                      <ChevronRightIcon />
                    </li>
                  )}

                  {codeType == 'naics' && (
                    <li className="circle circle-yellow"></li>
                  )}
                  {codeType == 'naics' && (
                    <li className="circle-yellow">
                      <ChevronRightIcon />
                    </li>
                  )}

                  {codeType == 'naics' && (
                    <li className="circle circle-blue">
                      <span>Specific</span>
                    </li>
                  )}

                  {codeType == 'nic' && (
                    <li className="circle circle-yellow">
                      <span>Specific</span>
                    </li>
                  )}

                  {codeType == 'uksic' && (
                    <li className="circle circle-yellow">
                      <span>Specific</span>
                    </li>
                  )}
                </ul>
              </div>
              <div>
                <ul className="digit-block d-flex flex-md-row flex-column pt-5">
                  <li className="col-3">3 Digit</li>
                  <li className="col-3">4 Digit</li>
                  {codeType == 'naics' && <li className="col-3">5 Digit</li>}
                  {codeType == 'nic' && <li className="col-3">5 Digit</li>}
                  {codeType == 'naics' && <li className="col-3">6 Digit</li>}
                </ul>
              </div>
              <BasicAccordion structureDetails={structureDetails} />
            </div>
          </section>
        </main>
      </Box>
      <Footer />
    </div>
  );
}
