import React, { useEffect, useMemo, useRef, useState } from 'react';
import {Helmet} from "react-helmet";
import { callAPI } from 'helpers/api';
import { Box } from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTable, useGlobalFilter } from 'react-table';
import { COLUMNS } from './table';
import { BASE_URL } from 'config/apiconfig';
import { GlobalFilter } from './GlobalFilter';

export default function Duplicatecodesystem(props: any) {
  const [currentlocation, setcurrentlocation]: any = useState([]);

  React.useEffect(() => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'countryList',
      success: (jsonResponse) => {
        setcurrentlocation(jsonResponse.data.rows);
      },
      error: (error) => console.log(error)
    });
    window.scrollTo(0, 0);
  }, []);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => currentlocation, [currentlocation]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  }: any = useTable(
    {
      columns,
      data
    },
    useGlobalFilter
  );

  const { globalFilter }: any = state;

  const [megaMenuStatus, setMegaMenuStatus] = useState(false);

  const switchRoute = (value: any) => {
    navigate(value, {
      replace: true
    });
    setMegaMenuStatus(false);
    // props.onOpenMenu(false);
  };

  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };

  const navigateToStructure = () => {
    navigate('/');
  };

  const changeDropDown = (event: any) => {
    setdropDownValue(event.target.value);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;

  const [dropDownValue, setdropDownValue] = React.useState(codeType || 'sic');

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Understanding Industry Classification Code Systems | IndustryCodes</title>    
      <meta name=" Industry classification codes play a crucial role in organizing businesses and industries based on economic activities. Learn about Industry code systems followed across countries, their significance, benefits, and applications at IndustryCodes."/>
      <meta name="keywords" content=" industry classification codes, NAICS, ISIC, SIC, NACE,  Industry code systems by country, Market analysis, Economic research, Policy development, Data integration, Global business landscape."/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">Code System</h2>

              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>

        <section className="know-about-blok py-1">
          <div
            className="container"
            style={{ marginTop: '56px', marginBottom: '56px' }}
          >
            <div className="row">
              <div className="col-md-12">
                <h2 className="block-title pb-md-3">
                  Understanding Industry Classification Codes
                </h2>
                <div>
                  Industry classification codes play a vital role in organizing
                  and categorizing businesses and industries based on their
                  economic activities. These standardized systems provide a
                  common language for analysis and comparison across sectors and
                  countries. At IndustryCodes, we aim to provide a comprehensive
                  understanding of industry classification codes and their
                  significance in the global business landscape.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Purpose of Industry Classification Codes
                </h2>
                <div>
                  Industry classification codes serve as a structured framework
                  for organizing and comprehending the diverse range of economic
                  activities. By assigning unique codes to each industry, these
                  systems simplify data organization, facilitate market
                  research, and enable statistical reporting. They allow
                  businesses, researchers, and policymakers to analyze industry
                  trends, track economic indicators, and make informed
                  decisions.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Benefits and Applications
                </h2>
                <p>
                  Industry classification codes offer a wide range of benefits
                  and find applications in various domains:
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    1. Market Analysis
                  </span>
                  : By utilizing classification codes, businesses and analysts
                  can segment markets, identify key trends, and assess
                  competitive landscapes, enabling strategic decision-making.
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    2. Economic Research
                  </span>
                  : Classification systems provide a standardized structure for
                  studying industries' contributions to GDP, employment, and
                  productivity, helping economists and researchers analyze
                  economic patterns.
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    3. Policy Development
                  </span>
                  : Governments and policymakers rely on industry classification
                  codes to develop targeted policies, monitor regulatory
                  compliance, and evaluate the impact of economic interventions.
                </p>
                <div className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    4. Data Integration
                  </span>
                  : These codes facilitate data integration from multiple
                  sources, harmonizing information for meaningful comparisons
                  and analysis across industries and regions.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Common Industry Classification Systems:
                </h2>
                <p>
                  Several industry classification systems are widely used
                  worldwide. Here are some prominent examples:
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    1. North American Industry Classification System (NAICS)
                  </span>
                  : Predominantly used in North America, including the United
                  States, Canada, and Mexico.
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    2. International Standard Industrial Classification (ISIC)
                  </span>
                  : Developed by the United Nations, it is a globally recognized
                  classification system.
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    3. Standard Industrial Classification (SIC)
                  </span>
                  : Primarily used in the United Kingdom and several other
                  countries.
                </p>
                <p className="ps-3">
                  <span style={{ fontWeight: '700', color: 'black' }}>
                    4. Nomenclature of Economic Activities (NACE)
                  </span>
                  : Developed by the member states of European Union and the
                  European Commission and is primarily used in the European
                  Union.
                </p>
                <div>
                  These classification systems typically employ hierarchical
                  structures, grouping industries into sectors, sub-sectors, and
                  specific industry codes.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Know more About
                </h2>

                <div className="row g-2 hover-list">
                  <div className="col-xs-4">
                    <div className="row">
                      <div
                        className="col-4"
                        style={{ maxWidth: '100%' }}
                        onClick={() => switchRoute('sic')}
                      >
                        <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data justify-content-center">
                          <div className="col-3 code_range">SIC</div>
                        </div>
                      </div>
                      <div
                        className="col-4"
                        style={{ maxWidth: '100%' }}
                        onClick={() => switchRoute('naics')}
                      >
                        <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data justify-content-center">
                          <div className="col-3 code_range">NAICS</div>
                        </div>
                      </div>
                      <div
                        className="col-4"
                        style={{ maxWidth: '100%' }}
                        onClick={() => switchRoute('isic')}
                        >
                        <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data justify-content-center">
                          <div className="col-3 code_range">ISIC</div>
                        </div>
                      </div>
                      <div className="col-4" 
                      style={{ maxWidth: '100%' }}
                      onClick={() => switchRoute('nace')}
                      >
                        <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data justify-content-center">
                          <div className="col-3 code_range">NACE</div>
                        </div>
                      </div>
                      <div className="col-4" 
                      style={{ maxWidth: '100%' }}
                      onClick={() => switchRoute('nic')}
                      >
                        <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data justify-content-center">
                          <div className="col-3 code_range">NIC</div>
                        </div>
                      </div>
                      <div className="col-4" 
                      style={{ maxWidth: '100%' }}
                      onClick={() => switchRoute('uksic')}
                      >
                        <div className="d-flex align-items-center p-3 border bg-light pointer rangre_data justify-content-center">
                          <div className="col-3 code_range">UKSIC</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Industry code systems followed across countries
                </h2>
                <div>
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div>
                <div className="table_container">
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column: any , columnIndex :any) => (
                            <th {...column.getHeaderProps()}
                            style={{
                              width: columnIndex === 0 ? '22%' : columnIndex === 1 ? '16%' : '62%',
                            }}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps}>
                      {rows.map((row: any) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any , cellIndex :any) => {
                              return (
                                <td {...cell.getCellProps()}
                                style={{
                                  width: cellIndex === 0 ? '22%' : cellIndex === 1 ? '16%' : '62%',
                                }}>
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <p>
                  At IndustryCodes, we strive to provide a comprehensive
                  understanding of industry classification codes and their
                  practical implications. By embracing these codes, businesses,
                  researchers, and policymakers can gain valuable insights into
                  industry trends, market dynamics, and economic indicators.
                </p>
                <p>
                  We invite you to explore our website, where you can delve
                  deeper into industry classifications, access our curated
                  database, and engage with a vibrant community of professionals
                  and enthusiasts. Understanding industry classification codes
                  empowers you to navigate the complex global business
                  environment more effectively.
                </p>
                <div>
                  Remember, at IndustryCodes, we believe that knowledge of
                  industry classification codes is a key to unlocking
                  opportunities and making informed decisions in today's
                  interconnected world.
                </div>
              </div>
            </div>
          </div>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
