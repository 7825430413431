import * as React from 'react';
import { Helmet } from 'react-helmet';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import naceTimeline from 'assets/uksic-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function NACE(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const historyRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/codeSystem');
  };

  //   React.useEffect(() => {
  //     getSearchResults();
  //     window.scrollTo(0, 0);
  //   }, []);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  //   const getSearchResults = () => {
  //     callAPI({
  //       method: 'get',
  //       params: {},
  //       resource: BASE_URL + 'div/nace',
  //       success: (jsonResponse) => {
  //         updateArray(jsonResponse);
  //       },
  //       error: (error) => console.log(error)
  //     });
  //   };

  const subsectors = [
    {
      col1: 'A',
      col2: '01-03',
      col3: 'Agriculture, Forestry and Fishing',
      col4: '3',
      col5: '13',
      col6: '39',
      col7: '2',
      col8: '57'
    },
    {
      col1: 'B',
      col2: '05-09',
      col3: 'Mining and Quarrying',
      col4: '5',
      col5: '10',
      col6: '15',
      col7: '2',
      col8: '32'
    },
    {
      col1: 'C',
      col2: '10-33',
      col3: 'Manufacturing',
      col4: '24',
      col5: '95',
      col6: '230',
      col7: '51',
      col8: '400'
    },
    {
      col1: 'D',
      col2: '35',
      col3: 'Electricity, Gas, Steam and Air Conditioning supply',
      col4: '1',
      col5: '3',
      col6: '8',
      col7: '',
      col8: '12'
    },
    {
      col1: 'E',
      col2: '36-39',
      col3: 'Water supply; Sewerage, Waste Management and Remediation Activities',
      col4: '4',
      col5: '6',
      col6: '9',
      col7: '',
      col8: '19'
    },
    {
      col1: 'F',
      col2: '41-43',
      col3: 'Construction',
      col4: '3',
      col5: '9',
      col6: '22',
      col7: '6',
      col8: '40'
    },
    {
      col1: 'G',
      col2: '45-47',
      col3: 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      col4: '3',
      col5: '21',
      col6: '91',
      col7: '23',
      col8: '138'
    },
    {
      col1: 'H',
      col2: '49-53',
      col3: 'Transportation and Storage',
      col4: '5',
      col5: '15',
      col6: '23',
      col7: '16',
      col8: '59'
    },
    {
      col1: 'I',
      col2: '55-56',
      col3: 'Accommodation and Food Service Activities',
      col4: '2',
      col5: '7',
      col6: '8',
      col7: '8',
      col8: '25'
    },
    {
      col1: 'J',
      col2: '58-63',
      col3: 'Information and Communication',
      col4: '6',
      col5: '13',
      col6: '26',
      col7: '10',
      col8: '55'
    },
    {
      col1: 'K',
      col2: '64-66',
      col3: 'Financial and Insurance Activities',
      col4: '3',
      col5: '10',
      col6: '18',
      col7: '22',
      col8: '53'
    },
    {
      col1: 'L',
      col2: '68',
      col3: 'Real Estate Activities',
      col4: '1',
      col5: '3',
      col6: '4',
      col7: '3',
      col8: '11'
    },
    {
      col1: 'M',
      col2: '69-75',
      col3: 'Professional, Scientific and Technical Activities',
      col4: '7',
      col5: '15',
      col6: '19',
      col7: '20',
      col8: '61'
    },
    {
      col1: 'N',
      col2: '77-82',
      col3: 'Administrative and Support Service Activities',
      col4: '6',
      col5: '19',
      col6: '33',
      col7: '20',
      col8: '78'
    },
    {
      col1: 'O',
      col2: '84',
      col3: 'Public Administration and Defence; Compulsory Social Security',
      col4: '1',
      col5: '3',
      col6: '9',
      col7: '2',
      col8: '15'
    },
    {
      col1: 'P',
      col2: '85',
      col3: 'Education',
      col4: '1',
      col5: '6',
      col6: '11',
      col7: '',
      col8: '18'
    },
    {
      col1: 'Q',
      col2: '86-88',
      col3: 'Human Health and Social Work Activities',
      col4: '3',
      col5: '9',
      col6: '12',
      col7: '2',
      col8: '26'
    },
    {
      col1: 'R',
      col2: '90-93',
      col3: 'Arts, Entertainment and Recreation',
      col4: '4',
      col5: '5',
      col6: '15',
      col7: '4',
      col8: '28'
    },
    {
      col1: 'S',
      col2: '94-96',
      col3: 'Other Service Activities',
      col4: '3',
      col5: '6',
      col6: '19',
      col7: '',
      col8: '28'
    },
    {
      col1: 'T',
      col2: '97-98',
      col3: 'Activities of Households as Employers; Undifferentiated Goods- and Services-producing Activities of Households for own use',
      col4: '2',
      col5: '3',
      col6: '3',
      col7: '',
      col8: '8'
    },
    {
      col1: 'U',
      col2: '99',
      col3: 'Activities of Extraterritorial Organisations and Bodies',
      col4: '1',
      col5: '1',
      col6: '1',
      col7: '',
      col8: '3'
    }
  ];


  //   function updateArray(jsonResponse: any) {
  //     if (jsonResponse) {
  //       const updatedData = jsonResponse?.data?.map((item: any, i: number) => {
  //         if (item?.nace) {
  //           const updatednace = item.nace.map((obj: any, j: number) => ({
  //             ...obj,
  //             subsectors: subsectors[j],
  //             industry_groups: industry_groups[j],
  //             naics_industries: naics_industries[j],
  //             us_detail1: us_detail1[j]
  //           }));

  //           return {
  //             ...item,
  //             nace: updatednace
  //           };
  //         }
  //         return item;
  //       });
  //       setSearchResults(updatedData);
  //     }
  //   }

  return (
    <div className="App inner-pages">
      <Helmet>
        <title>
          Know about UKSIC Code | Understanding International Standard
          Industrial Classification System{' '}
        </title>
        <meta name="Learn about the International Standard Industrial Classification (NACE) code system, its historical significance, hierarchical structure, and usage in statistical reporting, business identification, economic research, and policy development. " />
        <meta
          name="keywords"
          content="NACE code, International Standard Industrial Classification System, Industry categorization, Economic analysis, Statistical reporting, Business identification, Policy development, Historical insights."
        />
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Know about UKSIC code
              </h2>
              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section style={{ padding: '3.5rem 10%' }}>
          <Box
            className="h-tab"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <div>
            The Standard Industrial Classification (SIC) system was first introduced in the UK in 1948. It was designed to classify business establishments and other statistical units based on their economic activities. This classification system provides a structured framework for the collection, tabulation, presentation, and analysis of data, ensuring uniformity and consistency. Beyond its statistical applications, the SIC system is also used for administrative purposes and by non-governmental organizations as a convenient method to categorize industrial activities within a common structure. The current classification includes 21 sections, 88 divisions, 272 groups, 615 classes, and 191 subclasses. 
              <p style={{ paddingTop: '20px' }}>
                There are 21 sections, 88 divisions, 272 groups, 615 classes and
                191 subclasses
              </p>
            </div>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="me-5 vtab-aside  home-tabs"
              style={{ marginBottom: '24px', marginTop: '36px' }}
            >
              <Tab
                label="Structural Overview"
                onClick={() => moveToRef(structuralRef)}
              />
              <Tab label="History" onClick={() => moveToRef(historyRef)} />
              <Tab label="Timelines" onClick={() => moveToRef(timelineRef)} />
              <Tab
                label="Usage & Importance"
                onClick={() => moveToRef(usageRef)}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div
                className="tab-panel-wrapper"
                style={{ paddingBottom: '.5rem' }}
                ref={structuralRef}
              >
                <div className="code-info-parent">
                  <div className="code-info-wrap">
                    <div className="grid-container">
                      <div>
                        <div> </div>
                      </div>
                      <div
                        className="white"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          C
                        </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          2
                        </div>
                      </div>{' '}
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          6
                        </div>
                      </div>{' '}
                      <div
                        className="pink"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          .5
                        </div>
                      </div>{' '}
                      <div
                        className="green"
                        style={{
                          height: '130px',
                          padding: '100px 40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="yellow"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          /2
                        </div>
                      </div>
                      <div className="item7 white"></div>
                      <div className="item8 orange"></div>
                      <div className="item9 orange"></div>
                      <div className="item10 pink"></div>
                      <div className="item11 green"></div>
                      <div className="item111 yellow"></div>
                      <div className="item111 yellow"></div>
                      <div
                        className="item32 white table-sector-name"
                        style={{ color: 'black', textAlign: 'end' }}
                      >
                        Section&nbsp;
                      </div>
                      <div
                        className="item33 orange table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Division&nbsp;
                      </div>
                      <div
                        className="item13 pink table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Group&nbsp;
                      </div>
                      <div
                        className="item14 green table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Class&nbsp;
                      </div>
                      <div
                        className="item991 yellow table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Subclass&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-panel-wrapper">
                <table id="structural-table">
                  <tr>
                    <th>Section</th>
                    <th>Range</th>
                    <th style={{ textAlign: 'left', width: '50%' }}>Title</th>
                    <th>Division 2-digit</th>
                    <th>Group 3-digit</th>
                    <th>Class 4-digit</th>
                    <th>Subclass 5-digit</th>
                    <th>Total</th>
                  </tr>
                  { 
  subsectors.map((subsector, index) => (
    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
      <td>{subsector.col1}</td>
      <td>{subsector.col2}</td>
      <td style={{ textAlign: 'left' }}>{subsector.col3}</td>
      <td>{subsector.col4}</td>
      <td>{subsector.col5}</td>
      <td>{subsector.col6}</td>
      <td>{subsector.col7}</td>
      <td>{subsector.col8}</td>
    </tr>
  ))
}


                  {/* {searchResults?.map((item: any, i: number) => {
                    if (item?.nace) {
                      return item?.nace?.map((data: any, j: number) => {
                        return (
                          <tr
                            key={data.id}
                            className={j % 2 === 0 ? 'even-row' : 'odd-row'}
                          >
                            <td>{data?.division}</td>
                            <td>{data?.code_range}</td>
                            <td style={{ textAlign: 'left' }}>
                              {data?.short_desc}
                            </td>
                            <td>{data?.subsectors}</td>
                            <td>{data?.industry_groups}</td>
                            <td>{data?.naics_industries}</td>
                            <td>{data?.us_detail1}</td>
                          </tr>
                        );
                      });
                    }
                  })} */}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>Total</td>
                    <td>88</td>
                    <td>272</td>
                    <td>615</td>
                    <td>975</td>
                    <td>1,166</td>
                  </tr>
                </table>
              </div>
            </TabPanel>

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '32px',
                paddingBottom: '16px'
              }}
              ref={historyRef}
            >
              History
            </h2>

            <div>
            Since its inception in 1948, the UK SIC (Standard Industrial Classification) system has undergone several revisions in 1958, 1968, 1980, 1992, 1997, and 2003. These revisions are essential as new products and industries emerge, and the focus of existing industries shifts over time.  
            </div>

            <div style={{ paddingTop: '20px' }}>
            The 1997 and 2003 updates were not full-scale revisions but rather responses to user demands for more detailed subclass information, along with some minor renumbering and revisions. The latest publication represents a major revision, aligning with contemporary changes in the NACE classification. The need for periodic updates is common across all international classifications to ensure they remain current. On 9 October 1990, the European Council of Ministers introduced a new statistical classification of economic activities in the European Communities (NACE Rev. 1). A minor revision, NACE Rev. 1.1, was published in January 2003, followed by a major revision, NACE Rev. 2, effective from 1 January 2008. {' '}
            </div>

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Timeline of UKSIC
            </h2>

            <embed src={naceTimeline} />

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Changes from UK SIC 2003 to UK SIC 2007 
            </h2>


<TabPanel value={value} index={1}>
  <table  className="custom-table" style={{width:'100%',}}>
    <colgroup>
      <col className="col-width-20" />
      <col className="col-width-100" />
      <col className="col-width-20" />
      <col />
    </colgroup>
    <thead>
      <tr>
        <th style={{textAlign:'center'}} colSpan={2}>SIC (2003)</th>
        <th style={{textAlign:'center'}} colSpan={2}>SIC (2007)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{textAlign:'center'}}>A</td>
        <td>Agriculture, hunting and forestry </td>
        <td style={{textAlign:'center', verticalAlign:'middle'}} rowSpan={2}>A</td>
        <td rowSpan={2}>Agriculture, forestry and fishing</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>B</td>
        <td>Fishing </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>C</td>
      <td>Mining and quarrying </td>
      <td style={{textAlign:'center'}}>B</td>
      <td>Mining and quarrying </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>D</td>
      <td>Manufacturing </td>
      <td style={{textAlign:'center'}}>C</td>
      <td>Manufacturing </td>
      </tr>
      <tr>
        <td style={{textAlign:'center', verticalAlign:'middle'}} rowSpan={2}>E</td>
        <td rowSpan={2}>Electricity, gas and water supply</td>
        <td style={{textAlign:'center'}}>D</td>
        <td>Electricity, gas, steam and air conditioning supply</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>E</td>
        <td>Water supply; sewerage, waste management and remediation activities </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>F</td>
      <td>Construction </td>
      <td style={{textAlign:'center'}}>F</td>
      <td>Construction </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>G</td>
      <td>Wholesale and retail trade; repair of motor vehicles, motorcycles and personal and household goods </td>
      <td style={{textAlign:'center'}}>G</td>
      <td>Wholesale and retail trade; repair of motor vehicles and motor cycles  </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>H</td>
      <td>Hotels and restaurants  </td>
      <td style={{textAlign:'center'}}>I</td>
      <td>Accomodation and Food Service Activities </td>
      </tr>
      <tr>
        <td style={{textAlign:'center', verticalAlign:'middle'}} rowSpan={2}>I</td>
        <td rowSpan={2}>Transport, storage and communications</td>
        <td style={{textAlign:'center'}}>H</td>
        <td>Transport and storage </td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>J</td>
        <td>Information and communication </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>J</td>
      <td>Financial intermediation  </td>
      <td style={{textAlign:'center'}}>K</td>
      <td>Financial and insurance activities  </td>
      </tr>
      <tr>
        <td style={{textAlign:'center', verticalAlign:'middle'}} rowSpan={3}>K</td>
        <td rowSpan={3}>Real estate, renting and business activities</td>
        <td style={{textAlign:'center'}}>L</td>
        <td>Real Estate Activities  </td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>M</td>
        <td>Professional, scientific and technical activities  </td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>N</td>
        <td>Administrative and support service activities  </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>L</td>
      <td>Public administration and defence; compulsory social security  </td>
      <td style={{textAlign:'center'}}>O</td>
      <td>Public administration and defence; compulsory social security   </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>M</td>
      <td>Education</td>
      <td style={{textAlign:'center'}}>P</td>
      <td>Education</td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>N</td>
      <td>Health and social work  </td>
      <td style={{textAlign:'center'}}>Q</td>
      <td>Human health and social work activities   </td>
      </tr>
      <tr>
        <td style={{textAlign:'center', verticalAlign:'middle'}} rowSpan={2}>O</td>
        <td rowSpan={2}>Other community, social and personal activities</td>
        <td style={{textAlign:'center'}}>R</td>
        <td>Arts, entertainment and recreation </td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>S</td>
        <td>Other service activities  </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>P</td>
      <td>Activities of private households as employers and undifferentiated production activites of private households   </td>
      <td style={{textAlign:'center'}}>T</td>
      <td>Activities of households as employers; undifferentieated goods- and services-producing activities of households for own use      </td>
      </tr>
      <tr>
      <td style={{textAlign:'center'}}>Q</td>
      <td>Extraterritorial organisations and bodies </td>
      <td style={{textAlign:'center'}}>U</td>
      <td>Ectivities of extraterritorial organisations and bodies   </td>
      </tr>
    </tbody>
  </table>
</TabPanel>







            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '56px',
                paddingBottom: '16px'
              }}
              ref={usageRef}
            >
              Usage & Importance of UKSIC
            </h2>

            <div className="usage-table">
              <div className="usage-table-left">
                <h3>Usage</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Data Collection and Analysis:</strong> The UK SIC Code System facilitates the collection, tabulation, presentation, and analysis of data, ensuring uniformity and consistency across various sectors.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Administrative and Classification Purposes:</strong> This system is used for administrative purposes and by non-governmental bodies to classify industrial activities within a common structure.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Compliance with UK SIC 2007 Classification:</strong> UK companies are required to update their SIC code to the UK SIC 2007 classification when filing their annual return, effective from October 1, 2011.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Enhancing Comparability:</strong> The system enhances comparability and coherence in economic data reporting and analysis across different industries and time periods. 
                  </li>
                </ul>
              </div>
              <div className="usage-table-right">
                <h3>Importance</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Identifying Business Operations:</strong> UK SIC Codes are crucial for identifying the specific line of business in which a company operates, providing clear industry classification.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Compliance with Annual Returns: </strong> UK SIC Codes are necessary for filing a company’s annual return, ensuring proper regulatory compliance and accurate reporting.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Facilitating Industry Analysis:</strong> These codes aid in the analysis of industry trends and market conditions, helping businesses and policymakers make informed decisio
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Enhancing Data Consistency:</strong> UK SIC Codes promote consistency and uniformity in data collection and reporting, enabling reliable comparisons across different sectors and time periods. 
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
