import React, { useState, useEffect, Fragment } from 'react';
import {Helmet} from "react-helmet";
import { callAPI } from '../helpers/api';
import { BASE_URL } from '../config/apiconfig';
import {
  Container,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel
} from '@mui/material';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import PaginationLink from 'components/PaginationLink/PaginationLink';
import NavBar from 'components/Navbar/NavBar';
import Footer from 'components/Footer/Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function SearchResults(props: any) {
  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var searchString: any = routeParams?.searchString;
  searchString = searchString.toLowerCase();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [numberlists, setnumberlist]: any = useState([]);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  let [categoryList, setCategoryList]: any = useState<any>();

  let [x, y]: any = React.useState<any>('');


  const searchRef = React.useRef(null);

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);
  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  React.useEffect(() => {
    getSearchResults({
      cat: undefined
    });
    // getCategoryListDataAPI();
  }, []);

  const handleLanguage = (langValue: any) => {
    moveToRef(searchRef);
  };

  const [category, setCategoryFilter] = React.useState('');
  const categoryHandleChange = (event: SelectChangeEvent) => {
    setCategoryFilter(event.target.value as string);
    getSearchResults({
      cat: event.target.value as string
    });
  };
  

  const [sortBy, setSortByFilter] = React.useState<any>('By Code');
  const sortSelectHandleChange = (event: SelectChangeEvent) => {
    if (event.target.value == 'By Relevance') {
      setSortByFilter(event.target.value as String);

      callAPI({
        method: 'get',
        params: {
          [codeType]: searchString,
          division: x,
          sortBy: 'relevance'
        },
        resource: BASE_URL + 'search',
        success: (jsonResponse) => {
          setSearchResults(jsonResponse);
          if (!categoryList) {
            setCategoryList(jsonResponse.data?.categoriesData);
          }
        },
        error: (error) => console.log(error)
      });
      
    } else {
      console.log(x)
      setSortByFilter(event.target.value as String);
      callAPI({
        method: 'get',
        params: {
          [codeType]: searchString,
          division: x,
          sortBy: 'code'
        },
        resource: BASE_URL + 'search',
        success: (jsonResponse) => {
          setSearchResults(jsonResponse);
          if (!categoryList) {
            setCategoryList(jsonResponse.data?.categoriesData);
          }
        },
        error: (error) => console.log(error)
      });
    }
  };

  const makeBold = (item: any, keyword: any) => {
    if (item) {
      var re = new RegExp(keyword, 'gi');
      item = item.replace(
        re,
        "<span style='display: inline;font-weight: 900'>" + keyword + '</span>'
      );

      // var re = new RegExp(' ' + keyword + ' ')
      // item = item.replace(re, "<span style='display: inline;font-weight: 900'>" + ' ' + keyword + ' ' + "</span>")
      // var re = new RegExp(keyword + ' ')
      // item = item.replace(re, "<span style='display: inline;font-weight: 900'>" + keyword + ' ' + "</span>")
      // re = new RegExp(' ' + keyword.toLowerCase() + ' ')
      // item = item.replace(re, "<span style='display: inline;font-weight: 900'>" + ' ' + keyword.toLowerCase() + ' ' + "</span>")
      // re = new RegExp(' ' + keyword.toLowerCase() + ',')
      // item = item.replace(re, "<span style='display: inline;font-weight: 900'>" + ' ' + keyword.toLowerCase() + ',' + "</span>")
      return item;
    }
  };

  const getSearchResults = (filter: any) => {
    var categorySearchFilter;
    if (searchResults) {
      categorySearchFilter = categoryList?.find((x: any) => {
        return x.short_desc == filter.cat;
      });
      y(categorySearchFilter?.division)
    }

    // if(categorySearchFilter){
    callAPI({
      method: 'get',
      params: {
        [codeType]: searchString,
        division: categorySearchFilter?.division,
        sortBy: sortBy === 'By Relevance' ? 'relevance' : 'code'
      },
      resource: BASE_URL + 'search',
      success: (jsonResponse) => {
        setSearchResults(jsonResponse);
        if (!categoryList) {
          setCategoryList(jsonResponse.data?.categoriesData);
        }
      },
      error: (error) => console.log(error)
    });
    // }
  };

  let [categoryListData, setCategoryListData]: any = useState<any[]>([]);
  const getCategoryListDataAPI = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/sic-naics',
      success: (jsonResponse) => {
        setCategoryListData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  const navigateToDetailPage = (menuValueSelected: any) => {
    var code = menuValueSelected?.sic_code || menuValueSelected?.naics_code || menuValueSelected?.nace_code || menuValueSelected?.isic_code || menuValueSelected?.nic_code || menuValueSelected?.uksic_code;
    var win = window.open('/detail/' + codeType + '/' + code, '_blank');
  };

  const getExamples = (item: any) => {
    var example_m: any = ' Examples&nbsp;-&nbsp;';
    item?.exampleArray?.map((example: any, j: number) => {
      if (example && j < item?.exampleArray?.length - 1) {
        example_m = example_m + example + '; ';
      } else {
        example_m = example_m + example + '. ';
      }
    });
    return example_m;
  };

  return (
    <div className="App">
      <Helmet>
      <title>Industry Code Search - Find Codes for Various Industries </title>    
      <meta name="Search for industry classification codes using our comprehensive database. Find codes for various sectors and sub-sectors to categorize businesses accurately. "/>
      <meta name="keywords" content="Industry code search, Classification code lookup, Business code finder, Sector code search, SIC lookup, NAICS Search, ISIC lookup, NACE Code Search "/>
      </Helmet>
      <NavBar onSelectLanguage={handleLanguage} />
      {typeof searchResults?.data?.data == 'object' && (
        <Container maxWidth="xl">
          <Box
            className="page-not-found d-flex align-items-center"
            sx={{ height: '100%' }}
          >
            {searchResults && searchResults?.data && (
              <div style={{ marginTop: '112px' }}>
                <h2 className="mb-4 mt-5 pt-4">
                  {codeType?.toUpperCase()} -{' '}
                  <span style={{ color: 'rgb(240, 98, 64)' }}>
                    {searchString?.charAt(0).toUpperCase() +
                      searchString?.slice(1)}
                  </span>{' '}
                  showing
                  {typeof searchResults?.data?.data == 'object'
                    ? ' ' + searchResults?.data?.data?.length + ' '
                    : ' no '}
                  results
                </h2>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={category}
                          onChange={categoryHandleChange}
                          inputProps={{ 'aria-label': 'Without label' }}
                          className="box-white"
                          displayEmpty
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em style={{ fontStyle: 'normal' }}>
                                  Categories
                                </em>
                              );
                            }
                            return selected;
                          }}
                          sx={{
                            '&.Mui-focused': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(240, 98, 64)'
                              }
                            }
                          }}
                        >
                          <MenuItem value={''}>All</MenuItem>
                          {categoryList?.map((item: any, i: number) => {
                           if(item.sic_count){
                            return (
                              <MenuItem value={item.short_desc}>
                                {item.short_desc} ({item.sic_count})
                              </MenuItem>
                            );}
                            else if(item.naics_count){
                              return (
                                <MenuItem value={item.short_desc}>
                                  {item.short_desc} ({item.naics_count})
                                </MenuItem>
                              );
                            }
                            else if(item.isic_count){
                              return (
                                <MenuItem value={item.short_desc}>
                                  {item.short_desc} ({item.isic_count})
                                </MenuItem>
                              );
                            }
                            else if(item.nace_count){
                              return (
                                <MenuItem value={item.short_desc}>
                                  {item.short_desc} ({item.nace_count})
                                </MenuItem>
                              );
                            }
                            else if(item.nic_count){
                              return (
                                <MenuItem value={item.short_desc}>
                                  {item.short_desc} ({item.nic_count})
                                </MenuItem>
                              );
                            }
                            else if(item.uksic_count){
                              return (
                                <MenuItem value={item.short_desc}>
                                  {item.short_desc} ({item.uksic_count})
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={sortBy}
                          onChange={sortSelectHandleChange}
                          inputProps={{ 'aria-label': 'Without label' }}
                          className="box-white"
                          displayEmpty
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em style={{ fontStyle: 'normal' }}>Sort by</em>
                              );
                            }
                            return selected;
                          }}
                          sx={{
                            '&.Mui-focused': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(240, 98, 64)'
                              }
                            }
                          }}
                        >
                          <MenuItem value={'By Relevance'}>
                            By Relevance
                          </MenuItem>
                          <MenuItem value={'By Code'}>By Code</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <ul className=" mt-5  mb-5 row search-result-list">
                  {typeof searchResults?.data?.data == 'object' &&
                    searchResults?.data?.data?.map((item: any, i: number) => {
                      return (
                        <li className="col-md-6 mb-4" key={i}>
                          <div
                            className="p-4 pointer"
                            style={{ height: '230px' }}
                            onClick={() => navigateToDetailPage(item)}
                          >
                            <h5 style={{ fontSize: '16px' }}>
                              {item?.sic_code || item?.naics_code || item?.nace_code || item?.isic_code || item?.nic_code || item?.uksic_code} -{' '}
                              <span style={{ display: 'inline' }}>
                                {item.short_desc}
                              </span>
                            </h5>
                            <p
                              style={{ fontSize: '14px', marginBottom: '1rem' }}
                              dangerouslySetInnerHTML={{
                                __html: makeBold(item.long_desc, searchString)
                              }}
                            ></p>
                            <span
                              style={{ fontSize: '14px' }}
                              dangerouslySetInnerHTML={{
                                __html: makeBold(
                                  getExamples(item),
                                  searchString
                                )
                              }}
                            ></span>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </Box>
        </Container>
      )}
      {typeof searchResults?.data?.data == 'string' && (
        <Container maxWidth="xl" style={{ height: 'calc(100vh - 150px)' }}>
          <Box
            className="page-not-found d-flex align-items-center"
            sx={{ height: '100%' }}
          >
            {searchResults && searchResults?.data && (
              <div style={{ marginTop: '112px' }}>
                <h2 className="mb-4 mt-5 pt-4">
                  {codeType?.toUpperCase()} -{' '}
                  <span style={{ color: 'rgb(240, 98, 64)' }}>
                    {searchString?.charAt(0).toUpperCase() +
                      searchString?.slice(1)}
                  </span>{' '}
                  showing
                  {typeof searchResults?.data?.data == 'object'
                    ? ' ' + searchResults?.data?.data?.length + ' '
                    : ' no '}
                  results
                </h2>
              </div>
            )}
          </Box>
        </Container>
      )}

      <Footer />
    </div>
  );
}
