// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-white {
  background-color: #fff;
  border-radius: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/CountryDropDown/CountryDropDown.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB;EACtB,kBAAkB,EAAA","sourcesContent":[".box-white {\n    background-color: #fff;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
