import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound from 'views/404';
import Home from 'components/Home/Home';
import Search from 'views/SearchResults';
import About1 from 'views/About1';
import About from 'views/About';
import NACE from 'views/NACE';
import NIC from 'views/NIC';
import UKSIC from 'views/UKSIC'
import Duplicateabout from 'views/duplicateabout';
import CodeConversion from 'views/CodeConversion';
import CodeSystem from 'views/CodeSystem';
import Duplicatecodesystem from 'views/duplicatecodesystem';
import Contact from 'views/Contact';
import WhySicCode from 'views/WhySicCode';
import StructureOfNaics from 'views/StructureOfNaics';
import DetailPage from 'views/detailPage';
import Terms from 'views/Terms';
import TermsAndConditions from 'views/TermsAndPrivacy';
import Privacy from 'views/privacy';
import Menu from 'views/Menu';
import HomeSearch from 'views/HomeSearch';

const AppRouter = (props: any) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="detail/:codeType/:codeValue/:division" element={<DetailPage />} />
        <Route path="detail/:codeType/:codeValue/" element={<DetailPage />} />
        <Route path="structure/:codeType/:codeValue" element={<StructureOfNaics />} />
        <Route path="whySicCode" element={<WhySicCode />} />
        <Route path="contact" element={<Contact />} />
        <Route path="codeSystem/naics" element={<CodeSystem />} />
        <Route path="codeSystem" element={<Duplicatecodesystem />} />
        <Route path="codeSystem/sic" element={<About />} />
        <Route path="about" element={<Duplicateabout />} />
        <Route path="codeConversion/:codeConversionFromDropDownValue/:codeConversionToDropDownValue/:codeConversionValue" element={<CodeConversion />} />
        <Route path="codeConversion" element={<CodeConversion />} />
        <Route path="codeSystem/isic" element={<About1 />} />
        <Route path="codeSystem/nace" element={<NACE />} />
        <Route path="codeSystem/nic" element={<NIC />} />
        <Route path="codeSystem/uksic" element={<UKSIC />} />
        <Route path="/" element={<Home />} />
        <Route path="menu" element={<Menu />} />
        <Route path="search/:codeType/:searchString" element={<Search />} />
        <Route path="terms" element={<Terms />} />
        <Route path="termsAndConditions" element={<TermsAndConditions />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="homesearch" element={<HomeSearch />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
