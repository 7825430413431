import React, { useState } from 'react';
import './Footer.scss';
import { useNavigate } from 'react-router-dom';
import CountryDropDown from 'components/CountryDropDown/CountryDropDown';
import logo from 'assets/append_new_logo_white[328].png';


export default function Footer({countryname}: any) {

  const[count, setCount] = useState()

  const handleClick = (num : any) => {
    setCount(num)
    countryname(num)
  }
  // console.log(count)
  const navigate = useNavigate();
  const navigateToCodeSystems = () => {
    navigate('/codeSystem', {
      replace: true
    });
  };

  const navigateToContactUs = () => {
    navigate('/contact', {
      replace: true
    });
  };

  const navigateToAppendSolutions = () => {
    window.open('https://www.appendsolutions.com', '_blank');
  };







  return (
    <div>
      <footer id="footer" className="py-5">
        <div className="container">
          <ul className="row  align-self-center">
            <li className="col-md-5 d-flex footer-nav  align-self-center">
              <a href="/#"
                onClick={() => navigateToCodeSystems()} >
                Code Systems
              </a>
              <a href="/#" onClick={() => navigateToAppendSolutions()}>
                Powered by &nbsp;<img src={logo} style={{width:'100px', height: 'auto'}}/>
              </a>
              <a href="/contact" onClick={() => navigateToContactUs()}>Contact Us</a>
            </li>
            <li className="col-md-6 footer-country-container" style={{ marginLeft: '100px' }}>
              <CountryDropDown handleClick={handleClick}/>
              <span style={{marginLeft:"20px"}}>
                Copyright 2024 © <span>IndustryCodes</span>
              </span>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
