import * as React from 'react';
import {Helmet} from "react-helmet";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import naceTimeline from 'assets/nic-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function NACE(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const historyRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/codeSystem');
  };

  // React.useEffect(() => {
  //   getSearchResults();
  //   window.scrollTo(0, 0);
  // }, []);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  // const getSearchResults = () => {
  //   callAPI({
  //     method: 'get',
  //     params: {},
  //     resource: BASE_URL + 'div/nace',
  //     success: (jsonResponse) => {
  //       updateArray(jsonResponse);
  //     },
  //     error: (error) => console.log(error)
  //   });
  // };

  const subsectors = [
    {
      col1: 'A',
      col2: '01-03',
      col3: 'Agriculture, Forestry and Fishing',
      col4: '3',
      col5: '13',
      col6: '38',
      col7: '124',
      col8: '54'
    },
    {
      col1: 'B',
      col2: '05-09',
      col3: 'Mining and Quarrying',
      col4: '5',
      col5: '10',
      col6: '14',
      col7: '53',
      col8: '29'
    },
    {
      col1: 'C',
      col2: '10-33',
      col3: 'Manufacturing',
      col4: '24',
      col5: '71',
      col6: '137',
      col7: '612',
      col8: '232'
    },
    {
      col1: 'D',
      col2: '35',
      col3: 'Electricity, Gas, Steam and Air Conditioning supply',
      col4: '1',
      col5: '3',
      col6: '3',
      col7: '13',
      col8: '7'
    },
    {
      col1: 'E',
      col2: '36-39',
      col3: 'Water supply; Sewerage, Waste Management and Remediation Activities',
      col4: '4',
      col5: '6',
      col6: '8',
      col7: '11',
      col8: '18'
    },
    {
      col1: 'F',
      col2: '41-43',
      col3: 'Construction',
      col4: '3',
      col5: '8',
      col6: '11',
      col7: '39',
      col8: '22'
    },
    {
      col1: 'G',
      col2: '45-47',
      col3: 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      col4: '3',
      col5: '20',
      col6: '43',
      col7: '135',
      col8: '66'
    },
    {
      col1: 'H',
      col2: '49-53',
      col3: 'Transportation and Storage',
      col4: '5',
      col5: '11',
      col6: '20',
      col7: '50',
      col8: '36'
    },
    {
      col1: 'I',
      col2: '55-56',
      col3: 'Accommodation and Food Service Activities',
      col4: '2',
      col5: '6',
      col6: '7',
      col7: '17',
      col8: '15'
    },
    {
      col1: 'J',
      col2: '58-63',
      col3: 'Information and Communication',
      col4: '6',
      col5: '13',
      col6: '23',
      col7: '55',
      col8: '42'
    },
    {
      col1: 'K',
      col2: '64-66',
      col3: 'Financial and Insurance Activities',
      col4: '3',
      col5: '10',
      col6: '18',
      col7: '22',
      col8: '31'
    },
    {
      col1: 'L',
      col2: '68',
      col3: 'Real Estate Activities',
      col4: '1',
      col5: '2',
      col6: '2',
      col7: '2',
      col8: '5'
    },
    {
      col1: 'M',
      col2: '69-75',
      col3: 'Professional, Scientific and Technical Activities',
      col4: '7',
      col5: '14',
      col6: '14',
      col7: '26',
      col8: '35'
    },
    {
      col1: 'N',
      col2: '77-82',
      col3: 'Administrative and Support Service Activities',
      col4: '6',
      col5: '19',
      col6: '26',
      col7: '42',
      col8: '51'
    },
    {
      col1: 'O',
      col2: '84',
      col3: 'Public Administration and Defence; Compulsory Social Security',
      col4: '1',
      col5: '3',
      col6: '7',
      col7: '11',
      col8: '11'
    },
    {
      col1: 'P',
      col2: '85',
      col3: 'Education',
      col4: '1',
      col5: '5',
      col6: '8',
      col7: '26',
      col8: '14'
    },
    {
      col1: 'Q',
      col2: '86-88',
      col3: 'Human Health and Social Work Activities',
      col4: '3',
      col5: '9',
      col6: '9',
      col7: '16',
      col8: '21'
    },
    {
      col1: 'R',
      col2: '90-93',
      col3: 'Arts, Entertainment and Recreation',
      col4: '4',
      col5: '5',
      col6: '10',
      col7: '18',
      col8: '19'
    },
    {
      col1: 'S',
      col2: '94-96',
      col3: 'Other Service Activities',
      col4: '3',
      col5: '6',
      col6: '17',
      col7: '31',
      col8: '26'
    },
    {
      col1: 'T',
      col2: '97-98',
      col3: 'Activities of Households as Employers; Undifferentiated Goods- and Services-producing Activities of Households for own use',
      col4: '2',
      col5: '3',
      col6: '3',
      col7: '3',
      col8: '8'
    },
    {
      col1: 'U',
      col2: '99',
      col3: 'Activities of Extraterritorial Organisations and Bodies',
      col4: '1',
      col5: '1',
      col6: '1',
      col7: '1',
      col8: '3'
    }
  ];

  // function updateArray(jsonResponse: any) {
  //   if (jsonResponse) {
  //     const updatedData = jsonResponse?.data?.map((item: any, i: number) => {
  //       if (item?.nace) {
  //         const updatednace = item.nace.map((obj: any, j: number) => ({
  //           ...obj,
  //           subsectors: subsectors[j],
  //           industry_groups: industry_groups[j],
  //           naics_industries: naics_industries[j],
  //           us_detail1: us_detail1[j]
  //         }));

  //         return {
  //           ...item,
  //           nace: updatednace
  //         };
  //       }
  //       return item;
  //     });
  //     setSearchResults(updatedData);
  //   }
  // }

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Know about NIC Code | Understanding International Standard Industrial Classification System </title>    
      <meta name="Learn about the International Standard Industrial Classification (NACE) code system, its historical significance, hierarchical structure, and usage in statistical reporting, business identification, economic research, and policy development. "/>
      <meta name="keywords" content="NACE code, International Standard Industrial Classification System, Industry categorization, Economic analysis, Statistical reporting, Business identification, Policy development, Historical insights."/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Know about NIC code
              </h2>
              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section style={{ padding: '3.5rem 10%' }}>
          <Box
            className="h-tab"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <div>
            The National Industrial Classification Code (“NIC Code”) is a statistical standard for developing and maintaining a comparable data base for various economic activities. This code has been developed with an intent to ascertain and analyze as to how each economic activity is contributing towards national wealth. 
            <p style={{ paddingTop: '20px' }}>
            The Department of Policy and Promotion in their Press Note 4 (2014 series) dated 26 June 2014 decided to switch over to the NIC 2008 from NIC 1987 version. Since then, all Indian Companies are advised to follow NIC 2008. 
              </p>
              <p style={{ paddingTop: '20px' }}>
              NIC 2008 is more compatible with the international system of classification. This enables the compliance processes for registration to flow in a smooth manner.               </p>
            </div>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="me-5 vtab-aside  home-tabs"
              style={{ marginBottom: '24px', marginTop: '36px' }}
            >
              <Tab
                label="Structural Overview"
                onClick={() => moveToRef(structuralRef)}
              />
              <Tab label="History" onClick={() => moveToRef(historyRef)} />
              <Tab label="Timelines" onClick={() => moveToRef(timelineRef)} />
              <Tab
                label="Usage & Importance"
                onClick={() => moveToRef(usageRef)}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div
                className="tab-panel-wrapper"
                style={{ paddingBottom: '.5rem' }}
                ref={structuralRef}
              >
                <div className="code-info-parent">
                  <div className="code-info-wrap">
                    <div className="grid-container">
                      <div>
                        <div> </div>
                      </div>
                      <div
                        className="white"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          D
                        </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          3
                        </div>
                      </div>{' '}
                      <div
                        className="pink"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="green"
                        style={{
                          height: '130px',
                          padding: '100px 40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="yellow"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>
                      <div className="item7 white"></div>
                      <div className="item8 orange"></div>
                      <div className="item9 orange"></div>
                      <div className="item10 pink"></div>
                      <div className="item11 green"></div>
                      <div className="item111 yellow"></div>
                      <div className="item111 yellow"></div>
                      <div
                        className="item32 white table-sector-name"
                        style={{ color: 'black', textAlign: 'end' }}
                      >
                        Section&nbsp;
                      </div>
                      <div
                        className="item33 orange table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Division&nbsp;
                      </div>
                      <div
                        className="item13 pink table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Group&nbsp;
                      </div>
                      <div
                        className="item14 green table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Class&nbsp;
                      </div>
                      <div
                        className="item991 yellow table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Sub-Class&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-panel-wrapper">
                <table id="structural-table">
                  <tr>
                    <th>Section</th>
                    <th>Range</th>
                    <th style={{ textAlign: 'left', width: '50%' }}>Title</th>
                    <th>Division 2-digit</th>
                    <th>Group 3-digit</th>
                    <th>Class 4-digit</th>
                    <th>Subclass 5-digit</th>
                    <th>Total</th>
                  </tr>
                  {subsectors.map((subsector, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                      <td>{subsector.col1}</td>
                      <td>{subsector.col2}</td>
                      <td style={{ textAlign: 'left' }}>{subsector.col3}</td>
                      <td>{subsector.col4}</td>
                      <td>{subsector.col5}</td>
                      <td>{subsector.col6}</td>
                      <td>{subsector.col7}</td>
                      <td>{subsector.col8}</td>
                    </tr>
                  ))}

                  {/* {searchResults?.map((item: any, i: number) => {
                    if (item?.nace) {
                      return item?.nace?.map((data: any, j: number) => {
                        return (
                          <tr
                            key={data.id}
                            className={j % 2 === 0 ? 'even-row' : 'odd-row'}
                          >
                            <td>{data?.division}</td>
                            <td>{data?.code_range}</td>
                            <td style={{ textAlign: 'left' }}>
                              {data?.short_desc}
                            </td>
                            <td>{data?.subsectors}</td>
                            <td>{data?.industry_groups}</td>
                            <td>{data?.naics_industries}</td>
                            <td>{data?.us_detail1}</td>
                          </tr>
                        );
                      });
                    }
                  })} */}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'left' }}>Total</td>
                    <td>88</td>
                    <td>238</td>
                    <td>419</td>
                    <td>1307</td>
                    <td>745</td>
                  </tr>
                </table>
              </div>
            </TabPanel>

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '32px',
                paddingBottom: '16px'
              }}
              ref={historyRef}
            >
              History
            </h2>

            <div>
            Industrial classification has a longstanding history in India, widely utilized in enterprise surveys, population censuses, national income estimations, labor statistics, and more. Initially, various organizations employed different industrial classifications for their statistical data collection. 
            </div>

            <div style={{ paddingTop: '20px' }}>
            This diversity led to a pressing need for a standardized industrial classification system. In response, the Central Statistical Organization (CSO) undertook the development of a national industrial classification in the early 1960s. 
            </div>

            <div style={{ paddingTop: '20px' }}>
            The CSO incorporated suggestions from multiple government agencies, including the National Employment Service, Registrar General, Economic Advisers to the Ministries of Finance, Food & Agriculture, Commerce and Industry, the Planning Commission, India Bureau of Mines, Labour Bureau, National Sample Survey, and the Indian Statistical Institute. 
            </div>

            <div style={{ paddingTop: '20px' }}>
            This collaborative effort resulted in the draft of the Standard Industrial Classification (SIC), which was finalized and published in 1962. 
            </div>

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Timeline of NIC
            </h2>

            <embed src={naceTimeline} />

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '56px',
                paddingBottom: '16px'
              }}
              ref={usageRef}
            >
              Usage & Importance of NIC
            </h2>

            <div className="usage-table">
              <div className="usage-table-left">
                <h3>Usage</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Enterprise Surveys:</strong> Enables systematic data collection and analysis across various industries.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Population Census:</strong> Assists in categorizing and understanding the employment distribution and economic activities of the population.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>National Income Estimation:</strong> Provides a framework for calculating the contribution of different sectors to the national income.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Labor Statistics:</strong> Facilitates the classification and reporting of labor market information, including employment and wage statistics.
                  </li>
                </ul>
              </div>
              <div className="usage-table-right">
                <h3>Importance</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Standardization:</strong> Ensures uniformity in data collection and analysis across various industries and sectors. 
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Making: </strong> Aids government agencies in crafting informed economic policies and development plans.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Economic Analysis:</strong>  Facilitates accurate measurement and comparison of economic activities and growth across different sectors. 
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Resource Allocation:</strong>  It helps in the efficient distribution of resources by identifying key industry needs and priorities. 
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
