// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-tabs button {
  color: var(--grey);
  font-size: 1rem;
  min-width: 160px; }
  .home-tabs button.Mui-selected {
    color: var(--red);
    background-color: transparent;
    border-bottom: 3px solid;
    border-radius: 0; }

.home-tabs .MuiTabs-indicator {
  height: 3px;
  background-color: var(--red); }

.short_desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }

.rangre_data {
  height: 80px;
  margin-bottom: 10px; }

.code_range {
  padding-right: 20px; }

.down-arrow {
  width: 30px;
  height: 30px; }
`, "",{"version":3,"sources":["webpack://./src/components/TabLinks/TabLinks.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB;EAClB,eAAe;EACf,gBAAgB,EAAA;EAJpB;IAMM,iBAAiB;IACjB,6BAA6B;IAC7B,wBAAwB;IACxB,gBAAgB,EAAA;;AATtB;EAaI,WAAW;EACV,4BAA4B,EAAA;;AAKjC;EACE,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;EAChB,uBAAuB,EAAA;;AAEzB;EACE,YAAY;EACZ,mBAAmB,EAAA;;AAErB;EACE,mBAAmB,EAAA;;AAErB;EACE,WAAW;EACX,YAAY,EAAA","sourcesContent":[".home-tabs {\n  button {\n    color: var(--grey);\n    font-size: 1rem;\n    min-width: 160px;\n    &.Mui-selected {\n      color: var(--red);\n      background-color: transparent;\n      border-bottom: 3px solid;\n      border-radius: 0;\n    }\n  }\n  .MuiTabs-indicator {\n    height: 3px;\n     background-color: var(--red);\n    \n  }\n}\n\n.short_desc{\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.rangre_data{\n  height: 80px;\n  margin-bottom: 10px;\n}\n.code_range{\n  padding-right: 20px;\n}\n.down-arrow{\n  width: 30px;\n  height: 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
