import React, { useState, useEffect, Fragment } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './CountryDropDown.scss';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import axios from 'axios';

export default function CountryDropDown({handleClick}:any) {

  let [currentlocation, setcurrentlocation]: any = useState(''); 

  function getGeoInfo () {
    axios.get('https://ipapi.co/json/').then((response) => {
        setcurrentlocation(response.data.country_code_iso3)
    }).catch((error) => {
        console.log(error);
    });
};
  const handleChange = (event: SelectChangeEvent) => {
    handleClick(event.target.value as string)
    setcurrentlocation(event.target.value as string);
  };

  let [countryList, setCountryList]: any = useState<any[]>([]);

  React.useEffect(() => {
    getCountryList();
    getGeoInfo();
  }, []);


  const getCountryList = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'countryList',
      success: (jsonResponse) => {
        setCountryList(jsonResponse);
      },
      error: (error) => console.log(error)
    })
  }

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth>
        <Select
          id="demo-simple-select"
          value={currentlocation}
          displayEmpty
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          className="box-white"
          style={{ height: "40px"}}
          sx={{
            "&.Mui-focused": {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(240, 98, 64)',
              }
            }
          }}
        >

          { 
            countryList?.data?.rows.map((item: any, i: number) => { 
           
              return (
                <MenuItem key={i} value={item.alpha3_code}>{item.country}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </Box>
  );
}
