import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VerticalTab from 'components/VerticalTab/VerticalTab';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from 'config/apiconfig';
import { callAPI } from 'helpers/api';

export default function DetailPage(props: any) {
  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;
  var division: any = routeParams?.division;

  const formattedCodeValue = `${codeValue}/${division}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    getCodeDetail();
  }, []);

  let [codeLegendDetails, setCodeDetails_legend]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [newx, setX]: any = useState(''); // Initialize state for x

  useEffect(() => {
    // Set x based on codeType, codeValue, and division
    if (codeType === 'uksic' && codeValue && division) {
      setX(`${codeValue}/${division}`);
    }
  }, [codeType, codeValue, division]);

  const getCodeDetail = () => {
    if (division != null && codeValue.length <= 2) {
      callAPI({
        method: 'get',
        resource: BASE_URL + 'code/BasedOnId',
        params: {
          [codeType]: codeValue,
          division: division,
          code: codeType == 'sic' ? '[2,3,4,6,8]' : '[2,3,4,5,6,7,8]'
        },
        success: (jsonResponse) => {
          setCodeDetails_legend(jsonResponse);
          var codeDetail = jsonResponse?.data[0]?.data?.find((data: any) => {
            if (
              data?.sic_code ||
              data?.naics_code ||
              data?.isic_code ||
              data?.nic_code ||
              data?.uksic_code ||
              data?.nace_code != null
            ) {
              return (
                data?.sic_code == codeValue ||
                data?.naics_code == codeValue ||
                data?.isic_code == codeValue ||
                data?.nace_code == codeValue ||
                data?.nic_code == codeValue ||
                data?.uksic_code == codeValue
              );
            } else {
              return data?.code_range == codeValue;
            }
          });
          console.log(codeDetail);
          setCodeDetails(codeDetail);
        },
        error: (error) => console.log(error)
      });
    } else {
      if (formattedCodeValue?.length == 7 && codeValue.includes('.')) {
        callAPI({
          method: 'get',
          resource: BASE_URL + 'code/BasedOnId',
          params: {
            [codeType]: formattedCodeValue,
            code: codeType == 'sic' ? '[2,3,4,6,8]' : '[2,3,4,5,6,7,8]'
          },
          success: (jsonResponse) => {
            setCodeDetails_legend(jsonResponse);
            var codeDetail = jsonResponse?.data[0]?.data?.find((data: any) => {
              if (
                data?.sic_code ||
                data?.naics_code ||
                data?.isic_code ||
                data?.nic_code ||
                data?.uksic_code ||
                data?.nace_code != null
              ) {
                return (
                  data?.sic_code == codeValue ||
                  data?.naics_code == codeValue ||
                  data?.isic_code == codeValue ||
                  data?.nace_code == codeValue ||
                  data?.nic_code == codeValue ||
                  data?.uksic_code == formattedCodeValue
                );
              } else {
                return data?.code_range == codeValue;
              }
            });
            console.log(codeDetail);
            setCodeDetails(codeDetail);
          },
          error: (error) => console.log(error)
        });
      } else {

        if((codeType === 'isic') || (codeType === 'nace') || (codeType === 'nic') || (codeType === 'uksic') || (codeType === 'sic') && (division))
          {
            callAPI({
              method: 'get',
              resource: BASE_URL + 'code/BasedOnId',
              params: {
                [codeType]: codeValue,
                code: codeType == 'sic' ? '[2,3,4,6,8]' : '[2,3,4,5,6,7,8]',
                division: division
              },
              success: (jsonResponse) => {
                setCodeDetails_legend(jsonResponse);
                var codeDetail = jsonResponse?.data[0]?.data?.find((data: any) => {
                  if (
                    data?.sic_code ||
                    data?.naics_code ||
                    data?.isic_code ||
                    data?.nic_code ||
                    data?.uksic_code ||
                    data?.nace_code != null
                  ) {
                    return (
                      data?.sic_code == codeValue ||
                      data?.naics_code == codeValue ||
                      data?.isic_code == codeValue ||
                      data?.nace_code == codeValue ||
                      data?.nic_code == codeValue ||
                      data?.uksic_code == codeValue
                    );
                  } else {
                    return data?.code_range == codeValue;
                  }
                });
                console.log(codeDetail);
                setCodeDetails(codeDetail);
              },
              error: (error) => console.log(error)
            });
          }
          else{
            callAPI({
              method: 'get',
              resource: BASE_URL + 'code/BasedOnId',
              params: {
                [codeType]: codeValue,
                code: codeType == 'sic' ? '[2,3,4,6,8]' : '[2,3,4,5,6,7,8]'
              },
              success: (jsonResponse) => {
                setCodeDetails_legend(jsonResponse);
                var codeDetail = jsonResponse?.data[0]?.data?.find((data: any) => {
                  if (
                    data?.sic_code ||
                    data?.naics_code ||
                    data?.isic_code ||
                    data?.nic_code ||
                    data?.uksic_code ||
                    data?.nace_code != null
                  ) {
                    return (
                      data?.sic_code == codeValue ||
                      data?.naics_code == codeValue ||
                      data?.isic_code == codeValue ||
                      data?.nace_code == codeValue ||
                      data?.nic_code == codeValue ||
                      data?.uksic_code == codeValue
                    );
                  } else {
                    return data?.code_range == codeValue;
                  }
                });
                console.log(codeDetail);
                setCodeDetails(codeDetail);
              },
              error: (error) => console.log(error)
            });
          }
      }
    }
  };

  const navigate = useNavigate();
  const navigateToStructure = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    navigate('/structure/' + codeType + '/' + codeValue.substring(0, 2));
  };

  var parentToChildData = {
    type: codeType,
    value: codeValue,
    detailData: codeLegendDetails
  };

  return (
    <div className="App inner-pages">
      <Helmet>
        <title>{`${codeType} ${codeValue} ${codeDetails?.short_desc?.toLowerCase()}`}</title>
        <meta
          name="description"
          content={`${codeType} ${codeValue} : ${codeDetails?.short_desc?.toLowerCase()}`}
        />
        <meta
          name="keywords"
          content={`${codeType} ${codeValue} : ${codeDetails?.short_desc?.toLowerCase()}`}
        />
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className={
            codeType === 'nace'
              ? codeValue.includes('-')
                ? 'inner-banner bg-yellow'
                : codeValue?.length === 2
                ? 'inner-banner bg-orange'
                : codeValue?.length === 4
                ? 'inner-banner bg-pink'
                : codeValue?.length === 5
                ? 'inner-banner bg-green'
                : 'inner-banner bg-blue'
              : codeType === 'uksic'
              ? formattedCodeValue?.length === 7 && codeValue.includes('.')
                ? 'inner-banner bg-yellow'
                : codeValue?.length === 2
                ? 'inner-banner bg-orange'
                : codeValue?.length === 3
                ? 'inner-banner bg-orange'
                : codeValue?.length === 4
                ? 'inner-banner bg-pink'
                : codeValue?.length === 5 && codeValue.includes('-')
                ? 'inner-banner bg-yellow'
                : codeValue?.length === 5
                ? 'inner-banner bg-green'
                : 'inner-banner bg-blue'
              : codeValue?.length === 2
              ? 'inner-banner bg-orange'
              : codeValue?.length === 3
              ? 'inner-banner bg-pink'
              : codeValue?.length === 4
              ? 'inner-banner bg-green'
              : codeValue?.length === 5
              ? 'inner-banner bg-yellow'
              : 'inner-banner bg-blue'
          }
        >
          <div className="container py-2">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center">
              {
                ((codeType === 'nace')  && (division)  && (
                  <h2>
                    {codeType.toUpperCase()} Code Section {division} <br></br>
                  </h2>
                ))}
                 {
                ((codeType === 'nace')  && (!division)  && (
                  <h2>
                    {codeType.toUpperCase()} Code {codeValue} <br></br>
                  </h2>
                ))}
                {
                ((codeType === 'sic')  && (division)  && (
                  <h2>
                    {codeType.toUpperCase()} Code Division {division} <br></br>
                  </h2>
                ))}
                 {
                ((codeType === 'sic')  && (!division)  && (
                  <h2>
                    {codeType.toUpperCase()} Code {codeValue} <br></br>
                  </h2>
                ))}
                {
                ((codeType === 'naics')  && (
                  <h2>
                    {codeType.toUpperCase()} Code {codeValue} <br></br>
                  </h2>
                ))}
                {
                ((codeType === 'isic')  && (division) && (
                  <h2>
                    {codeType.toUpperCase()} Code Section {division} <br></br>
                  </h2>
                ))}
                {
                ((codeType === 'isic') && (!division)  && (
                  <h2>
                    {codeType.toUpperCase()} Code {codeValue} <br></br>
                  </h2>
                ))}
                {
                ((codeType === 'nic') && (division) && (
                  <h2>
                    {codeType.toUpperCase()} Code Section {division} <br></br>
                  </h2>
                ))}
                 {
                ((codeType === 'nic') && (!division) && (
                  <h2>
                    {codeType.toUpperCase()} Code {codeValue} <br></br>
                  </h2>
                ))}
              {codeType === 'uksic' && formattedCodeValue?.length > 7 && (
                <h2>
                  {codeType.toUpperCase()} Code {codeValue} <br></br>
                </h2>
              )}
              {codeType === 'uksic' &&
                formattedCodeValue?.length == 7 &&
                codeValue.includes('.') && (
                  <h2>
                    {codeType.toUpperCase()} Code {formattedCodeValue} <br></br>
                  </h2>
                )}
              {codeType === 'uksic' &&
                formattedCodeValue?.length == 7 &&
                codeValue.includes('-') && (!division) && (
                  <h2>
                    {codeType.toUpperCase()} Code {codeValue} <br></br>
                  </h2>
                )}
                  {codeType === 'uksic' &&
                formattedCodeValue?.length == 7 &&
                codeValue.includes('-') && (division) && (
                  <h2>
                    {codeType.toUpperCase()} Code Section {division} <br></br>
                  </h2>
                )}
              <div>
                <p className="inner-su-head"> {codeDetails?.short_desc}</p>
              </div>
              <div
                className="text-center"
                onClick={(event) => navigateToStructure(event)}
              >
                <a href="">
                  <ArrowBackIcon className="lnk-back-icon" /> Back to{' '}
                  {codeType.toUpperCase()} Structure
                </a>
              </div>
            </div>
          </div>
        </section>
        <main className="">
          <section className="">
            <div className="container ">
              {codeLegendDetails?.data?.length >= 0 && (
                <VerticalTab detailsData={parentToChildData} />
              )}
            </div>
          </section>
        </main>
      </Box>
      <Footer />
    </div>
  );
}
