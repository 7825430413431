import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Container, Box } from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from 'components/Footer/Footer';
import { useNavigate } from 'react-router-dom';

export default function TermsAndConditions(props: any) {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Terms and Conditions - IndustryCodes</title>    
      <meta name="Read the terms and conditions of IndustryCodes for using our website and services. By accessing our site, you agree to abide by these terms."/>
      <meta name="keywords" content="Terms and conditions, Website terms, Service terms, User agreement "/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Terms & Conditions
              </h2>

              <a href="" onClick={() => navigateToHome()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>

        <section className="know-about-blok py-1">
          <div
            className="container"
            style={{ marginTop: '56px', marginBottom: '56px' }}
          >
            <div className="row">
              <div className="col-md-12">
                <h2 className="block-title pb-md-3">Terms & Conditions</h2>
                <div className="pb-md-3">Welcome to IndustryCodes! </div>
                <div className="pb-md-3">
                  These terms and conditions govern the use of Biztruss LLC's
                  website,{' '}
                  <a href="www.industrycodes.com">www.industrycodes.com.</a> By
                  accessing this website, you are indicating your acceptance of
                  these terms and conditions. If you do not agree to comply with
                  all the terms and conditions stated on this page, please
                  refrain from using IndustryCodes.
                </div>
                <div>
                  The following terms shall apply to these Terms and Conditions,
                  Privacy Statement, and Disclaimer Notice, as well as all
                  agreements: "Client," "You," and "Your" refer to you, the
                  individual accessing this website and complying with the
                  Company's terms and conditions. "The Company," "Ourselves,"
                  "We," "Our," and "Us" refer to our Company. "Party,"
                  "Parties," or "Us" refer to both the Client and ourselves. All
                  terms refer to the offer, acceptance, and consideration of
                  payment necessary to undertake the process of our assistance
                  to the Client in the most appropriate manner for the express
                  purpose of meeting the Client's needs in respect of the
                  provision of the Company's stated services, in accordance with
                  and subject to the prevailing law. Any use of the above
                  terminology or other words in the singular, plural,
                  capitalization, and/or he/she or they, are taken as
                  interchangeable and therefore refer to the same. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Cookies
                </h2>
                <div>
                  We use cookies to enhance your experience on IndustryCodes. By
                  accessing this website, you agree to the use of cookies in
                  accordance with Biztruss LLC's Privacy Policy. Cookies are
                  utilized by our website to enable certain functionalities,
                  making it easier for visitors to navigate our site. Some of
                  our affiliate/advertising partners may also use cookies. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  License
                </h2>
                <p>
                  Unless explicitly stated otherwise, Biztruss LLC and/or its
                  licensors retain the intellectual property rights for all
                  materials on IndustryCodes. All intellectual property rights
                  are reserved. You may access and use the materials on
                  IndustryCodes for your personal use, subject to the
                  restrictions outlined in these terms and conditions. 
                </p>
                <p>You must not:</p>
                <p className="ps-3">
                  <li>Republish material from IndustryCodes</li>
                </p>
                <p className="ps-3">
                  <li>
                    Sell, rent, or sub-license material from IndustryCodes 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    Reproduce, duplicate, or copy material from IndustryCodes 
                  </li>
                </p>
                <p className="ps-3">
                  <li>Redistribute content from IndustryCodes </li>
                </p>
                <p className="ps-3">
                  <li>User Comments and Opinions  </li>
                </p>
                <p>
                  Certain areas of the website may allow users to post and
                  exchange opinions and information. Biztruss LLC does not
                  filter, edit, publish, or review user comments (hereafter
                  referred to as "Comments") before their presence on the
                  website. The opinions expressed in Comments do not necessarily
                  reflect the views and opinions of Biztruss LLC, its agents,
                  and/or affiliates. Users are solely responsible for the
                  Comments they post. 
                </p>
                <p>
                  By posting Comments on our website, you warrant and represent
                  that: 
                </p>
                <p className="ps-3">
                  <li>
                    You have the right to post the Comments and have all
                    necessary licenses and consents to do so. 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    The Comments do not infringe upon any intellectual property
                    rights of third parties, including copyrights, patents, or
                    trademarks. 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    The Comments are not defamatory, libelous, offensive,
                    indecent, or otherwise unlawful, violating privacy rights. 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    The Comments will not be used for soliciting or promoting
                    commercial activities or unlawful activities.
                  </li>
                </p>
                <p>
                  You grant Biztruss LLC a non-exclusive license to use,
                  reproduce, edit, and authorize others to use and reproduce any
                  of your Comments in any and all forms, formats, or media. 
                  Hyperlinking to Our Content 
                </p>
                <p>
                  The following organizations may link to our Website without
                  prior written approval:
                </p>
                <p className="ps-3">
                  <li>Government agencies</li>
                </p>
                <p className="ps-3">
                  <li>Search engines </li>
                </p>
                <p className="ps-3">
                  <li>News organizations </li>
                </p>
                <p className="ps-3">
                  <li>
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the websites of other
                    listed businesses 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    System-wide Accredited Businesses except soliciting
                    non-profit organizations, charity shopping malls, and
                    charity fundraising groups, which may not hyperlink to our
                    website. 
                  </li>
                </p>
                <p>
                  These organizations may link to our home page, publications,
                  or other website information, provided that the link:
                </p>
                <p className="ps-3">
                  <li>Is not in any way deceptive </li>
                </p>
                <p className="ps-3">
                  <li>
                    Does not falsely imply sponsorship, endorsement, or approval
                    of the linking party and its products and/or services 
                  </li>
                </p>
                <p className="ps-3">
                  <li>Fits within the context of the linking party's site </li>
                </p>
                <p>
                  We may consider and approve link requests from other types of
                  organizations, including commonly-known consumer and/or
                  business information sources, dot.com community sites,
                  associations or other groups representing charities, online
                  directory distributors, internet portals, accounting, law and
                  consulting firms, and educational institutions and trade
                  associations. We will approve link requests from these
                  organizations if we determine that: 
                </p>
                <p className="ps-3">
                  <li>
                    The link would not harm our reputation or that of our
                    accredited businesses  
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    The organization does not have any negative records with us 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    The benefits from the visibility of the hyperlink compensate
                    for the absence of Biztruss LLC 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    The link is in the context of general resource information 
                  </li>
                </p>
                <div>
                  Approved organizations may hyperlink to our Website using our
                  corporate name, uniform resource locator (URL), or any other
                  description of our Website that makes sense within the context
                  and format of content on the linking party's site. Use of
                  Biztruss LLC's logo or other artwork for linking purposes is
                  not allowed without a trademark license agreement. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  iFrames 
                </h2>
                <div>
                  Creating frames around our web pages that alter the visual
                  presentation or appearance of our Website is prohibited
                  without prior approval and written permission. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Content Liability 
                </h2>
                <div>
                  We are not responsible for the content that appears on your
                  Website. By using our Website, you agree to protect and defend
                  us against any claims arising from your Website's content. You
                  shall not include any links on your Website that may be
                  interpreted as libelous, obscene, criminal, or that infringe,
                  violate, or advocate the infringement or violation of any
                  third-party rights. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Reservation of Rights 
                </h2>
                <div>
                  We reserve the right to request the removal of any links or
                  any particular link to our Website. If we request the removal
                  of links from your website, you agree to promptly remove all
                  such links. We also reserve the right to amend these terms and
                  conditions and our linking policy at any time. By continuously
                  linking to our Website, you agree to be bound by and follow
                  these linking terms and conditions. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Removal of Links from Our Website 
                </h2>
                <div>
                  If you find any link on our Website offensive for any reason,
                  you may contact and inform us at any time. While we will
                  consider requests to remove links, we are not obligated to do
                  so or to respond directly to you. 
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Disclaimer 
                </h2>
                <p>
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties, and conditions relating to
                  our website and its use. Nothing in this disclaimer will: 
                </p>
                <p className="ps-3">
                  <li>
                    Limit or exclude our or your liability for death or personal
                    injury 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    Limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    Limit any of our or your liabilities in a way that is not
                    permitted under applicable law 
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    Exclude any of our or your liabilities that may not be
                    excluded under applicable law 
                  </li>
                </p>
                <p>
                  The limitations and prohibitions of liability set forth in
                  this Section and elsewhere in this disclaimer govern all
                  liabilities arising under the disclaimer, including
                  liabilities arising in contract, tort, and for breach of
                  statutory duty. 
                </p>
                <p>
                  As long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for
                  any loss or damage of any nature. 
                </p>
                <div>
                  Please note that this is only a rephrasing of the original
                  text. It's crucial to ensure compliance with applicable laws
                  and regulations and seek legal advice for crafting a
                  comprehensive and accurate privacy policy. 
                </div>
              </div>
            </div>
          </div>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
