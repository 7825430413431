import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './TextFields.scss';
import { useState } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from 'config/apiconfig';
import { ResetTv } from '@mui/icons-material';

export default function TextFields() {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [message, setMessage] = useState('');
  const [xvalue, setXValue]: any = useState([]);
  const [formReset, setFormReset] = useState(false);


  const handlechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      // Checkbox is checked, add the value to the state
      setXValue([...xvalue, value]);
    } else {
      // Checkbox is unchecked, remove the value from the state
      setXValue(xvalue.filter((item:any) => item !== value));
    }
  }

  const handleFormReset = () => {
    setName('');
    setCompany('');
    setEmail('');
    setMobileNumber('');
    setMessage('');
    setXValue([]); // Clear the selected checkboxes by setting xvalue to an empty array
    setFormReset(true); // Set the form reset state to true
  }

  let handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      fullname: name,
      companyName: company,
      mobileNo: mobileNumber,
      email: email,
      interestedTopics: xvalue,
      contactFormComment: message
    };
    if(body.fullname.length > 0 || body.companyName.length > 0 || body.mobileNo.length > 0  || body.email.length > 0 || body.contactFormComment.length > 0 || body.interestedTopics.length > 0) 
    axios
      .post(BASE_URL + 'insertInformation', body)
      .then((response) => {
        handleFormReset(); // Call the function to reset the form
      })
      .catch((error) => {
        console.log(error);
      }); 
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginBottom: 2, width: '100%' }
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div>
        <TextField
          fullWidth
          className="box-white"
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          style={{ fontSize: '2rem' }}
          inputProps={{
            'aria-label': 'Without label',
            'background-color': '#ffffff'
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(240, 98, 64)'
              }
            }
          }}
        />
        <TextField
          fullWidth
          className="box-white"
          value={company}
          id="company"
          placeholder="Company"
          onChange={(e) => setCompany(e.target.value)}
          style={{ fontSize: '2rem' }}
          inputProps={{
            'aria-label': 'Without label',
            'background-color': '#ffffff'
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(240, 98, 64)'
              }
            }
          }}
        />
        <TextField
          fullWidth
          className="box-white"
          value={email}
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={{ fontSize: '2rem' }}
          inputProps={{
            'aria-label': 'Without label',
            'background-color': '#ffffff'
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(240, 98, 64)'
              }
            }
          }}
        />
        <TextField
          fullWidth
          className="box-white"
          value={mobileNumber}
          id="phone"
          type="number"
          onChange={(e) => setMobileNumber(e.target.value)}
          placeholder="Contact number"
          style={{ fontSize: '2rem' }}
          inputProps={{
            'aria-label': 'Without label',
            'background-color': '#ffffff'
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(240, 98, 64)'
              }
            }
          }}
        />
      </div>
      <div>
        <p>Please select the topics you are interested in :</p>
      </div>
      <div className="form-check m-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="Industry Classification Code Documentation"
          checked={xvalue.includes('Industry Classification Code Documentation')}
          id="flexCheckDefault#1"
          onChange={(e)=> handlechange(e)}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Industry Classification Code Documentation
        </label>
      </div>
      <div className="form-check m-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="Detailed Inter-code Reference Document"
          id="flexCheckDefault#2"
          checked={xvalue.includes('Detailed Inter-code Reference Document')}
          onChange={handlechange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Detailed Inter-code Reference Document
        </label>
      </div>
      <div className="form-check m-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="Reports and Analytics"
          id="flexCheckDefault#3"
          checked={xvalue.includes('Reports and Analytics')}
          onChange={handlechange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Reports and Analytics
        </label>
      </div>
      <div className="form-check m-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="Marketing List Businesses to Industry Code Mapping"
          id="flexCheckDefault#4"
          onChange={handlechange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Marketing List Businesses to Industry Code Mapping
        </label>
      </div>
      <div className="form-check m-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="Email Marketing List"
          id="flexCheckDefault#5"
          onChange={handlechange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Email Marketing List
        </label>
      </div>
      <div className="form-check m-3">
        <input
          className="form-check-input"
          type="checkbox"
          value="Other Marketing List Services"
          id="flexCheckDefault#6"
          onChange={handlechange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Other Marketing List Services
        </label>
      </div>
      <div>
        <TextField
          className="box-white"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Message Text"
          multiline={true}
          rows={4}
          style={{ fontSize: '2rem' }}
          inputProps={{
            'aria-label': 'Without label',
            'background-color': '#ffffff',
            maxLength: 1000
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(240, 98, 64)'
              }
            }
          }}
        />
      </div>
      <div>
        <p>By submitting this form, you agree to our Privacy Policy.</p>
      </div>
      {
        /* <div>
        <TextField
          error
          id="filled-error"
          label="Error"
          defaultValue="Hello World"
          variant="filled"
        />
        <TextField
          error
          id="filled-error-helper-text"
          label="Error"
          defaultValue="Hello World"
          helperText="Incorrect entry."
          variant="filled"
        />
      </div>
      <div>
        <TextField
          error
          id="standard-error"
          label="Error"
          defaultValue="Hello World"
          variant="standard"
        />
        <TextField
          error
          id="standard-error-helper-text"
          label="Error"
          defaultValue="Hello World"
          helperText="Incorrect entry."
          variant="standard"
        />
      </div> */
        <Button
          style={{
            backgroundColor: '#f06240',
            borderRadius: '3px',
            minWidth: '160px',
            minHeight: '48px',
            textTransform: 'none',
            fontSize: '1.2rem'
          }}
          variant="contained"
          type="submit"
          // disabled={areFieldsEmpty() || !isAtLeastOneCheckboxSelected()} // Disable the button if any field is empty or no checkbox is selected
          >
          Send Request
        </Button>
      }
    </Box>
  );
}
