import * as React from 'react';
import {Helmet} from "react-helmet";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import sicTimeline from 'assets/sic-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function Duplicateabout(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const importanceRef = React.useRef(null);
  const directoryRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/');
  };

  React.useEffect(() => {
    getSearchResults();
    window.scrollTo(0, 0);
  }, []);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  const getSearchResults = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/sic-naics',
      success: (jsonResponse) => {
        setSearchResults(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>About IndustryCodes - Your Ultimate Resource for Global Industry Classification Systems</title>    
      <meta name="Learn about IndustryCodes, the leading platform for accessing comprehensive information on industry categorization worldwide. Discover how we empower professionals, researchers, and enthusiasts with accurate and up-to-date knowledge on SIC, NAICS, ISIC, NACE, and more."/>
      <meta name="keywords" content="IndustryCodes, Industry classification systems, Global economy, Sector codes, Business categorization, Economic research, Industry trends, Market analysis"/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                About IndustryCode
              </h2>

              <a
                href=""
                onClick={() => navigateToStructure()}
              >
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
              {/* <p className="inner-su-title">
                {codeDetails?.long_desc}
              </p> */}
            </div>
          </div>
        </section>
        <main>
          <section className="know-about-blok py-1">
            <div className="container" style={{marginTop:'56px' , marginBottom:'56px'}}>
              <div className="row">
                <div className="col-md-12">
                <h2 className="block-title pb-md-3">
                    About
                  </h2>
                  <p>
                    Welcome to IndustryCodes, the ultimate resource for industry
                    classification systems across the globe. Our mission is to
                    provide a centralized platform that collates and presents
                    comprehensive information on industry categorization from
                    various countries. Whether you're a business professional,
                    researcher, or simply curious about how industries are
                    classified worldwide, we aim to empower you with accurate
                    and up-to-date knowledge.
                  </p>
                  </div>
                  <div className="col-md-12">
                  <p>
                    At IndustryCodes, we understand the importance of industry
                    classification systems in facilitating effective
                    communication, market analysis, and economic research.
                    However, navigating the vast landscape of industry
                    categorization can be challenging, especially when dealing
                    with multiple countries and their unique classification
                    methodologies. That's where we come in.
                  </p>
                  </div>
                  <div className="col-md-12">
                  <p>
                    Our dedicated team of industry experts and researchers has
                    painstakingly curated an extensive database of industry
                    classification systems from around the world. We have
                    gathered sector codes, sub-sectors, and industry codes
                    specific to each country, providing you with a comprehensive
                    understanding of how industries are categorized globally.
                  </p>
                  </div>
                  <div className="col-md-12">
                  <div>
                    We believe that accurate and accessible information is
                    crucial for making informed decisions. Whether you're
                    exploring new markets, identifying industry trends, or
                    conducting in-depth research, our platform equips you with
                    the tools and resources needed to navigate industry
                    classifications with ease.
                  </div>
                  </div>
                <div className="col-md-12">
                  <h2 className="block-title pb-md-3" style={{paddingTop:'56px'}}>
                    Key Features of IndustryCodes:
                  </h2>
                  <p className='ps-3'>
                  <span style={{fontWeight:'700',color:'black'}}>1. Global Industry Classification Systems{' '}:</span> We provide a
                    centralized repository of industry classification systems
                    from various countries. With our comprehensive database, you
                    can compare, contrast, and understand how industries are
                    categorized across borders.
                  </p>
                  <p className='ps-3'>
                  <span style={{fontWeight:'700',color:'black'}}>2. Country-specific Classifications{' '}:</span> Dive into the
                    intricacies of industry classification systems specific to
                    individual countries. Our detailed breakdowns ensure you
                    have a comprehensive view of each country's classification
                    system, empowering you with accurate insights.
                  </p>
                  <p className='ps-3'>
                  <span style={{fontWeight:'700',color:'black'}}>3. Search and Comparison Tools{' '}:</span> Our powerful search and
                    comparison tools enable you to find specific industry
                    classifications or compare classification systems from
                    different countries effortlessly. Simplify your research and
                    save valuable time.
                  </p>
                  <p className='ps-3'>
                  <span style={{fontWeight:'700',color:'black'}}>4. Resources and Guides{' '}:</span> Explore our collection of resources
                    and guides that delve deeper into industry classification
                    systems. Stay updated with industry trends, learn about
                    classification methodologies, and discover practical
                    applications in various sectors.
                  </p>
                  <p className='ps-3'>
                  <span style={{fontWeight:'700',color:'black'}}>5. Community Engagement{' '}:</span> Join a vibrant community of
                    industry professionals, researchers, and enthusiasts. Share
                    your knowledge, exchange ideas, and contribute to the
                    collective understanding of industry categorization.
                  </p>
                  <p>
                    At IndustryCodes, we strive for excellence in delivering
                    accurate and reliable information. Our team is committed to
                    continuously updating and expanding our database to ensure
                    you have access to the most current industry classification
                    systems worldwide.
                  </p>
                  <p>
                    Thank you for visiting IndustryCodes. We invite you to
                    explore our platform, engage with our community, and unlock
                    a world of knowledge on industry classifications across
                    countries.
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Remember, accurate classification leads to clearer
                    communication, better decision-making, and a deeper
                    understanding of industries worldwide. Together, let's
                    navigate the intricacies of industry categorization and
                    unlock new opportunities!
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Box>
      <Footer />
    </div>
  );
}
