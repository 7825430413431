import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import './VerticalTab.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) =>
  window.scrollTo(0, ref.current.offsetTop - 90);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function VerticalTab(props: any) {
  const navigate = useNavigate();
  const descriptionRef = React.useRef(null);
  const classificationRef = React.useRef(null);
  const crossRef = React.useRef(null);
  const examplesRef = React.useRef(null);
  const sixDigitRef = React.useRef(null);
  const eightDigitRef = React.useRef(null);

// Get the value from localStorage
let selectedTabValueString = localStorage.getItem('selectedtabvalue');
// Check if selectedTabValueString is not null before parsing
let selectedTabValue = selectedTabValueString ? JSON.parse(selectedTabValueString) : null;

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [newCategory, setnewCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  let [newcase, snewcase]: any = useState<any>('');
  let [specicalcase, specialnewcase]: any = useState<any>('');
  let [checkukisc, uncheckuksic]: any = useState<any>('');

  let [uksiccodelength,setuksiccodelength]:any = useState<any>('');


  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;
  var division: any = routeParams?.division;
  console.log(codeType,codeValue,division)


  let [newx, setX]: any = useState(''); // Initialize state for x

  useEffect(() => {
    getStructureDetail();
    setX(`${codeValue}/${division}`)
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getStructureDetail = () => {
    setCodeTypeString([]);
    setParentCategory([]);
    setChildCategory([]);
    setCodeDetails([]);
    setCrossRefs([]);

    var codeDetail: any;
    props?.detailsData?.detailData?.data.forEach((data: any) => {
      console.log(data)
      if(data.values == "fiveDigitUksic"){
        console.log("fivedigituksiccccccc")
        console.log(data.rowCount)
        uncheckuksic(data.rowCount)
      }

      if (typeof data?.data == 'object') {
        codeDetail = data?.data?.find((data: any) => {
          return (
            (data?.sic_code == codeValue ||
              data?.naics_code == codeValue ||
              data?.isic_code == codeValue ||
              data?.nic_code == codeValue ||
              data?.uksic_code == codeValue ||
              data?.nace_code == codeValue) &&
             data?.long_desc || data?.short_desc
          );
        });
      }
      // else{
      //   codeDetail = data;
      // }
    });

    if (!codeDetail) {
      codeDetail = props?.detailsData?.detailData?.data[0]?.data?.find(
        (data: any) => {
          console.log(data)
          return (
            data?.sic_code == codeValue ||
            data?.naics_code == codeValue ||
            data?.isic_code == codeValue ||
            data?.nace_code == codeValue ||
            data?.nic_code == codeValue ||
            data?.uksic_code == codeValue ||
            data?.code_range == codeValue &&
            data?.long_desc || data?.short_desc
          );
        }
      );
    }

    var codeCrossref = props?.detailsData?.detailData?.data?.find(
      (data: any) => {
        return (
          data?.values == 'naics_crossrefs' ||
          data?.values == 'sic_crossrefs' ||
          data?.values == 'isic_crossrefs' ||
          data?.values == 'nace_crossrefs' ||
          data?.values == 'nic_crossrefs' ||
          data?.values == 'uksic_crossrefs'
        );
      }
    );
    if (codeCrossref && typeof codeCrossref.data == 'object') {
      setCrossRefs(codeCrossref);
    }

    var codeExamples = props?.detailsData?.detailData?.data?.find(
      (data: any) => {
        return (
          data?.values == 'naics_examples' ||
          data?.values == 'sic_examples' ||
          data?.values == 'isic_examples' ||
          data?.values == 'nace_examples' ||
          data?.values == 'nic_examples' ||
          data?.values == 'uksic_examples'
        );
      }
    );

    if (codeExamples && typeof codeExamples == 'object') {
      setCodeExamples(codeExamples);
    }
    console.log(codeExamples?.data)
    setCodeDetails(codeDetail);

    var detailData = props?.detailsData?.detailData?.data;
    if (codeType == 'sic') {
      var code = 'sic_code';
      var parentCategoryData;
      var childCategoryData;
      setCodeTypeString(code);

      var childValueName = '';
      var childValueNumber = 0;
      var parentValueName = '';
      var parentValueNumber = 0;

      if (codeValue > 0) {
      } else {
        snewcase('2');
        parentValueName = 'twoDigitSic';
        parentValueNumber = 2;
      }

      if (codeValue?.length == 6) {
        parentValueName = 'fourDigitSic';
        parentValueNumber = 4;
        childValueName = 'eightDigitSic';
        childValueNumber = 8;
      } else if (codeValue?.length == 4) {
        parentValueName = 'threeDigitSic';
        parentValueNumber = 3;
        childValueName = 'sixDigitSic';
        childValueNumber = 6;
      } else if (codeValue?.length == 3) {
        parentValueName = 'twoDigitSic';
        parentValueNumber = 2;
        childValueName = 'fourDigitSic';
        childValueNumber = 4;
      } else if (codeValue?.length == 2) {
        parentValueName = 'twoDigitSic';
        parentValueNumber = 2;
        childValueName = 'threeDigitSic';
        childValueNumber = 3;
      }

      parentCategoryData = detailData?.find((data: any) => {
        return data?.values == parentValueName;
      });

      if (parentCategoryData) {
        setParentCategory(parentCategoryData?.data);
      }
      childCategoryData = detailData?.find((data: any) => {
        return data?.values == childValueName;
      });
      var sicData;
      var six___;
      var eight___;
      if (childCategoryData) {
        setChildCategory(childCategoryData?.data);
      } else {
        sicData = detailData.find((data: any) => {
          return data?.values == codeType; // type sic
        });
        childCategoryData = sicData?.data?.filter((data: any) => {
          return data?.sic_code?.length == childValueNumber;
        });
        setChildCategory(childCategoryData);
      }

      sicData = detailData.find((data: any) => {
        return data?.values == codeType; // type sic
      });

      six___ = sicData?.data?.filter((data: any) => {
        return data?.sic_code?.length == 6;
      });
      eight___ = sicData?.data?.filter((data: any) => {
        return data?.sic_code?.length == 8;
      });

      if (six___) {
        setSixDigitData(six___);
      }
      if (eight___) {
        setEightDigitData(eight___);
      }
    }

    if (codeType == 'isic') {
      var code = 'isic_code';
      var parentCategoryData;
      var childCategoryData;
      setCodeTypeString(code);

      var childValueName = '';
      var childValueNumber = 0;
      var parentValueName = '';
      var parentValueNumber = 0;

      if (codeValue > 0) {
      } else {
        snewcase('2');
        parentValueName = 'twoDigitIsic';
        parentValueNumber = 2;
      }

      if (codeValue?.length == 6) {
        parentValueName = 'fourDigitIsic';
        parentValueNumber = 4;
        childValueName = 'eightDigitIsic';
        childValueNumber = 8;
      } else if (codeValue?.length == 4) {
        parentValueName = 'threeDigitIsic';
        parentValueNumber = 3;
        childValueName = 'sixDigitISic';
        childValueNumber = 6;
      } else if (codeValue?.length == 3) {
        parentValueName = 'twoDigitIsic';
        parentValueNumber = 2;
        childValueName = 'fourDigitIsic';
        childValueNumber = 4;
      } else if (codeValue?.length == 2) {
        if(division){
          snewcase('division')
          parentValueName = 'twoDigitIsic'
          parentValueNumber = 2;
        }
        else{
          parentValueName = 'twoDigitIsic';
          parentValueNumber = 2;
          childValueName = 'threeDigitIsic';
          childValueNumber = 3;
        }
      }

      parentCategoryData = detailData?.find((data: any) => {
        return data?.values == parentValueName;
      });

      if (parentCategoryData) {
        setParentCategory(parentCategoryData?.data);
      }
      childCategoryData = detailData?.find((data: any) => {
        return data?.values == childValueName;
      });
      var isicData;
      var six___;
      var eight___;
      if (childCategoryData) {
        setChildCategory(childCategoryData?.data);
      } else {
        isicData = detailData.find((data: any) => {
          return data?.values == codeType; // type isic
        });
        childCategoryData = isicData?.data?.filter((data: any) => {
          return data?.isic_code?.length == childValueNumber;
        });
        setChildCategory(childCategoryData);
      }

      isicData = detailData.find((data: any) => {
        return data?.values == codeType; // type sic
      });

      six___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 6;
      });
      eight___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 8;
      });

      if (six___) {
        setSixDigitData(six___);
      }
      if (eight___) {
        setEightDigitData(eight___);
      }
    }

    if (codeType == 'nace') {
      var code = 'nace_code';
      var parentCategoryData;
      var childCategoryData;
      setCodeTypeString(code);

      var childValueName = '';
      var childValueNumber = 0;
      var parentValueName = '';
      var parentValueNumber = 0;

      if (codeValue > 0) {
         if(codeValue?.length == 5){
          snewcase('100')
          parentValueName = 'threeDigitNace';
          parentValueNumber = 3;
        }
      } else {
        snewcase('5');
        parentValueName = 'twoDigitNace';
        parentValueNumber = 2;
      }

      if (codeValue?.length == 6) {
        parentValueName = 'fourDigitNace';
        parentValueNumber = 4;
        childValueName = 'eightDigitNace';
        childValueNumber = 8;
      } else if (codeValue?.length == 4) {
        snewcase('4');
        parentValueName = 'twoDigitNace';
        parentValueNumber = 2;
        childValueName = 'fourDigitNace';
        childValueNumber = 4;
      } else if (codeValue?.length == 3) {
        parentValueName = 'threeDigitNace';
        parentValueNumber = 3;
        childValueName = 'fourDigitNace';
        childValueNumber = 4;
      } else if (codeValue?.length == 2) {
        if(division){
          snewcase('nacedivision')
          parentValueName = 'twoDigitNace';
          parentValueNumber = 2;
        }
        else{
          snewcase('10')
          parentValueName = 'twoDigitNace';
          parentValueNumber = 2;
          childValueName = 'threeDigitNace';
          childValueNumber = 3;
        }
      }


      parentCategoryData = detailData?.find((data: any) => {
        return data?.values == parentValueName;
      });

      if (parentCategoryData) {
        setParentCategory(parentCategoryData?.data);
      }
      childCategoryData = detailData?.find((data: any) => {
        return data?.values == childValueName;
      });
      var isicData;
      var six___;
      var eight___;
      if (childCategoryData) {
        setChildCategory(childCategoryData?.data);
      } else {
        isicData = detailData.find((data: any) => {
          return data?.values == codeType; // type isic
        });
        childCategoryData = isicData?.data?.filter((data: any) => {
          return data?.isic_code?.length == childValueNumber;
        });
        setChildCategory(childCategoryData);
      }

      isicData = detailData.find((data: any) => {
        return data?.values == codeType; // type sic
      });

      six___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 6;
      });
      eight___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 8;
      });

      if (six___) {
        setSixDigitData(six___);
      }
      if (eight___) {
        setEightDigitData(eight___);
      }
    }

    if (codeType == 'naics') {
      var code = 'naics_code';
      var parentCategoryData;
      var childCategoryData;
      setCodeTypeString(code);

      if (codeValue > 0) {
      } else {
        snewcase(3);
        parentCategoryData = detailData?.find((data: any) => {
          return data?.values == 'threeDigitNaics';
        });
        if (parentCategoryData) {
          setParentCategory(parentCategoryData?.data);
        }
      }

      if (codeValue?.length == 6) {
        parentCategoryData = detailData?.find((data: any) => {
          return data?.values == 'fiveDigitNaics';
        });
        childCategoryData = detailData?.find((data: any) => {
          return data?.values == 'eightDigitNaics';
        });
        if (parentCategoryData) {
          setParentCategory(parentCategoryData?.data);
        }
        if (childCategoryData) {
          setChildCategory(childCategoryData.data);
        }
      }
      if (codeValue?.length == 5) {
        parentCategoryData = detailData?.find((data: any) => {
          return data?.values == 'fourDigitNaics';
        });
        childCategoryData = detailData?.find((data: any) => {
          return data?.values == 'sixDigitNaics';
        });
        if (parentCategoryData) {
          setParentCategory(parentCategoryData?.data);
        }
        if (childCategoryData) {
          setChildCategory(childCategoryData.data);
        }
      }
      if (codeValue?.length == 4) {
        parentCategoryData = detailData?.find((data: any) => {
          return data?.values == 'threeDigitNaics';
        });
        childCategoryData = detailData?.find((data: any) => {
          return data?.values == 'fiveDigitNaics';
        });
        if (parentCategoryData) {
          setParentCategory(parentCategoryData?.data);
        }
        if (childCategoryData) {
          setChildCategory(childCategoryData.data);
        }
      }
      if (codeValue?.length == 3) {
        parentCategoryData = detailData?.find((data: any) => {
          return data?.values == 'twoDigitNaics';
        });
        childCategoryData = detailData?.find((data: any) => {
          return data?.values == 'fourDigitNaics';
        });
        if (parentCategoryData) {
          setParentCategory(parentCategoryData?.data);
        }
        if (childCategoryData) {
          setChildCategory(childCategoryData.data);
        }
      }
      if (codeValue?.length == 2) {
        specialnewcase('2');

        parentCategoryData = detailData?.find((data: any) => {
          return data?.values == 'threeDigitNaics';
        });

        if (parentCategoryData) {
          setParentCategory(parentCategoryData?.data);
        }
      }
      var naicsData;
      var six___;
      var eight___;
      if (childCategoryData) {
        setChildCategory(childCategoryData?.data);
      } else {
        naicsData = detailData.find((data: any) => {
          return data?.values == codeType; // type naics
        });
        childCategoryData = naicsData?.data?.filter((data: any) => {
          return data?.naics_code?.length == childValueNumber;
        });
        setChildCategory(childCategoryData);
      }

      naicsData = detailData.find((data: any) => {
        return data?.values == codeType; // type sic
      });

      six___ = naicsData?.data?.filter((data: any) => {
        return data?.naics_code?.length == 6;
      });
      eight___ = naicsData?.data?.filter((data: any) => {
        return data?.naics_code?.length == 8;
      });

      if (six___) {
        setSixDigitData(six___);
      }
      if (eight___) {
        setEightDigitData(eight___);
      }
    }

    if (codeType == 'nic') {
      var code = 'nic_code';
      var parentCategoryData;
      var childCategoryData;
      setCodeTypeString(code);

      var childValueName = '';
      var childValueNumber = 0;
      var parentValueName = '';
      var parentValueNumber = 0;

      if (codeValue > 0) {
      } else {
        snewcase('2');
        parentValueName = 'twoDigitNic';
        parentValueNumber = 2;
      }

      if (codeValue?.length == 6) {
        parentValueName = 'fourDigitNic';
        parentValueNumber = 4;
        childValueName = 'eightDigitNic';
        childValueNumber = 8;
      } else if (codeValue?.length == 4) {
        parentValueName = 'threeDigitNic';
        parentValueNumber = 3;
        childValueName = 'fiveDigitNic';
        childValueNumber = 5;
      }
       else if (codeValue?.length == 5 && (!codeValue.includes('-'))) {
        snewcase('nic5')
        parentValueName = 'fourDigitNic';
        parentValueNumber = 4;
      }
       else if (codeValue?.length == 3) {
        parentValueName = 'twoDigitNic';
        parentValueNumber = 2;
        childValueName = 'fourDigitNic';
        childValueNumber = 4;
      } else if (codeValue?.length == 2) {
        if(division){
          snewcase('division')
          parentValueName = 'twoDigitNic'
          parentValueNumber = 2;
        }
        else{
          parentValueName = 'twoDigitNic';
          parentValueNumber = 2;
          childValueName = 'threeDigitNic';
          childValueNumber = 3;
        }
      }

      parentCategoryData = detailData?.find((data: any) => {
        return data?.values == parentValueName;
      });

      if (parentCategoryData) {
        setParentCategory(parentCategoryData?.data);
      }
      childCategoryData = detailData?.find((data: any) => {
        return data?.values == childValueName;
      });
      var isicData;
      var six___;
      var eight___;
      if (childCategoryData) {
        setChildCategory(childCategoryData?.data);
      } else {
        isicData = detailData.find((data: any) => {
          return data?.values == codeType; // type isic
        });
        childCategoryData = isicData?.data?.filter((data: any) => {
          return data?.isic_code?.length == childValueNumber;
        });
        setChildCategory(childCategoryData);
      }

      isicData = detailData.find((data: any) => {
        return data?.values == codeType; // type sic
      });

      six___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 6;
      });
      eight___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 8;
      });

      if (six___) {
        setSixDigitData(six___);
      }
      if (eight___) {
        setEightDigitData(eight___);
      }
    }

  let formattedCodeValue = `${codeValue}/${division}`;
  setuksiccodelength(formattedCodeValue);
  console.log(formattedCodeValue?.length);
  console.log(codeValue)
  console.log(division)

    if (codeType == 'uksic') {
      var code = 'uksic_code';
      var parentCategoryData;
      var childCategoryData;
      setCodeTypeString(code);

      var childValueName = '';
      var childValueNumber = 0;
      var parentValueName = '';
      var parentValueNumber = 0;

      if (codeValue > 0) {
         if(codeValue?.length == 5 && codeValue.includes('.') && formattedCodeValue?.length == 15){
          snewcase('501')
          parentValueName = 'threeDigitUksic';
          parentValueNumber = 3;
          childValueName = 'fiveDigitUksic';
          childValueNumber = 5;
        }
        else if(codeValue?.length == 5 && codeValue.includes('.') && formattedCodeValue?.length == 7){
          snewcase('501')
          parentValueName = 'fourDigitUksic';
          parentValueNumber = 4;
          childValueName = 'SixDigitUksic';
          childValueNumber = 6;
        }
      }
       else {
        snewcase('5');
        parentValueName = 'twoDigitUksic';
        parentValueNumber = 2;
      }

      if (codeValue?.length == 6) {
        parentValueName = 'fourDigitUksic';
        parentValueNumber = 4;
        childValueName = 'eightDigitUksic';
        childValueNumber = 8;
      } else if (codeValue?.length == 4) {
        snewcase('4');
        parentValueName = 'twoDigitUksic';
        parentValueNumber = 2;
        childValueName = 'fourDigitUksic';
        childValueNumber = 4;
      } else if (codeValue?.length == 3) {
        parentValueName = 'threeDigitUksic';
        parentValueNumber = 3;
        childValueName = 'fourDigitUksic';
        childValueNumber = 4;
      } else if (codeValue?.length == 2) {
        if(division){
          snewcase('nacedivision')
          parentValueName = 'twoDigitUksic';
          parentValueNumber = 2;
        }
        else{
          snewcase('10')
          parentValueName = 'twoDigitUksic';
          parentValueNumber = 2;
          childValueName = 'threeDigitUksic';
          childValueNumber = 3;
        }
      }


      parentCategoryData = detailData?.find((data: any) => {
        return data?.values == parentValueName;
      });

      if (parentCategoryData) {
        setParentCategory(parentCategoryData?.data);
      }
      childCategoryData = detailData?.find((data: any) => {
        return data?.values == childValueName;
      });
      var isicData;
      var six___;
      var eight___;
      if (childCategoryData) {
        setChildCategory(childCategoryData?.data);
      } else {
        isicData = detailData.find((data: any) => {
          return data?.values == codeType; // type isic
        });
        childCategoryData = isicData?.data?.filter((data: any) => {
          return data?.isic_code?.length == childValueNumber;
        });
        setChildCategory(childCategoryData);
      }

      isicData = detailData.find((data: any) => {
        return data?.values == codeType; // type sic
      });

      six___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 6;
      });
      eight___ = isicData?.data?.filter((data: any) => {
        return data?.isic_code?.length == 8;
      });

      if (six___) {
        setSixDigitData(six___);
      }
      if (eight___) {
        setEightDigitData(eight___);
      }
    }




  };

  const replacer1 = (any: any) => {
    var matches = any.match(/\d+/)[0];
    return (
      `<a href="/detail/` +
      codeType +
      '/' +
      matches +
      `" target='_blank' style='display: inline;font-weight: 500;cursor:pointer;'>` +
      `<hlink>` +
      any +
      `<hlink>` +
      `</a>`
    );
  };

  const convertToLink1 = (crossref_desc: any) => {
    if (crossref_desc) {
      var re = new RegExp('<hlink>(.*?)</hlink>', 'gi');
      crossref_desc = crossref_desc.replace(re, replacer1);
      console.log(crossref_desc)
      return crossref_desc;
    }
  };

  const convertToLink2 = (long_desc: any) => {
    if (long_desc) {
      var re = new RegExp('<hlink>(.*?)</hlink>', 'gi');
      long_desc = long_desc.replace(re, replacer1);
      return long_desc;
    }
  };


 function hlink(linkValue:any,codeTypes:any){

  let hlinkValues:any;

  let selectedTabValueString = sessionStorage.getItem('selectedtabvalue');

  if (selectedTabValueString !== null)
   {
    let selectedTabValue = JSON.parse(selectedTabValueString);
    hlinkValues = selectedTabValue[0]
   } 
  else
   {
    console.log("selectedtabvalue is null in localStorage");
   }

  let matchingObject = null;

  if (hlinkValues)
   {
     for (let i = 0; i < hlinkValues[codeTypes]?.length; i++)
      {
       if (hlinkValues[codeTypes][i]?.division == linkValue)
        {
          matchingObject = hlinkValues[codeTypes][i]?.code_range;
          break;
        }
      }
   }

  if (matchingObject)
   {
    let codeRangeValue = matchingObject;
    return codeRangeValue;
   } 
  else 
   {
    return null;
   }
}

 const replacer = (match: string, p1: string, codeType: string) => {
  const matches = p1.match(/\b\d+(\.\d+)?\b|\w+/g);

  if (matches && matches.length > 0) {
      const isSpecialCase = /\bhlink>(section|division|class|group|Group) (\w+)<hlink/.exec(match);

      if (isSpecialCase && isSpecialCase[2]) {
          const linkValue = isSpecialCase[2];

          if (/^[A-Z]$/.test(linkValue)) {

            if(linkValue){

              let hyperlinkvalue = hlink(linkValue,codeType);

              return (
                  `<a href="/detail/${codeType}/${hyperlinkvalue}/${linkValue}" target='_blank' style='display: inline; font-weight: 500; cursor: pointer;'>${p1}</a>`
              );

            }

              return (
                  `<a href="/detail/${codeType}/${linkValue}" target='_blank' style='display: inline; font-weight: 500; cursor: pointer;'>${p1}</a>`
              );
          } else {
              return (
                  `<a href="/detail/${codeType}/${linkValue}" target='_blank' style='display: inline; font-weight: 500; cursor: pointer;'>${p1}</a>`
              );
          }
      } 
      
      else if (/^\d+$/.test(matches[0])) {
        return (
          `<a href="/detail/${codeType}/${matches[0]}" target='_blank' style='display: inline; font-weight: 500; cursor: pointer;'>${matches[0]}</a> ${matches[1]}`
        );
      }
  }

  return p1;
};




const convertToLink = (crossref_desc: string | undefined, codeType: string) => {
  if (crossref_desc) {
    const re = new RegExp("<hlink>(.*?)<hlink>", 'gi');
    crossref_desc = crossref_desc.replace(re, (match, p1) => replacer(match, p1, codeType));
    return crossref_desc as string; // Use type assertion
  }

  // Provide a default value (an empty string) if crossref_desc is undefined
  return "";
};


const replacerx = (match: string, p1: string, codeType: string) => {
  const matches = p1.match(/\b\d+(\.\d+)?\b/g);

  if (matches && matches.length > 0) {
    const combinedMatches = matches.join(''); // Combine all matched digits
    return (
      `<a href="/detail/` +
      codeType +
      '/' +
      combinedMatches +
      `" target='_blank' style='display: inline;font-weight: 500;cursor:pointer;'>` +
      p1 +
      `</a>`
    );
  }

  // Handle the case when no match is found
  return p1;
};

const convertToLinkx = (crossref_desc: string | undefined, codeType: string) => {
  if (crossref_desc) {
    const re = new RegExp("<hlink>(.*?)<hlink>", 'gi');
    crossref_desc = crossref_desc.replace(re, (match, p1) => replacerx(match, p1, codeType));
    return crossref_desc as string; // Use type assertion
  }

  // Provide a default value (an empty string) if crossref_desc is undefined
  return "";
};


  const convertToLinknaics = (long_desc: string | undefined, codeType: string) => {
    if (long_desc) {
      const re = new RegExp("<hlink>(.*?)<hlink>", 'gi');
      long_desc = long_desc.replace(re, (match, p1) => replacer(match, p1, codeType));
      return long_desc as string; // Use type assertion
    }
  
    // Provide a default value (an empty string) if crossref_desc is undefined
    return "";
  };


  const navigateToDetailPage = (menuValueSelected: any) => {

    if (
      (menuValueSelected.sic_code && menuValueSelected.sic_code.includes('-')) ||
      (menuValueSelected.sic_code && menuValueSelected[codeTypeString] === codeValue && menuValueSelected.division != division) ||
      (menuValueSelected.isic_code && menuValueSelected.isic_code.includes('-')) ||
      (menuValueSelected.isic_code && menuValueSelected[codeTypeString] === codeValue && menuValueSelected.division != division) ||
      (menuValueSelected.nic_code && menuValueSelected.nic_code.includes('-')) ||
      (menuValueSelected.nic_code && menuValueSelected[codeTypeString] === codeValue && menuValueSelected.division != division) ||
      (menuValueSelected.nace_code && menuValueSelected.nace_code.includes('-')) ||
      (menuValueSelected.nace_code && menuValueSelected[codeTypeString] === codeValue && menuValueSelected.division != division) ||
      (newx?.length == 7 ? '' : (menuValueSelected.uksic_code && menuValueSelected.uksic_code.includes('-')) ||
      (menuValueSelected.uksic_code && menuValueSelected[codeTypeString] === codeValue && menuValueSelected.division != division)
      ))
       {
      // Update the URL with the division value
      var codeWithDivision =
        menuValueSelected?.sic_code ||
        menuValueSelected?.naics_code ||
        menuValueSelected?.isic_code ||
        menuValueSelected?.nace_code ||
        menuValueSelected?.nic_code ||
        menuValueSelected?.uksic_code ||
        menuValueSelected?.code_range;
  
      // Include division if it exists
      if (menuValueSelected?.division) {
        codeWithDivision += '/' + menuValueSelected.division;
      }
 
            window.location.href = '/detail/' + codeType + '/' + codeWithDivision;
  
    } else {
      var code =
        menuValueSelected?.sic_code ||
        menuValueSelected?.naics_code ||
        menuValueSelected?.isic_code ||
        menuValueSelected?.nace_code ||
        menuValueSelected?.nic_code ||
        menuValueSelected?.uksic_code ||
        menuValueSelected?.code_range;
  
        window.location.href = '/detail/' + codeType + '/' + code;
    }
  };

  return (
    <Box
      className="h-tab"
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className="me-5 vtab-aside pt-5"
      >
        {codeDetails?.long_desc && (
          <Tab label="Description" onClick={() => moveToRef(descriptionRef)} />
        )}


<Tab label="Classification Hierarchy" onClick={() => moveToRef(classificationRef)} />


        {typeof codeExamples?.data == 'object' &&
          (codeType == 'sic' || codeType == 'naics') &&
          codeExamples?.data?.length > 0 && (
            <Tab
              label="
              Industry Example"
              onClick={() => moveToRef(examplesRef)}
            />
          )}

        {typeof codeExamples?.data == 'object' &&
          (codeType == 'isic' || codeType == 'nace' || codeType == 'nic' || codeType == 'uksic') &&
          codeExamples?.data?.length > 0 && (
            <Tab
              label="
              Inclusions"
              onClick={() => moveToRef(examplesRef)}
            />
          )}

        {typeof codeExamples?.data  &&
          ( codeType == 'nic') &&
          codeExamples?.data == 'There is not correct combination' && (
            <Tab
              label="
              Inclusions"
              onClick={() => moveToRef(examplesRef)}
            />
          )}

        {crossRefs?.data?.length > 0 &&
          (codeType == 'sic' || codeType == 'naics') && (
            <Tab
              label="     
              Cross References"
              onClick={() => moveToRef(crossRef)}
            />
          )}

        {crossRefs?.data?.length > 0 &&
          (codeType == 'isic' || codeType == 'nace' || codeType == 'nic' || codeType == 'uksic') && (
            <Tab
              label="
          Exclusions"
              onClick={() => moveToRef(crossRef)}
            />
          )}

        <span>{sixDigitData?.data?.sic_code}</span>

        {codeType == 'sic' &&
          codeValue?.length > 3 &&
          sixDigitData?.length > 0 && (
            <Tab label="6 digit SIC" onClick={() => moveToRef(sixDigitRef)} />
          )}
        {codeType == 'sic' &&
          codeValue?.length > 3 &&
          eightDigitData?.length > 0 && (
            <Tab label="8 digit SIC" onClick={() => moveToRef(eightDigitRef)} />
          )}
        {codeType == 'naics' &&
          codeValue?.length > 4 &&
          sixDigitData?.length > 0 && (
            <Tab label="6 digit NAICS" onClick={() => moveToRef(sixDigitRef)} />
          )}
        {codeType == 'naics' &&
          codeValue?.length > 4 &&
          eightDigitData?.length > 0 && (
            <Tab
              label="8 digit NAICS"
              onClick={() => moveToRef(eightDigitRef)}
            />
          )}
      </Tabs>

      {/* <Tabs
        value={menuValueSelected}
        onChange={menuChange}
        aria-label="secondary tabs example"
        centered
        className="home-tabs"
      >
        <Tab value="sic" label="SIC" />
        <Tab value="naics" label="NAICS" />
        <Tab value="isic" label="ISIC" />
        <Tab value="nace" label="NACE" />
      </Tabs> */}

      <TabPanel value={value} index={0}>
        <div
          className="tab-panel-wrapper"
          style={{ paddingBottom: '1.5rem' }}
          ref={descriptionRef}
        >
          {codeDetails?.long_desc && (codeType === 'sic' || codeType === 'naics' || codeType == 'isic' || codeType == 'nace' || codeType == 'nic' || codeType == 'uksic')  && (!division) && (
            <h2 className="mb-4 block-title">
              {/* {codeType?.toUpperCase()} Code {codeValue} Description */}
             Description of {codeType?.toUpperCase()} Code {codeValue}
            </h2>
          )}

          {codeDetails?.long_desc && (codeType === 'sic' || codeType == 'isic' || codeType == 'nace' || codeType == 'nic' || codeType == 'uksic')  && (division) && (
            <h2 className="mb-4 block-title">
             Description of {codeType?.toUpperCase()}  Code Division {division}
            </h2>
          )}


           {codeDetails?.long_desc && (codeType === 'sic' || codeType == 'isic' || codeType == 'nace' || codeType == 'nic' || codeType == 'uksic') && (
            <div>
              <small
                className="txt-samll"
                dangerouslySetInnerHTML={{
                  __html: convertToLink2(codeDetails?.long_desc)
                }}
              ></small>
            </div>
          )}
          {codeDetails?.long_desc && codeType == 'naics' && (
            <div>
              <small
                className="txt-samll"
                dangerouslySetInnerHTML={{
                  __html: convertToLinknaics(codeDetails?.long_desc , codeType)
                }}
              ></small>
            </div>
          )}
          {codeDetails?.long_desc && codeType == 'sic'  && (
            <div
              className="dot-border pt-3 mb-5 mt-4"
              ref={classificationRef}
            ></div>
          )}
          {codeDetails?.long_desc && codeType == 'naics'  && (
            <div
              ref={classificationRef}
            ></div>
          )}
          {codeDetails?.short_desc && codeType == 'isic'  && (
            <div
              ref={classificationRef}
            ></div>
          )}
          {codeDetails?.short_desc && codeType == 'nace'  && (
            <div
              ref={classificationRef}
            ></div>
          )}
          {codeDetails?.short_desc && codeType == 'nic'  && (
            <div
              ref={classificationRef}
            ></div>
          )}
          {codeDetails?.short_desc && codeType == 'uksic'  && (
            <div
              ref={classificationRef}
            ></div>
          )}
          {codeType === 'uksic' && (division)
          && codeValue.includes('.') && (uksiccodelength.length >= 7) && (
          <h2 className="mb-4 block-title">
          Classification Hierarchy of  {codeType?.toUpperCase()} {codeValue}/{division}
          </h2>
          )}

         {codeType === 'uksic' && (!division)
          && codeValue.includes('.') && (uksiccodelength.length >= 7) && (
          <h2 className="mb-4 block-title">
          Classification Hierarchy of  {codeType?.toUpperCase()} {codeValue}
          </h2>
          )}

          {(codeType === 'sic') || (codeType === 'naics') || (codeType === 'isic') || (codeType === 'nace') || (codeType === 'nic')
          && (
          <h2 className="mb-4 block-title">
          Classification Hierarchy of  {codeType?.toUpperCase()} {codeValue}
          </h2>
          )}


          <div>
            <ul className="row classification-bar pb-4">
              <li className="col-md-6 d-flex flex-column mb-4 mb-md-0">
                {codeType == 'sic' && codeValue.length < 5 && (
                  <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'isic' &&
                  newcase != '2' && newcase != 'division' &&
                  codeValue.length < 5 && (
                    <small className="txt-small">Parent Category</small>
                  )}
                  {codeType == 'nic' &&
                  newcase != '2' && newcase != 'division' &&
                  codeValue.length < 5 && (
                    <small className="txt-small">Parent Category</small>
                  )}
                {codeType == 'sic' && newcase == '2' && (
                  <small className="txt-small">Subcategories</small>
                )}

                {codeType == 'isic' && newcase == '2' && (
                    <small className="txt-small">Subcategories</small>
                )}
                 {codeType == 'nic' && newcase == '2' && (
                    <small className="txt-small">Subclasses</small>
                )}
                 {codeType == 'isic' && newcase == 'division' && (
                    <small className="txt-small">Subcategories</small>
                )}
                {codeType == 'nic' && newcase == 'division' && (
                    <small className="txt-small">Subcategories</small>
                )}
                 {codeType == 'nic' && newcase == 'nic5' && (
                    <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'naics' &&
                  codeValue.length < 7 &&
                  newcase != '3' &&
                  specicalcase != '2' && (
                    <small className="txt-small">Parent Category</small>
                  )}

                {codeType == 'naics' &&
                  codeValue.length < 7 &&
                  newcase == '3' && (
                    <small className="txt-small">Subcategories</small>
                  )}

                {codeType == 'naics' && specicalcase == '2' && (
                  <small className="txt-small">Subcategories</small>
                )}

                {codeType == 'nace'  && newcase == '4' && (
                  <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'uksic'  && newcase == '4' && (
                  <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'nace'  && newcase == '5' && (
                  <small className="txt-small">Subcategories</small>
                )}
                 {codeType == 'uksic'  && newcase == '5' && (
                  <small className="txt-small">Subcategories</small>
                )}
                {codeType == 'nace'  && newcase == '10' && (
                  <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'uksic'  && newcase == '10' && (
                  <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'nace'  && newcase == '100' && (
                  <small className="txt-small">Parent Category</small>
                )}
                {codeType == 'uksic' && newcase == '501' && (
                  <small className="txt-small">Parent Category</small>
                )}
                 {codeType == 'nace'  && newcase == 'nacedivision' && (
                  <small className="txt-small">Subcategories</small>
                )}
                 {codeType == 'uksic'  && newcase == 'nacedivision' && (
                  <small className="txt-small">Subcategories</small>
                )}
                 {codeType == 'uksic'  && newcase == '99999' && (
                  <small className="txt-small">Parent Category</small>
                )}
               

                {parentCategory.map((x: any, index: any) => {
                  if (x?.[codeTypeString]) {
                    return (
                      <div
                        className={
                          
                              x.nace_code && x.nace_code.includes('.') || x.uksic_code && x.uksic_code.includes('.') 
                            ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                            : x?.[codeTypeString]?.length == 2
                            ? 'color-bar d-flex mt-2 pointer bg-light-orange'
                            : x?.[codeTypeString]?.length == 3
                            ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                            : x?.[codeTypeString]?.length == 4
                            ? 'color-bar d-flex mt-2 pointer bg-light-green'
                            : x?.[codeTypeString]?.length == 5
                            ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                            : 'color-bar d-flex mt-2 pointer bg-light-orange'
                        }
                        onClick={() => navigateToDetailPage(x)}
                      >
                        <span></span>
                        <span className="bar-nom">{x.isic_code && x.isic_code.includes('-') || x.isic_code && x?.[codeTypeString] === codeValue && x?.division != division ||
                        x.nic_code && x.nic_code.includes('-') || x.nic_code && x?.[codeTypeString] === codeValue && x?.division != division ||
                        x.sic_code && x.sic_code.includes('-') || x.sic_code && x?.[codeTypeString] === codeValue && x?.division != division ||
                        (newx?.length == 7 ? '' : (x.uksic_code && x.uksic_code.includes('-') || x.uksic_code && x?.[codeTypeString] === codeValue && x?.division != division)) ||
                        x.nace_code && x.nace_code.includes('-') || x.nace_code && x?.[codeTypeString] === codeValue && x?.division != division  ?  x.division : x?.[codeTypeString]}</span>
                        <span className="bar-desc">{x?.short_desc}</span>
                      </div>
                    );
                  }
                })}
              </li>


              <li className="col-md-6 d-flex flex-column ">
                {codeType == 'naics' &&
                  newcase != '3' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}

                {codeType == 'isic'  &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}

                {codeType == 'nic' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subclasses</small>
                  )}

                {codeType == 'nace'  &&
                  newcase == '4' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}

               {codeType == 'uksic' &&
                  newcase == '4' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}

                  
                {codeType == 'nace'  &&
                  newcase == '10' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}

                {codeType == 'uksic'  &&
                  newcase == '10' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}


                {/* {(codeType == 'uksic')  &&
                  (newcase == '501')  && (!division) &&  (codeValue.length == '7') &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small> ///    comment code
                  )} */}

                 {codeType == 'uksic'  &&
                  newcase == '501'  && codeDetails  && (checkukisc != 0)  &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small> ///    comment code
                  )}


                {codeType == 'uksic'  &&
                  newcase == '99999' &&
                  Object.keys(childCategory).length > 0 && (
                    <small className="txt-small">Subcategories</small>
                  )}



                {codeType == 'naics' &&
                  newcase != '3' &&
                  Object.keys(childCategory).map((key_0, i) => {
                    if (childCategory?.[key_0]?.[codeTypeString]) {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-orange'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          onClick={() =>
                            navigateToDetailPage(childCategory?.[key_0])
                          }
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          style={{ cursor: 'default' }}
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    }
                  })}

                {codeType == 'nace' && newcase != 'nacedivision' &&
                  Object.keys(childCategory).map((key_0, i) => {
                    if (childCategory?.[key_0]?.[codeTypeString]) {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-orange'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          onClick={() =>
                            navigateToDetailPage(childCategory?.[key_0])
                          }
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          style={{ cursor: 'default' }}
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    }
                  })}

                {codeType == 'isic' &&
                  Object.keys(childCategory).map((key_0, i) => {
                    if (childCategory?.[key_0]?.[codeTypeString]) {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          onClick={() =>
                            navigateToDetailPage(childCategory?.[key_0])
                          }
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          style={{ cursor: 'default' }}
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    }
                  })}


               {codeType == 'nic' &&
                  Object.keys(childCategory).map((key_0, i) => {
                    if (childCategory?.[key_0]?.[codeTypeString]) {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2  bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          onClick={() => {
                            if (childCategory?.[key_0]?.[codeTypeString]?.length !== 5) {
                              navigateToDetailPage(childCategory?.[key_0]);
                            }
                          }}   
                          style={{ cursor: childCategory?.[key_0]?.[codeTypeString]?.length == 5 ? 'default' : 'pointer' }}                   
                          >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          style={{ cursor: 'default' }}
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    }
              })}

{codeType == 'uksic' && newcase != 'nacedivision' &&
  Object.keys(childCategory).map((key_0, i) => {
    const codeValues = childCategory?.[key_0]?.[codeTypeString];
    console.log(codeValues)
    console.log(codeValue.length)
    console.log(codeDetails.uksic_code.length)
    if (codeValues && !division) {
      return (
        <div
          className={
            codeValues.length == 2
              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
              : codeValues.length == 3
              ? 'color-bar d-flex mt-2 pointer bg-light-orange'
              : codeValues.length == 4
              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
              : codeValues.length == 5
              ? 'color-bar d-flex mt-2 pointer bg-light-green'
              : 'color-bar d-flex mt-2 pointer bg-light-yellow'
          }
          onClick={() => navigateToDetailPage(childCategory?.[key_0])}
        >
          <span className="bar-nom">
            {codeValues}
          </span>
          <span className="bar-desc">
            {childCategory?.[key_0]?.short_desc}
          </span>
        </div>
      );
    } 
    else if(division > 0){
      return null
    }
  })
}


{/* {codeType == 'uksic' && newcase != 'nacedivision' &&
  Object.keys(childCategory).map((key_0, i) => {
    const codeValue = childCategory?.[key_0]?.[codeTypeString];
    if (codeValue && codeDetails && codeDetails ) {
      return (
        <div
          className={
            codeValue.length == 2
              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
              : codeValue.length == 3
              ? 'color-bar d-flex mt-2 pointer bg-light-orange'
              : codeValue.length == 4
              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
              : codeValue.length == 5
              ? 'color-bar d-flex mt-2 pointer bg-light-green'
              : 'color-bar d-flex mt-2 pointer bg-light-yellow'
          }
          onClick={() => {
            if (!division) {
              navigateToDetailPage(childCategory?.[key_0]);
            }
          }}   
          style={{ cursor: !division ? 'pointer' : 'default' }}                   
          >
          <span className="bar-nom">
            {childCategory?.[key_0]?.[codeTypeString]}
          </span>
          <span className="bar-desc">
            {childCategory?.[key_0]?.short_desc}
          </span>
        </div>
      );
    } else {
      return null; // Skip rendering if codeValue length is more than 4
    }
  })
} */}


                {/* {codeType == 'uksic' && newcase != 'nacedivision' &&
                  Object.keys(childCategory).map((key_0, i) => {
                    if (childCategory?.[key_0]?.[codeTypeString]) {
                      return (
                        <div
                          className={
                            childCategory?.[key_0]?.[codeTypeString]?.length ==
                            2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-orange'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : childCategory?.[key_0]?.[codeTypeString]
                                  ?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : 'color-bar d-flex mt-2 pointer bg-light-yellow'
                          }
                          onClick={() =>
                            navigateToDetailPage(childCategory?.[key_0])
                          }
                        >
                          <span className="bar-nom">
                            {childCategory?.[key_0]?.[codeTypeString]}
                          </span>
                          <span className="bar-desc">
                            {childCategory?.[key_0]?.short_desc}
                          </span>
                        </div>
                      );
                    } else {
                      // return (
                      //   <div
                      //     className={
                      //       childCategory?.[key_0]?.[codeTypeString]?.length ==
                      //       2
                      //         ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                      //         : childCategory?.[key_0]?.[codeTypeString]
                      //             ?.length == 3
                      //         ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                      //         : childCategory?.[key_0]?.[codeTypeString]
                      //             ?.length == 4
                      //         ? 'color-bar d-flex mt-2 pointer bg-light-green'
                      //         : childCategory?.[key_0]?.[codeTypeString]
                      //             ?.length == 5
                      //         ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                      //         : 'color-bar d-flex mt-2 pointer bg-light-blue'
                      //     }
                      //     style={{ cursor: 'default' }}
                      //   >
                      //     <span className="bar-nom">
                      //       {childCategory?.[key_0]?.[codeTypeString]}
                      //     </span>
                      //     <span className="bar-desc">
                      //       {childCategory?.[key_0]?.short_desc}
                      //     </span>
                      //   </div>
                      // );
                    }
                  })} */}


                {codeType == 'sic' &&
                  childCategory?.length > 0 &&
                  codeValue.length < 4 && (
                    <small className="txt-small">Subcategories</small>
                  )}
                {codeType == 'sic' &&
                  childCategory?.map((key_1: any, i: any) => {
                    if (key_1?.sic_code?.length <= 4) {
                      return (
                        <div
                          className={
                            key_1?.sic_code?.length == 2
                              ? 'color-bar d-flex mt-2 pointer bg-light-blue'
                              : key_1?.sic_code?.length == 3
                              ? 'color-bar d-flex mt-2 pointer bg-light-pink'
                              : key_1?.sic_code?.length == 4
                              ? 'color-bar d-flex mt-2 pointer bg-light-green'
                              : key_1?.sic_code?.length == 5
                              ? 'color-bar d-flex mt-2 pointer bg-light-yellow'
                              : 'color-bar d-flex mt-2 pointer bg-light-blue'
                          }
                          onClick={() => navigateToDetailPage(key_1)}
                        >
                          <span className="bar-nom">{key_1?.sic_code}</span>
                          <span className="bar-desc">{key_1?.short_desc}</span>
                        </div>
                      );
                    }
                  })}

              </li>
            </ul>
          </div>

          {typeof codeExamples?.data  &&
              ( codeType == 'nic') &&
              codeExamples?.data == 'There is not correct combination' && 
                (
                  <React.Fragment>
                    <div
                      className="dot-border pt-3 mb-5 mt-4"
                      ref={examplesRef}
                    ></div>
                 {(
                <h2 className="mb-4 block-title">
                  Inclusions of {codeType?.toUpperCase()} {codeValue}
                </h2>
                 )}
                <p className="txt-small">This class comprises all sub-classes listed as subcategories in the hierarchy section above.</p>
               </React.Fragment>
                )
              }


          <div
            style={{
              display: 'inline-block',
              marginBottom: '25px',
              width: '100%'
            }}
          >
            {typeof codeExamples?.data == 'object' &&
              codeExamples?.data?.length > 0 && (
                <React.Fragment>
                  <div
                    className="dot-border pt-3 mb-5 mt-4"
                    ref={examplesRef}
                  ></div>
            {codeType === 'sic' || codeType === 'naics' ? (
              <h2 className="mb-4 block-title">
                Industry Examples of {codeType?.toUpperCase()} {codeValue}
              </h2>
            ) : codeType === 'isic' && division ? (
              <h2 className="mb-4 block-title">
                Inclusions of  {codeType?.toUpperCase()} {codeValue}
              </h2>
            ) : (codeType === 'uksic') && (!division) ? (
              <h2 className="mb-4 block-title">
                Inclusions of {codeType?.toUpperCase()} {codeValue}
              </h2>
            ) : (codeType === 'uksic') && (division) && (uksiccodelength.length >= 7) ? (
              <h2 className="mb-4 block-title">
                Inclusions of {codeType?.toUpperCase()} {codeValue}/{division}
              </h2>
            ) :
             (
              <h2 className="mb-4 block-title">
                Inclusions of {codeType?.toUpperCase()} {codeValue}
              </h2>
            )}
                  {codeExamples?.data?.map((data1: any) => {
                    if (
                      data1?.cat_subcat === 'null' &&
                      data1?.example_desc == 'This class includes:'
                    ) {
                      return <p className="txt-small">{data1?.example_desc}</p>;
                    } else if (data1?.cat_subcat == 'cat') {
                      return (
                        <div
                          className="txt-small"
                          style={{ marginTop: '1rem' }}
                          key={data1.id}
                        >
                           <li className="txt-small" style={{ marginTop: '1rem' }}>
                        {data1?.example_desc && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertToLinkx(data1?.example_desc , codeType)
                            }}
                          ></span>
                        )}
                      </li>
                          {/* <li>{data1?.example_desc}</li> */}
                        </div>
                      );
                    } else if (data1?.cat_subcat == 'subcat') {
                      return (
                        <div
                          className="txt-small"
                          style={{ paddingLeft: '2.5rem' }}
                          key={data1.id}
                        >
                          <ul style={{ listStyleType: 'circle' }}>
                            <li>{data1?.example_desc}</li>
                          </ul>
                        </div>
                      );
                    } else if (data1?.sic_code) {
                      return (
                        <p className="industry_para">{data1?.example_desc}</p>
                      );
                    } else if (data1?.naics_code) {
                      return (
                        <p className="industry_para">{data1?.example_desc}</p>
                      );
                    } else {
                      return (
                        <p
                          className="txt-small"
                          style={{
                            marginBottom: '1.5rem',
                            marginTop: '1.5rem'
                          }}
                        >
                           {data1?.example_desc && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: convertToLink(data1?.example_desc, codeType)
                            }}
                          ></p>
                        )}
                        </p>
                      );
                    }
                  })}
                </React.Fragment>
              )}
            </div>


          
          <div
            style={{
              display: 'inline-block',
              marginBottom: '25px',
              width: '100%'
            }}
          >
            {crossRefs?.data?.length > 0 && (
              <React.Fragment>
                <div className="dot-border pt-3 mb-5 mt-4" ref={crossRef}></div>
                {((codeType == 'sic') || (codeType == 'naics')) ? (
                  <h2 className="mb-4 block-title">Cross References</h2>
                ) :  (codeType === 'uksic') && (!division) ? (
                  <h2 className="mb-4 block-title">
                    Exclusions of {codeType?.toUpperCase()} {codeValue}
                  </h2>
                ) : (codeType === 'uksic') && (division) && (uksiccodelength.length >= 7) ? (
                  <h2 className="mb-4 block-title">
                   Exclusions of {codeType?.toUpperCase()} {codeValue}/{division}
                  </h2>
                ) :
                (
                  <h2 className="mb-4 block-title">
                    Exclusions of {codeType?.toUpperCase()} {codeValue}
                  </h2>
                )}
                {crossRefs?.data?.map((data1: any) => {
                  if (
                    data1?.cat_subcat === 'null' &&
                    data1?.crossref_desc == 'This class excludes:'
                  ) {
                    return (
                      <p className="txt-small" style={{ marginTop: '1rem'}}>
                        {data1?.crossref_desc && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertToLink(data1?.crossref_desc , codeType)
                            }}
                          ></span>
                        )}
                      </p>
                    );
                  } else if (data1?.cat_subcat === 'cat') {
                    return (
                      <li className="txt-small" style={{ marginTop: '1rem' }}>
                        {data1?.crossref_desc && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertToLinkx(data1?.crossref_desc , codeType)
                            }}
                          ></span>
                        )}
                      </li>
                    );
                  } else if (data1?.sic_code) {
                    return (
                      <span className="crossref_para">
                        {data1?.crossref_desc && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertToLink1(data1?.crossref_desc)
                            }}
                          ></span>
                        )}
                      </span>
                    );
                  } else if (data1?.naics_code) {
                    return (
                      <span className="crossref_para">
                        {data1?.crossref_desc && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertToLink(data1?.crossref_desc , codeType)
                            }}
                          ></span>
                        )}
                      </span>
                    );
                  } else {
                    return (
                      <p
                        className="txt-small"
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      >
                        {data1?.crossref_desc && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertToLink(data1?.crossref_desc , codeType)
                            }}
                          ></span>
                        )}
                      </p>
                    );
                  }
                })}
              </React.Fragment>
            )}
          </div>

          <div style={{ display: 'inline-block', width: '100%' }}>
            {sixDigitData?.length > 0 && codeValue?.length > 4 && (
              <React.Fragment>
                <div className="dot-border" ref={sixDigitRef}></div>
                <h2 className="mb-4 block-title" style={{ marginTop: '25px' }}>
                  6 digit <span>{codeType?.toUpperCase()}</span>
                </h2>
                {sixDigitData?.map((data1: any) => {
                  return (
                    <div>
                      <span className="six_eight_para">
                        {data1?.sic_code || data1?.naics_code} -{' '}
                        {data1?.short_desc}
                      </span>
                    </div>
                  );
                })}
              </React.Fragment>
            )}
            {codeType == 'sic' &&
              sixDigitData?.length > 0 &&
              codeValue?.length > 3 && (
                <React.Fragment>
                  <div className="dot-border" ref={sixDigitRef}></div>
                  <h2
                    className="mb-4 block-title"
                    style={{ marginTop: '25px' }}
                  >
                    6 digit <span>{codeType?.toUpperCase()}</span>
                  </h2>
                  {sixDigitData?.map((data1: any) => {
                    return (
                      <div>
                        <span className="six_eight_para">
                          {data1?.sic_code || data1?.naics_code} -{' '}
                          {data1?.short_desc}
                        </span>
                      </div>
                    );
                  })}
                </React.Fragment>
              )}
          </div>

          <div style={{ display: 'inline-block', width: '100%' }}>
            {eightDigitData?.length > 0 && codeValue?.length > 3 && (
              <React.Fragment>
                <div
                  className="dot-border pt-3 mb-5 mt-4"
                  ref={eightDigitRef}
                ></div>
                <h2 className="mb-4 block-title">
                  8 digit <span>{codeType?.toUpperCase()}</span>
                </h2>
                {eightDigitData?.map((data1: any) => {
                  return (
                    <div>
                      <span className="six_eight_para">
                        {data1?.sic_code} - {data1?.short_desc}
                      </span>
                    </div>
                  );
                })}
              </React.Fragment>
            )}
            {eightDigitData?.length > 0 && codeValue?.length > 4 && (
              <React.Fragment>
                <div
                  className="dot-border pt-3 mb-5 mt-4"
                  ref={eightDigitRef}
                ></div>
                <h2 className="mb-4 block-title">
                  8 digit <span>{codeType?.toUpperCase()}</span>
                </h2>
                {eightDigitData?.map((data1: any) => {
                  return (
                    <div>
                      <span className="six_eight_para">
                        {data1?.sic_code} - {data1?.short_desc}
                      </span>
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          </div>
        </div>
      </TabPanel>
    </Box>
  );
}
