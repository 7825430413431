// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer {
  color: var(--white);
  font-size: 0.813rem; }

#footer a {
  color: var(--white);
  font-size: 0.813rem; }

.footer-nav {
  gap: 2.3rem; }

#footer span {
  color: var(--red); }
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB,EAAA;;AAErB;EACE,mBAAmB;EACnB,mBAAmB,EAAA;;AAErB;EACE,WAAW,EAAA;;AAEb;EACE,iBAAiB,EAAA","sourcesContent":["#footer {\n  color: var(--white);\n  font-size: 0.813rem;\n}\n#footer a {\n  color: var(--white);\n  font-size: 0.813rem;\n}\n.footer-nav {\n  gap: 2.3rem;\n}\n#footer span {\n  color: var(--red);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
