import * as React from 'react';
import {Helmet} from "react-helmet";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import naceTimeline from 'assets/nace-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function NACE(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const historyRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/codeSystem');
  };

  React.useEffect(() => {
    getSearchResults();
    window.scrollTo(0, 0);
  }, []);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  const getSearchResults = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/nace',
      success: (jsonResponse) => {
        updateArray(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  const subsectors = [
    '3',
    '5 ',
    '24',
    '1',
    '4',
    '3',
    '3',
    '5',
    '2',
    '6',
    '3',
    '1',
    '7',
    '6',
    '1',
    '1',
    '3',
    '4',
    '3',
    '2',
    '1'
  ];
  const industry_groups = [
    '13',
    '10',
    '95',
    '3',
    '6',
    '9',
    '21',
    '15',
    '7',
    '13',
    '10',
    '3',
    '15',
    '19',
    '3',
    '6',
    '9',
    '5',
    '6',
    '3',
    '1'
  ];
  const naics_industries = [
    '39',
    '15',
    '230',
    '8',
    '9',
    '22',
    '91',
    '23',
    '8',
    '26',
    '18',
    '4',
    '19',
    '33',
    '9',
    '11',
    '12',
    '15',
    '19',
    '3',
    '1'
  ];
  const us_detail1 = [
    '55',
    '30',
    '349',
    '12',
    '19',
    '34',
    '115',
    '43',
    '17',
    '45',
    '31',
    '8',
    '41',
    '58',
    '13',
    '18',
    '24',
    '24',
    '28',
    '8',
    '3'
  ];

  function updateArray(jsonResponse: any) {
    if (jsonResponse) {
      const updatedData = jsonResponse?.data?.map((item: any, i: number) => {
        if (item?.nace) {
          const updatednace = item.nace.map((obj: any, j: number) => ({
            ...obj,
            subsectors: subsectors[j],
            industry_groups: industry_groups[j],
            naics_industries: naics_industries[j],
            us_detail1: us_detail1[j]
          }));

          return {
            ...item,
            nace: updatednace
          };
        }
        return item;
      });
      setSearchResults(updatedData);
    }
  }

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Know about NACE Code | Understanding International Standard Industrial Classification System </title>    
      <meta name="Learn about the International Standard Industrial Classification (NACE) code system, its historical significance, hierarchical structure, and usage in statistical reporting, business identification, economic research, and policy development. "/>
      <meta name="keywords" content="NACE code, International Standard Industrial Classification System, Industry categorization, Economic analysis, Statistical reporting, Business identification, Policy development, Historical insights."/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Know about NACE code
              </h2>
              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section style={{ padding: '3.5rem 10%' }}>
          <Box
            className="h-tab"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <div>
              The Nomenclature statistique des activités économiques dans la
              Communauté européenne (NACE), or the Statistical Classification of
              Economic Activities in the European Community, is a cornerstone of
              economic classification in Europe. Developed collaboratively, NACE
              provides a structured framework for categorizing industries based
              on their core economic activities. Originating in the mid-20th
              century, NACE has undergone several revisions to accurately
              reflect the evolving European business landscape. It serves as a
              vital tool for statistical reporting, policy formulation, and
              cross-border trade analysis within the European Union. NACE's
              comprehensive hierarchy allows for precise industry
              identification, enabling researchers, policymakers, and analysts
              to dissect industry trends, evaluate market dynamics, and
              facilitate informed decision-making. With its enduring importance,
              NACE continues to harmonize data interpretation, guide policy
              coordination, and offer valuable insights into Europe's intricate
              economic tapestry.
            </div>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="me-5 vtab-aside  home-tabs"
              style={{ marginBottom: '24px', marginTop: '36px' }}
            >
              <Tab
                label="Structural Overview"
                onClick={() => moveToRef(structuralRef)}
              />
              <Tab label="History" onClick={() => moveToRef(historyRef)} />
              <Tab label="Timelines" onClick={() => moveToRef(timelineRef)} />
              <Tab
                label="Usage & Importance"
                onClick={() => moveToRef(usageRef)}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div
                className="tab-panel-wrapper"
                style={{ paddingBottom: '.5rem' }}
                ref={structuralRef}
              >
                <div className="code-info-parent">
                  <div className="code-info-wrap">
                    <div className="grid-container">
                      <div>
                        <div> </div>
                      </div>
                      <div
                        className="white"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          C
                        </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          0
                        </div>
                      </div>{' '}
                      <div
                        className="pink"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          .5
                        </div>
                      </div>{' '}
                      <div
                        className="green"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          2
                        </div>
                      </div>
                      <div className="item7 white"></div>
                      <div className="item8 orange"></div>
                      <div className="item9 orange"></div>
                      <div className="item10 pink"></div>
                      <div className="item11 green"></div>
                      <div
                        className="item32 white table-sector-name"
                        style={{ color: 'black', textAlign: 'end' }}
                      >
                        Section&nbsp;
                      </div>
                      <div
                        className="item33 orange table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Division&nbsp;
                      </div>
                      <div
                        className="item13 pink table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Group&nbsp;
                      </div>
                      <div
                        className="item14 green table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Class&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-panel-wrapper">
                <table id="structural-table">
                  <tr>
                    <th>Section</th>
                    <th>Range</th>
                    <th style={{textAlign:'left' , width:'50%'}}>Title</th>
                    <th>Division 2-digit</th>
                    <th>Group 3-digit</th>
                    <th>Class 4-digit</th>
                    <th>Total</th>
                  </tr>

                  {searchResults?.map((item: any, i: number) => {
                    if (item?.nace) {
                      return item?.nace?.map((data: any, j: number) => {
                        return (
                          <tr  key={data.id}
                          className={j % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{data?.division}</td>
                            <td>{data?.code_range}</td>
                            <td style={{textAlign:'left'}}>{data?.short_desc}</td>
                            <td>{data?.subsectors}</td>
                            <td>{data?.industry_groups}</td>
                            <td>{data?.naics_industries}</td>
                            <td>{data?.us_detail1}</td>
                          </tr>
                        );
                      });
                    }
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{textAlign:'left'}}>Total</td>
                    <td>88</td>
                    <td>272</td>
                    <td>615</td>
                    <td>975</td>
                  </tr>
                </table>
              </div>
            </TabPanel>

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '32px',
                paddingBottom: '16px'
              }}
              ref={historyRef}
            >
              History
            </h2>

            <div>
              The evolution of the Nomenclature statistique des activités
              économiques dans la Communauté européenne (NACE), or the
              Statistical Classification of Economic Activities in the European
              Community, is a testament to the European Union's commitment to
              standardizing and harmonizing economic data across its member
              states. The inception of NACE can be traced back to the mid-20th
              century when the need for a unified classification system to
              facilitate economic analysis and policy coordination became
              increasingly evident within the evolving framework of the European
              Community.
            </div>

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Timeline of NACE
            </h2>

            <embed src={naceTimeline} />

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '56px',
                paddingBottom: '16px'
              }}
              ref={usageRef}
            >
              Usage & Importance of NACE
            </h2>

            <div className="usage-table">
              <div className="usage-table-left">
                <h3>Usage</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Statistical Reporting:</strong> NACE codes serve as
                    the bedrock for consistent and reliable economic data
                    reporting, facilitating cross-country comparisons and
                    analysis.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Coordination:</strong> Governments and
                    policymakers rely on NACE classifications to develop and
                    implement harmonized regulations and policies that address
                    the unique needs of different economic sectors.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Trade and Economic Analysis:</strong> NACE's
                    standardized language enables effective analysis of trade
                    flows, market trends, and economic performance across the
                    European Union.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Research and Insights:</strong> Researchers,
                    analysts, and economists leverage NACE classifications to
                    study industry dynamics, track trends, and contribute to a
                    deeper understanding of the EU's economic landscape.
                  </li>
                </ul>
              </div>
              <div className="usage-table-right">
                <h3>Importance</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Harmonization:</strong> NACE plays a pivotal role in
                    harmonizing economic data collection and reporting across
                    diverse member states, fostering a common ground for
                    meaningful comparisons.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Alignment: </strong> NACE supports the EU's
                    efforts in coordinating policies, enabling targeted
                    interventions, and promoting sustainable economic growth
                    within the Union.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Global Engagement:</strong> NACE's structured
                    framework enhances the EU's ability to engage in
                    international economic discourse, negotiations, and
                    collaborations with a unified perspective.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Data Integrity:</strong> By providing a
                    comprehensive and standardized classification, NACE ensures
                    the accuracy and reliability of economic statistics,
                    underpinning informed decision-making.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              The history of NACE is a story of continuous evolution, a journey
              that reflects the commitment of the European Union to facilitate
              effective economic analysis, encourage harmonious policy
              development, and foster a deeper understanding of the dynamic
              economic activities within the European Community.
            </div>
          </Box>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
