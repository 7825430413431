import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './BasicAccordion.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Switch } from '@mui/material';

export default function BasicAccordion(props: any) {
  var propsData: any = props;

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const { state } = useLocation();
  // const propsData: any = React.useState(state)[0];

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [bgYellow, setBgYellow]: any = React.useState('bg-light-yellow-hover');
  const [bgGreen, setBgGreen]: any = React.useState('bg-light-green-hover');
  const [bgPink, setBgPink]: any = React.useState('bg-light-pink-hover');
  const [bgBlue, setBgBlue]: any = React.useState('bg-light-blue-hover');
  const [blueStrip, setBlueStrip]: any = React.useState('bg-blue-strip');
  const [yellowStrip, setYellowStrip]: any = React.useState('bg-yellow-strip');
  const [greenStrip, setGreenStrip]: any = React.useState('bg-green-strip');

  const [hoverTargetId, setHoverTargetId]: any = React.useState(undefined);
  const [expandedAccordionIndexes, setExpandedAccordionIndexes] =
    React.useState<number[]>([0]);

  const myRefname = React.useRef(null);

  const [accordionExpandedBoolean, setExpandedAccordionBoolean] =
    React.useState<object>({ value: false });
  const [expandedAccordionString, setExpandedAccordionString] =
    React.useState<string>('panel1' + 0);

  const expandAccordion =
    (panel: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      Object.values(propsData?.structureDetails)?.map((level: any, i: any) => {
        if (panel === level) {
          const currentIndex = expandedAccordionIndexes.indexOf(i);

          if (currentIndex !== -1) {
            setExpandedAccordionIndexes((prevIndexes) => [
              ...prevIndexes.slice(currentIndex + 1)
            ]);
          } else {
            // Accordion is not expanded, so open it
            setExpandedAccordionIndexes([i]);
          }
        }
      });
    };

  const [expandAllBoolean, setExpandAllChecked] = React.useState(false);
  const expandAllAccordion = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setExpandAllChecked(true);

      // Also, update the expandedAccordionIndexes state accordingly
      setExpandedAccordionIndexes(
        Object.keys(propsData?.structureDetails).map(Number)
      );
    } else {
      // Update the state and re-render
      setExpandAllChecked(false);

      // Clear the expandedAccordionIndexes state
      setExpandedAccordionIndexes([]);
    }
  };

  const navigate = useNavigate();
  const navigateToDetailPage = (menuValueSelected: any) => {
    var code =
      menuValueSelected?.sic_code ||
      menuValueSelected?.naics_code ||
      menuValueSelected?.isic_code ||
      menuValueSelected?.nace_code ||
      menuValueSelected?.nic_code ||
      menuValueSelected?.uksic_code;

    navigate('/detail/' + codeType + '/' + code);
  };

  function changeBackground(e: any) {
    setHoverTargetId(e?.target?.id);
  }

  function resetBackground(e: any) {
    setHoverTargetId(undefined);
  }

  const MAX_CHARACTERS = 98; // Set your desired character limit

  // Truncate function to limit the number of characters
  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div>
      <div className="text-end my-3">
        <Switch
          className="custom-switch"
          {...label}
          checked={expandAllBoolean}
          onChange={expandAllAccordion}
          ref={myRefname}
        />
        <span>Expand all rows</span>
      </div>
      <div className="accordion-one">
        <div className="acc-block">
          {Object.values(propsData?.structureDetails).map(
            (level1: any, index1) => {
              return (
                <Accordion
                  key={index1}
                  expanded={
                    expandAllBoolean ||
                    expandedAccordionIndexes.includes(index1)
                  }
                  onChange={expandAccordion(level1)}
                  className="cutom-accordion mb-3"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="acc-title" sx={{ flexShrink: 0 }}>
                      <span className="acc-title-count">
                        {level1?.naics_code ||
                          level1?.sic_code ||
                          level1?.isic_code ||
                          level1?.nace_code ||
                          level1?.nic_code ||
                          level1?.uksic_code}
                      </span>{' '}
                      {truncateText(level1?.short_desc, MAX_CHARACTERS)}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div
                      className={
                        codeType === 'nic'
                          ? 'custom-acc-grid-1-nic'
                          : codeType === 'naics'
                          ? 'custom-acc-grid-1-nacis'
                          : 'custom-acc-grid-1-sic'
                      }
                    >
                      <div className="col-md-3 d-flex flex-column">
                        <div
                          className={
                            'dealer-data bg-light-pink pointer ' +
                            (hoverTargetId?.substring(0, 3) ==
                            (level1?.naics_code ||
                              level1?.sic_code ||
                              level1?.isic_code ||
                              level1?.nace_code ||
                              level1?.nic_code ||
                              level1?.uksic_code)
                              ? bgPink
                              : 'empty')
                          }
                          onClick={() => navigateToDetailPage(level1)}
                        >
                          <strong>
                            {level1?.naics_code ||
                              level1?.sic_code ||
                              level1?.isic_code ||
                              level1?.nace_code ||
                              level1?.nic_code ||
                              level1?.uksic_code}
                          </strong>{' '}
                          &nbsp;&#8210;&nbsp;&nbsp; {level1?.short_desc}
                        </div>
                      </div>
                      <div className="col-md-9 d-flex flex-column">
                        <div className="col-md-12 d-flex flex-column">
                          {codeType == 'isic' ||
                            codeType == 'nace' ||
                            codeType == 'nic' ||
                            codeType == 'uksic' ||
                            Object.values(
                              codeType == 'naics' ? level1 : level1.sic_4_digit
                            ).map((level2: any, index2) => {
                              if (level2?.naics_code || level2?.sic_code) {
                                return (
                                  <div
                                    className={
                                      codeType == 'naics'
                                        ? 'custom-acc-grid-2-nacis'
                                        : 'custom-acc-grid-2-sic'
                                    }
                                  >
                                    <div className="col-md-4 d-flex flex-column">
                                      <div
                                        id={
                                          level2?.naics_code || level2?.sic_code
                                        }
                                        className={
                                          'dealer-data bg-light-green pointer ' +
                                          (hoverTargetId?.substring(0, 4) ==
                                          (level2?.naics_code ||
                                            level2?.sic_code)
                                            ? bgGreen
                                            : 'empty') +
                                          ' ' +
                                          (hoverTargetId?.substring(0, 3) ==
                                          (level1?.naics_code ||
                                            level1?.sic_code)
                                            ? greenStrip
                                            : 'empty')
                                        }
                                        onClick={() =>
                                          navigateToDetailPage(level2)
                                        }
                                        onMouseOver={changeBackground}
                                        onMouseOut={resetBackground}
                                      >
                                        <strong>
                                          {level2?.naics_code ||
                                            level2?.sic_code}
                                        </strong>{' '}
                                        &nbsp;&#8210;&nbsp;&nbsp;
                                        {level2?.short_desc}
                                      </div>
                                    </div>
                                    {codeType == 'naics' && (
                                      <div className="col-md-8 d-flex flex-column">
                                        <div className="col-md-12 d-flex flex-column">
                                          {Object.values(level2).map(
                                            (level3: any, index3) => {
                                              if (level3?.naics_code) {
                                                return (
                                                  <div className="custom-acc-grid-3">
                                                    <div className="col-md-6 d-flex flex-column">
                                                      <div
                                                        id={level3?.naics_code}
                                                        className={
                                                          'dealer-data bg-light-yellow pointer ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                          level3?.naics_code
                                                            ? bgYellow
                                                            : 'empty') +
                                                          ' ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            4
                                                          ) ==
                                                          level2?.naics_code
                                                            ? yellowStrip
                                                            : 'empty')
                                                        }
                                                        onClick={() =>
                                                          navigateToDetailPage(
                                                            level3
                                                          )
                                                        }
                                                        onMouseOver={
                                                          changeBackground
                                                        }
                                                        onMouseOut={
                                                          resetBackground
                                                        }
                                                      >
                                                        <strong>
                                                          {level3?.naics_code}
                                                          {hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                            level3?.naics_code}
                                                        </strong>{' '}
                                                        &nbsp;&#8210;&nbsp;&nbsp;
                                                        {level3?.short_desc}
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                      {Object.values(
                                                        level3
                                                      ).map(
                                                        (
                                                          level4: any,
                                                          index4
                                                        ) => {
                                                          if (
                                                            level4?.naics_code
                                                          ) {
                                                            return (
                                                              <div
                                                                id={
                                                                  level4?.naics_code
                                                                }
                                                                className={
                                                                  'dealer-data bg-light-blue pointer ' +
                                                                  (hoverTargetId?.substring(
                                                                    0,
                                                                    5
                                                                  ) ==
                                                                  level3?.naics_code
                                                                    ? blueStrip
                                                                    : 'empty')
                                                                }
                                                                onClick={() =>
                                                                  navigateToDetailPage(
                                                                    level4
                                                                  )
                                                                }
                                                                onMouseOver={
                                                                  changeBackground
                                                                }
                                                                onMouseOut={
                                                                  resetBackground
                                                                }
                                                              >
                                                                <strong>
                                                                  {
                                                                    level4?.naics_code
                                                                  }
                                                                  {hoverTargetId?.substring(
                                                                    0,
                                                                    6
                                                                  ) ==
                                                                    level4?.naics_code}
                                                                </strong>{' '}
                                                                &nbsp;&#8210;&nbsp;&nbsp;
                                                                {
                                                                  level4?.short_desc
                                                                }
                                                              </div>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}

                          {codeType == 'isic' &&
                            Object.values(
                              codeType == 'isic' ? level1 : level1
                            ).map((level2: any, index2) => {
                              if (level2?.isic_code || level2?.sic_code) {
                                return (
                                  <div
                                    className={
                                      codeType !== 'isic'
                                        ? 'custom-acc-grid-2-nacis'
                                        : 'custom-acc-grid-2-sic'
                                    }
                                  >
                                    <div className="col-md-4 d-flex flex-column">
                                      <div
                                        id={
                                          level2?.isic_code || level2?.sic_code
                                        }
                                        className={
                                          'dealer-data bg-light-green pointer ' +
                                          (hoverTargetId?.substring(0, 4) ==
                                          (level2?.isic_code ||
                                            level2?.sic_code)
                                            ? bgGreen
                                            : 'empty') +
                                          ' ' +
                                          (hoverTargetId?.substring(0, 3) ==
                                          (level1?.isic_code ||
                                            level1?.sic_code)
                                            ? greenStrip
                                            : 'empty')
                                        }
                                        onClick={() =>
                                          navigateToDetailPage(level2)
                                        }
                                        onMouseOver={changeBackground}
                                        onMouseOut={resetBackground}
                                      >
                                        <strong>
                                          {level2?.isic_code ||
                                            level2?.sic_code}
                                        </strong>{' '}
                                        &nbsp;&#8210;&nbsp;&nbsp;
                                        {level2?.short_desc}
                                      </div>
                                    </div>
                                    {codeType == 'naics' && (
                                      <div className="col-md-8 d-flex flex-column">
                                        <div className="col-md-12 d-flex flex-column">
                                          {Object.values(level2).map(
                                            (level3: any, index3) => {
                                              if (level3?.naics_code) {
                                                return (
                                                  <div className="custom-acc-grid-3">
                                                    <div className="col-md-6 d-flex flex-column">
                                                      <div
                                                        id={level3?.naics_code}
                                                        className={
                                                          'dealer-data bg-light-yellow pointer ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                          level3?.naics_code
                                                            ? bgYellow
                                                            : 'empty') +
                                                          ' ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            4
                                                          ) ==
                                                          level2?.naics_code
                                                            ? yellowStrip
                                                            : 'empty')
                                                        }
                                                        onClick={() =>
                                                          navigateToDetailPage(
                                                            level3
                                                          )
                                                        }
                                                        onMouseOver={
                                                          changeBackground
                                                        }
                                                        onMouseOut={
                                                          resetBackground
                                                        }
                                                      >
                                                        <strong>
                                                          {level3?.naics_code}
                                                          {hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                            level3?.naics_code}
                                                        </strong>{' '}
                                                        &nbsp;&#8210;&nbsp;&nbsp;
                                                        {level3?.short_desc}
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                      {Object.values(
                                                        level3
                                                      ).map(
                                                        (
                                                          level4: any,
                                                          index4
                                                        ) => {
                                                          if (
                                                            level4?.naics_code
                                                          ) {
                                                            return (
                                                              <div
                                                                id={
                                                                  level4?.naics_code
                                                                }
                                                                className={
                                                                  'dealer-data bg-blue pointer bg-light-blue ' +
                                                                  (hoverTargetId?.substring(
                                                                    0,
                                                                    5
                                                                  ) ==
                                                                  level3?.naics_code
                                                                    ? blueStrip
                                                                    : 'empty')
                                                                }
                                                                onClick={() =>
                                                                  navigateToDetailPage(
                                                                    level4
                                                                  )
                                                                }
                                                                onMouseOver={
                                                                  changeBackground
                                                                }
                                                                onMouseOut={
                                                                  resetBackground
                                                                }
                                                              >
                                                                <strong>
                                                                  {
                                                                    level4?.naics_code
                                                                  }
                                                                  {hoverTargetId?.substring(
                                                                    0,
                                                                    6
                                                                  ) ==
                                                                    level4?.naics_code}
                                                                </strong>{' '}
                                                                &nbsp;&#8210;&nbsp;&nbsp;
                                                                {
                                                                  level4?.short_desc
                                                                }
                                                              </div>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}

                          {codeType == 'nace' &&
                            Object.values(
                              codeType == 'nace' ? level1 : level1
                            ).map((level2: any, index2) => {
                              if (level2?.nace_code || level2?.sic_code) {
                                return (
                                  <div
                                    className={
                                      codeType !== 'nace'
                                        ? 'custom-acc-grid-2-nacis'
                                        : 'custom-acc-grid-2-sic'
                                    }
                                  >
                                    <div className="col-md-4 d-flex flex-column">
                                      <div
                                        id={
                                          level2?.nace_code || level2?.sic_code
                                        }
                                        className={
                                          'dealer-data bg-light-green pointer ' +
                                          (hoverTargetId?.substring(0, 4) ==
                                          (level2?.nace_code ||
                                            level2?.sic_code)
                                            ? bgGreen
                                            : 'empty') +
                                          ' ' +
                                          (hoverTargetId?.substring(0, 3) ==
                                          (level1?.nace_code ||
                                            level1?.sic_code)
                                            ? greenStrip
                                            : 'empty')
                                        }
                                        onClick={() =>
                                          navigateToDetailPage(level2)
                                        }
                                        onMouseOver={changeBackground}
                                        onMouseOut={resetBackground}
                                      >
                                        <strong>
                                          {level2?.nace_code ||
                                            level2?.sic_code}
                                        </strong>{' '}
                                        &nbsp;&#8210;&nbsp;&nbsp;
                                        {level2?.short_desc}
                                      </div>
                                    </div>

                                    {codeType == 'naics' && (
                                      <div className="col-md-8 d-flex flex-column">
                                        <div className="col-md-12 d-flex flex-column">
                                          {Object.values(level2).map(
                                            (level3: any, index3) => {
                                              if (level3?.naics_code) {
                                                return (
                                                  <div className="custom-acc-grid-3">
                                                    <div className="col-md-6 d-flex flex-column">
                                                      <div
                                                        id={level3?.naics_code}
                                                        className={
                                                          'dealer-data bg-light-yellow pointer ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                          level3?.naics_code
                                                            ? bgYellow
                                                            : 'empty') +
                                                          ' ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            4
                                                          ) ==
                                                          level2?.naics_code
                                                            ? yellowStrip
                                                            : 'empty')
                                                        }
                                                        onClick={() =>
                                                          navigateToDetailPage(
                                                            level3
                                                          )
                                                        }
                                                        onMouseOver={
                                                          changeBackground
                                                        }
                                                        onMouseOut={
                                                          resetBackground
                                                        }
                                                      >
                                                        <strong>
                                                          {level3?.naics_code}
                                                          {hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                            level3?.naics_code}
                                                        </strong>{' '}
                                                        &nbsp;&#8210;&nbsp;&nbsp;
                                                        {level3?.short_desc}
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                      {Object.values(
                                                        level3
                                                      ).map(
                                                        (
                                                          level4: any,
                                                          index4
                                                        ) => {
                                                          if (
                                                            level4?.naics_code
                                                          ) {
                                                            return (
                                                              <div
                                                                id={
                                                                  level4?.naics_code
                                                                }
                                                                className={
                                                                  'dealer-data bg-blue pointer bg-light-blue ' +
                                                                  (hoverTargetId?.substring(
                                                                    0,
                                                                    5
                                                                  ) ==
                                                                  level3?.naics_code
                                                                    ? blueStrip
                                                                    : 'empty')
                                                                }
                                                                onClick={() =>
                                                                  navigateToDetailPage(
                                                                    level4
                                                                  )
                                                                }
                                                                onMouseOver={
                                                                  changeBackground
                                                                }
                                                                onMouseOut={
                                                                  resetBackground
                                                                }
                                                              >
                                                                <strong>
                                                                  {
                                                                    level4?.naics_code
                                                                  }
                                                                  {hoverTargetId?.substring(
                                                                    0,
                                                                    6
                                                                  ) ==
                                                                    level4?.naics_code}
                                                                </strong>{' '}
                                                                &nbsp;&#8210;&nbsp;&nbsp;
                                                                {
                                                                  level4?.short_desc
                                                                }
                                                              </div>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}

                          {codeType == 'nic' &&
                            Object.values(
                              codeType == 'nic' ? level1 : level1.nic_4_digit
                            ).map((level2: any, index2) => {
                              if (level2?.nic_code || level2?.sic_code) {
                                return (
                                  <div
                                    className={
                                      codeType == 'nic'
                                        ? 'custom-acc-grid-1-nicsub'
                                        : 'custom-acc-grid-2-sic'
                                    }
                                  >
                                    <div className="col-md-4 d-flex flex-column">
                                      <div
                                        id={
                                          level2?.nic_code || level2?.sic_code
                                        }
                                        className={
                                          'dealer-data bg-light-green pointer ' +
                                          (hoverTargetId?.substring(0, 4) ==
                                          (level2?.nic_code || level2?.sic_code)
                                            ? bgGreen
                                            : 'empty') +
                                          ' ' +
                                          (hoverTargetId?.substring(0, 3) ==
                                          (level1?.nic_code || level1?.sic_code)
                                            ? greenStrip
                                            : 'empty')
                                        }
                                        onClick={() =>
                                          navigateToDetailPage(level2)
                                        }
                                        onMouseOver={changeBackground}
                                        onMouseOut={resetBackground}
                                      >
                                        <strong>
                                          {level2?.nic_code || level2?.sic_code}
                                        </strong>{' '}
                                        &nbsp;&#8210;&nbsp;&nbsp;
                                        {level2?.short_desc}
                                      </div>
                                    </div>
                                    {codeType == 'nic' && (
                                      <div className="col-md-4 d-flex flex-column">
                                        <div className="col-md-12 d-flex flex-column">
                                          {Object.values(level2).map(
                                            (level3: any, index3) => {
                                              if (level3?.nic_code) {
                                                return (
                                                  <div className="custom-acc-grid-3-nic">
                                                    <div className="col-md-6 d-flex flex-column">
                                                      <div
                                                        id={level3?.nic_code}
                                                        className={
                                                          'dealer-data bg-light-yellow pointer ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) == level3?.nic_code
                                                            ? bgYellow
                                                            : 'empty') +
                                                          ' ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            4
                                                          ) == level2?.nic_code
                                                            ? yellowStrip
                                                            : 'empty')
                                                        }
                                                        onClick={() =>
                                                          navigateToDetailPage(
                                                            level2
                                                          )
                                                        }
                                                        onMouseOver={
                                                          changeBackground
                                                        }
                                                        onMouseOut={
                                                          resetBackground
                                                        }
                                                      >
                                                        <strong>
                                                          {level3?.nic_code}
                                                          {hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                            level3?.naics_code}
                                                        </strong>{' '}
                                                        &nbsp;&#8210;&nbsp;&nbsp;
                                                        {level3?.short_desc}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}


                          {codeType == 'uksic' &&
                            Object.values(
                              codeType == 'uksic' ? level1 : level1
                            ).map((level2: any, index2) => {
                              if (level2?.uksic_code || level2?.sic_code) {
                                return (
                                  <div
                                    className={
                                      codeType !== 'uksic'
                                        ? 'custom-acc-grid-2-nacis'
                                        : 'custom-acc-grid-2-sic'
                                    }
                                  >
                                    <div className="col-md-4 d-flex flex-column">
                                      <div
                                        id={
                                          level2?.uksic_code || level2?.sic_code
                                        }
                                        className={
                                          'dealer-data bg-light-green pointer ' +
                                          (hoverTargetId?.substring(0, 4) ==
                                          (level2?.uksic_code ||
                                            level2?.sic_code)
                                            ? bgGreen
                                            : 'empty') +
                                          ' ' +
                                          (hoverTargetId?.substring(0, 3) ==
                                          (level1?.uksic_code ||
                                            level1?.sic_code)
                                            ? greenStrip
                                            : 'empty')
                                        }
                                        onClick={() =>
                                          navigateToDetailPage(level2)
                                        }
                                        onMouseOver={changeBackground}
                                        onMouseOut={resetBackground}
                                      >
                                        <strong>
                                          {level2?.uksic_code ||
                                            level2?.sic_code}
                                        </strong>{' '}
                                        &nbsp;&#8210;&nbsp;&nbsp;
                                        {level2?.short_desc}
                                      </div>
                                    </div>

                                    {codeType == 'naics' && (
                                      <div className="col-md-8 d-flex flex-column">
                                        <div className="col-md-12 d-flex flex-column">
                                          {Object.values(level2).map(
                                            (level3: any, index3) => {
                                              if (level3?.naics_code) {
                                                return (
                                                  <div className="custom-acc-grid-3">
                                                    <div className="col-md-6 d-flex flex-column">
                                                      <div
                                                        id={level3?.naics_code}
                                                        className={
                                                          'dealer-data bg-light-yellow pointer ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                          level3?.naics_code
                                                            ? bgYellow
                                                            : 'empty') +
                                                          ' ' +
                                                          (hoverTargetId?.substring(
                                                            0,
                                                            4
                                                          ) ==
                                                          level2?.naics_code
                                                            ? yellowStrip
                                                            : 'empty')
                                                        }
                                                        onClick={() =>
                                                          navigateToDetailPage(
                                                            level3
                                                          )
                                                        }
                                                        onMouseOver={
                                                          changeBackground
                                                        }
                                                        onMouseOut={
                                                          resetBackground
                                                        }
                                                      >
                                                        <strong>
                                                          {level3?.naics_code}
                                                          {hoverTargetId?.substring(
                                                            0,
                                                            5
                                                          ) ==
                                                            level3?.naics_code}
                                                        </strong>{' '}
                                                        &nbsp;&#8210;&nbsp;&nbsp;
                                                        {level3?.short_desc}
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex flex-column">
                                                      {Object.values(
                                                        level3
                                                      ).map(
                                                        (
                                                          level4: any,
                                                          index4
                                                        ) => {
                                                          if (
                                                            level4?.naics_code
                                                          ) {
                                                            return (
                                                              <div
                                                                id={
                                                                  level4?.naics_code
                                                                }
                                                                className={
                                                                  'dealer-data bg-blue pointer bg-light-blue ' +
                                                                  (hoverTargetId?.substring(
                                                                    0,
                                                                    5
                                                                  ) ==
                                                                  level3?.naics_code
                                                                    ? blueStrip
                                                                    : 'empty')
                                                                }
                                                                onClick={() =>
                                                                  navigateToDetailPage(
                                                                    level4
                                                                  )
                                                                }
                                                                onMouseOver={
                                                                  changeBackground
                                                                }
                                                                onMouseOut={
                                                                  resetBackground
                                                                }
                                                              >
                                                                <strong>
                                                                  {
                                                                    level4?.naics_code
                                                                  }
                                                                  {hoverTargetId?.substring(
                                                                    0,
                                                                    6
                                                                  ) ==
                                                                    level4?.naics_code}
                                                                </strong>{' '}
                                                                &nbsp;&#8210;&nbsp;&nbsp;
                                                                {
                                                                  level4?.short_desc
                                                                }
                                                              </div>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}


                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}
