import * as React from 'react';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import './PopOver.scss';
import { useNavigate } from 'react-router-dom';

export default function PopOver(props: any) {

  let { popup, showPopup, targetElement, setActiveIndex } = props;
  const [anchorEl, setAnchorEl] = React.useState(targetElement);
  // const [selectedType, setSelectedType] = React.useState(props?.data?.data?.naicsDropdownKey? 'naicsDropdownKey' : 'sicDropdownKey');
  const [selectedType, setSelectedType] = React.useState(
    props?.data?.data?.naicsDropdownKey
      ? 'naicsDropdownKey'
      : props?.data?.data?.sicDropdownKey
      ? 'sicDropdownKey'
      : props?.data?.data?.isicDropdownKey
      ? 'isicDropdownKey'
      : props?.data?.data?.nicDropdownKey
      ? 'nicDropdownKey'
      : props?.data?.data?.uksicDropdownKey
      ? 'uksicDropdownKey'
      : 'naceDropdownKey'
  );

  const handleClose = () => {
    setAnchorEl(null);
    showPopup(false);
    setActiveIndex('');
  };

  const navigate = useNavigate();

  const open = popup;
  const id = open ? 'simple-popover' : undefined;



  const navigateToStructure = (
    menuValueSelected: any
  ) => {
    // alert(JSON.stringify(menuValueSelected))
    var type = props?.data?.type
    var code = menuValueSelected?.sic_code || menuValueSelected?.naics_code || menuValueSelected?.isic_code || menuValueSelected?.nace_code || menuValueSelected?.nic_code || menuValueSelected?.uksic_code ;
    navigate('/structure/'+ type + '/' + code);
  };


  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className="list-popover"
      >
        <ul className="tablist-popup-item ">
          {props?.data?.data?.[selectedType]?.map((user: any) => {
            return (

              <li className="d-flex" onClick={(event: any) => navigateToStructure(user)}>
                <strong className="pe-3">{user.sic_code || user.naics_code || user.isic_code || user.nace_code || user.nic_code || user.uksic_code}</strong> {user.short_desc}
              </li>

            )
          })}
        </ul>

      </Popover>

    </div>
  );
}
