import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Container, Box } from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from 'components/Footer/Footer';
import { useNavigate } from 'react-router-dom';

export default function Privacy(props: any) {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Privacy Policy - IndustryCodes </title>    
      <meta name="Explore our privacy policy to understand how IndustryCodes collects, uses, and protects your personal information when you visit our website or use our services."/>
      <meta name="keywords" content="Privacy policy, Data protection, Personal information, Website privacy, User privacy"/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">Privacy</h2>

              <a href="" onClick={() => navigateToHome()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>

        <section className="know-about-blok py-1">
          <div
            className="container"
            style={{ marginTop: '56px', marginBottom: '56px' }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="pb-md-3">
                  At IndustryCodes, accessible from{' '}
                  <a href="www.industrycodes.com">www.industrycodes.com</a> ,
                  one of our main priorities is the privacy of our visitors.
                  This Privacy Policy document contains types of information
                  that is collected and recorded by IndustryCodes and how we use
                  it.
                </div>
                <p>
                   If you have additional questions or require more information
                  about our Privacy Policy, do not hesitate to contact us.
                </p>
                <div>
                  This Privacy Policy applies only to our online activities and
                  is valid for visitors to our website with regards to the
                  information that they shared and/or collect in IndustryCodes.
                  This policy is not applicable to any information collected
                  offline or via channels other than this website.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Consent
                </h2>
                <div>
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its terms.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Information we collect
                </h2>
                <p>
                  The personal information that you are asked to provide, and
                  the reasons why you are asked to provide it, will be made
                  clear to you at the point we ask you to provide your personal
                  information. 
                </p>
                <p>
                  If you contact us directly, we may receive additional
                  information about you such as your name, email address, phone
                  number, the contents of the message and/or attachments you may
                  send us, and any other information you may choose to provide.
                </p>
                <div>
                  When you register for an Account, we may ask for your contact
                  information, including items such as name, company name,
                  address, email address, and telephone number.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  How we use your information
                </h2>
                <p>
                  We use the information we collect in various ways, including
                  to: 
                </p>
                <p className="ps-3">
                  <li>Provide, operate, and maintain our website</li>
                </p>
                <p className="ps-3">
                  <li>Improve, personalize, and expand our website</li>
                </p>
                <p className="ps-3">
                  <li>Understand and analyze how you use our website</li>
                </p>
                <p className="ps-3">
                  <li>
                    Develop new products, services, features, and functionality
                  </li>
                </p>
                <p className="ps-3">
                  <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </li>
                </p>
                <p className="ps-3">
                  <li>Send you emails</li>
                </p>
                <p className="ps-3">
                  <li>Find and prevent fraud</li>
                </p>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Log Files
                </h2>
                <div>
                  IndustryCodes follows a standard procedure of using log files.
                  These files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting services' analytics.
                  The information collected by log files include internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. These are not linked to any
                  information that is personally identifiable. The purpose of
                  the information is for analyzing trends, administering the
                  site, tracking users' movement on the website, and gathering
                  demographic information.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Cookies and Web Beacons 
                </h2>
                <div>
                  Like any other website, IndustryCodes uses "cookies". These
                  cookies are used to store information including visitors'
                  preferences, and the pages on the website that the visitor
                  accessed or visited. The information is used to optimize the
                  users' experience by customizing our web page content based on
                  visitors' browser type and/or other information.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Advertising Partners Privacy Policies
                </h2>
                <p>
                  You may consult this list to find the Privacy Policy for each
                  of the advertising partners of IndustryCodes.
                </p>
                <p>
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on
                  IndustryCodes, which are sent directly to users' browser. They
                  automatically receive your IP address when this occurs. These
                  technologies are used to measure the effectiveness of their
                  advertising campaigns and/or to personalize the advertising
                  content that you see on websites that you visit.
                </p>
                <div>
                  Note that IndustryCodes has no access to or control over these
                  cookies that are used by third-party advertisers.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Third Party Privacy Policies
                </h2>
                <p>
                  IndustryCodes's Privacy Policy does not apply to other
                  advertisers or websites. Thus, we are advising you to consult
                  the respective Privacy Policies of these third-party ad
                  servers for more detailed information. It may include their
                  practices and instructions about how to opt-out of certain
                  options.
                </p>
                <div>
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  CCPA Privacy Rights (Do Not Sell My Personal Information)
                </h2>
                <p>
                  Under the CCPA, among other rights, California consumers have
                  the right to:
                </p>
                <p>
                  Request that a business that collects a consumer's personal
                  data disclose the categories and specific pieces of personal
                  data that a business has collected about consumers.
                </p>
                <p>
                  Request that a business delete any personal data about the
                  consumer that a business has collected.
                </p>
                <p>
                  Request that a business that sells a consumer's personal data,
                  not sell the consumer's personal data.
                </p>
                <div>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  GDPR Data Protection Rights
                </h2>
                <p>
                  We would like to make sure you are fully aware of all of your
                  data protection rights. Every user is entitled to the
                  following:
                </p>
                <p>
                  The right to access – You have the right to request copies of
                  your personal data. We may charge you a small fee for this
                  service.
                </p>
                <p>
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate. You
                  also have the right to request that we complete the
                  information you believe is incomplete.
                </p>
                <p>
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </p>
                <p>
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </p>
                <p>
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </p>
                <p>
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </p>
                <div>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Children's Information
                </h2>
                <p>
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </p>
                <div>
                  IndustryCodes does not knowingly collect any Personal
                  Identifiable Information from children under the age of 13. If
                  you think that your child provided this kind of information on
                  our website, we strongly encourage you to contact us
                  immediately and we will do our best efforts to promptly remove
                  such information from our records.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Changes to This Privacy Policy
                </h2>
                <div>
                  We may update our Privacy Policy from time to time. Thus, we
                  advise you to review this page periodically for any changes.
                  We will notify you of any changes by posting the new Privacy
                  Policy on this page. These changes are effective immediately,
                  after they are posted on this page.
                </div>
              </div>
              <div className="col-md-12">
                <h2
                  className="block-title pb-md-3"
                  style={{ paddingTop: '56px' }}
                >
                  Contact Us
                </h2>
                <div>
                  If you have any questions or suggestions about our Privacy
                  Policy, do not hesitate to contact us.
                </div>
              </div>
            </div>
          </div>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
