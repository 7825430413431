// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-popover .MuiPopover-paper {
  border: 1px solid var(--red);
  width: 100%;
  max-width: 426px;
  margin: 0 0 0 4px; }

.tablist-popup-item li {
  padding: 1rem; }

.tablist-popup-item li:hover {
  background-color: var(--red);
  cursor: pointer;
  color: var(--white);
  width: 424px; }
`, "",{"version":3,"sources":["webpack://./src/components/PopOver/PopOver.scss"],"names":[],"mappings":"AAAA;EAEQ,4BAA4B;EAC1B,WAAW;EACZ,gBAAgB;EACjB,iBAAiB,EAAA;;AAIzB;EAEQ,aAAa,EAAA;;AAIrB;EAEQ,4BAA4B;EAC5B,eAAe;EACf,mBAAmB;EACnB,YAAY,EAAA","sourcesContent":[".list-popover {\n    .MuiPopover-paper {\n        border: 1px solid var(--red);\n          width: 100%;\n         max-width: 426px;\n        margin: 0 0 0 4px;\n       \n    }\n}\n.tablist-popup-item {\n    li {\n        padding: 1rem;\n    }\n  \n}\n.tablist-popup-item {\n    li:hover {\n        background-color: var(--red);\n        cursor: pointer;\n        color: var(--white);\n        width: 424px; \n    }\n   \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
