// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #252525;
  box-shadow: none; }

.logo {
  color: #fff; }

.logo {
  font: normal normal bold 2rem/40px Poppins;
  letter-spacing: -0.14px; }

.menu-nav {
  height: 0;
  width: 100%;
  background-color: var(--black-light);
  position: fixed;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  transition: 0.4s; }

.overlay-open {
  height: 100vh;
  z-index: 1;
  opacity: 1; }

.menu-list a,
.menu-list p {
  color: var(--white); }

.menu-list a {
  font-weight: 700;
  margin-top: 5rem;
  font-size: 2.2rem;
  margin-bottom: 1.8rem;
  display: block; }

.menu-list p {
  opacity: 0.5;
  line-height: 1.6; }

.btn-menu-close {
  cursor: pointer; }

.btn-menu-close svg {
  width: 27px; }

.menu-nav .form-control,
.menu-nav .form-select {
  border-color: var(--grey-border);
  background-color: transparent;
  color: var(--white); }

.project-header-text {
  cursor: pointer; }

.pointer {
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/NavBar.scss"],"names":[],"mappings":"AAEA;EACE,yBAHmB;EAInB,gBAAgB,EAAA;;AAElB;EACE,WAAW,EAAA;;AAEb;EACE,0CAA0C;EAC1C,uBAAuB,EAAA;;AAGzB;EACE,SAAS;EACT,WAAW;EACX,oCAAoC;EACpC,eAAe;EACf,WAAW;EACX,UAAU;EACV,MAAM;EACN,OAAO;EACP,kBAAkB;EAClB,gBAAgB,EAAA;;AAElB;EACE,aAAa;EACb,UAAU;EACV,UAAU,EAAA;;AAEZ;;EAEE,mBAAmB,EAAA;;AAErB;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,cAAc,EAAA;;AAEhB;EACE,YAAY;EACZ,gBAAgB,EAAA;;AAElB;EACE,eAAe,EAAA;;AAEjB;EACE,WAAW,EAAA;;AAEb;;EAEE,gCAAgC;EAChC,6BAA6B;EAC7B,mBAAmB,EAAA;;AAGrB;EACE,eAAe,EAAA;;AAGjB;EACE,eAAe,EAAA","sourcesContent":["$black-light: #252525;\n\n.header {\n  background-color: $black-light;\n  box-shadow: none;\n}\n.logo {\n  color: #fff;\n}\n.logo {\n  font: normal normal bold 2rem/40px Poppins;\n  letter-spacing: -0.14px;\n}\n\n.menu-nav {\n  height: 0;\n  width: 100%;\n  background-color: var(--black-light);\n  position: fixed;\n  z-index: -1;\n  opacity: 0;\n  top: 0;\n  left: 0;\n  overflow-y: hidden;\n  transition: 0.4s;\n}\n.overlay-open {\n  height: 100vh;\n  z-index: 1;\n  opacity: 1;\n}\n.menu-list a,\n.menu-list p {\n  color: var(--white);\n}\n.menu-list a {\n  font-weight: 700;\n  margin-top: 5rem;\n  font-size: 2.2rem;\n  margin-bottom: 1.8rem;\n  display: block;\n}\n.menu-list p {\n  opacity: 0.5;\n  line-height: 1.6;\n}\n.btn-menu-close {\n  cursor: pointer;\n}\n.btn-menu-close svg {\n  width: 27px;\n}\n.menu-nav .form-control,\n.menu-nav .form-select {\n  border-color: var(--grey-border);\n  background-color: transparent;\n  color: var(--white);\n}\n\n.project-header-text{\n  cursor: pointer;\n}\n\n.pointer{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
