import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import {
  Container,
  Box,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OutlinedInput from '@mui/material/OutlinedInput';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import arrowsImg from 'assets/arrows.png';
import { useNavigate, useParams } from 'react-router-dom';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';

export default function CodeConversion(props: any) {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };

  const navigateToStructure = () => {
    navigate('/');
  };

  const routeParams = useParams();
  let [codeConversionResults, setCodeConversionResults]: any = useState<any[]>(
    []
  );

  React.useEffect(() => {
    if (routeParams?.codeConversionFromDropDownValue) {
      convertCode();
    }
  }, []);

  React.useEffect(() => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'allCodes',
      success: (jsonResponse) => {
        setCodeData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  }, []);

  const [searchResultCode, setSearchResultCode]: any = useState('');

  const convertCode = () => {
    setSearchResultCode(codeConversionValue);
    setCodeConversionResults('');

    setxvalue(codeConversionFromDropDownValue);
  setyvalue(codeConversionToDropDownValue); 

    callAPI({
      method: 'get',
      params: {
        [codeConversionFromDropDownValue +
        '_to_' +
        codeConversionToDropDownValue]: codeConversionValue
      },
      resource: BASE_URL + 'codeConversion',
      success: (jsonResponse) => {
        setCodeConversionResults(jsonResponse);
        console.log(jsonResponse);
      },
      error: (error) => console.log(error)
    });
    
    console.log(xvalue)
  };

  const swapCodeConversion = () => {
    var value = codeConversionFromDropDownValue;
    setCodeConversionFromDropDownValue(codeConversionToDropDownValue);
    setCodeConversionToDropDownValue(value);
  };

  const navigateToDetailPage = (codeType: any, menuValueSelected: any) => {
    console.log(codeType)
    console.log(menuValueSelected)
   
    window.open('/detail/' + codeType + '/' + menuValueSelected, '_blank');
  };

  const [codeData, setCodeData]: any = useState({});

  const [
    codeConversionFromDropDownValue,
    setCodeConversionFromDropDownValue
  ]: any = React.useState(
    routeParams?.codeConversionFromDropDownValue || 'sic'
  );
  const [codeConversionToDropDownValue, setCodeConversionToDropDownValue]: any =
    React.useState(routeParams?.codeConversionToDropDownValue || 'naics');

    const [xvalue , setxvalue] : any = React.useState()
    const [yvalue , setyvalue] : any = React.useState()

  const changeCodeConversionFromDropDown = (event: any) => {
    setCodeConversionFromDropDownValue(event.target.value);
  };
  const changeCodeConversionToDropDown = (event: any) => {
    setCodeConversionToDropDownValue(event.target.value);
  };
  const [codeConversionValue, setCodeConversionValue]: any = useState(
    routeParams?.codeConversionValue
  );
  const codeConversionValueChange = (event: any) => {
    setCodeConversionValue(event.target.value);
  };
  // const navCodeConversion = () => {

  // };

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Industry Code Conversion Tool | Convert Between Different Classification Systems</title>    
      <meta name="Easily convert industry classification codes between various systems such as NAICS, SIC, ISIC, and NACE. Simplify data analysis, market research, and regulatory compliance with our industry code conversion tool."/>
      <meta name="keywords" content=" Industry code conversion, NAICS to SIC conversion, SIC to NAICS conversion, NAICS to NACE, SIC to NAICS, SIC to ISIC, SIC to NACE, ISIC to NAICS, ISIC to SIC, ISIC to NACE, NACE to NAICS, NACE to SIC, NACE to ISIC, Industry classification systems, Data analysis, Market research, Regulatory compliance, Code conversion tool. "/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
      <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">Code Conversion</h2>

              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section className="code-conversion">
          <div className="container px-4 py-4">
            {/* <h2 className="pb-2 text-center text-light">Code Conversion</h2> */}
            <p className="text-center">
              Code mapping and cross references across multiple industrial
              classification systems. Enter a code and select the desired
              classification systems.
            </p>
            <div className="row g-3 my-4">
              <div className="col-md-5">
                {/* <OutlinedInput
                  className="box-outline"
                  fullWidth
                  placeholder="Search"
                /> */}

                <TextField
                  placeholder={
                    codeConversionFromDropDownValue == 'sic'
                      ? 'Enter 4 digit code'
                      : 'Enter 6 digit code'
                  }
                  fullWidth
                  style={{ fontSize: '2rem' }}
                  type="text"
                  id="codeConversion"
                  name="codeConversionValue"
                  onChange={codeConversionValueChange}
                  value={codeConversionValue}
                  inputProps={{
                    'aria-label': 'Without label',
                    'background-color': '#ffffff'
                  }}
                  className="box-white"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(240, 98, 64)'
                      }
                    }
                  }}
                />
              </div>
              <div className="col-md-7">
                <ul className="d-flex g-3 list-search">
                  <li className="">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={codeConversionFromDropDownValue}
                          displayEmpty
                          onChange={changeCodeConversionFromDropDown}
                          inputProps={{
                            'aria-label': 'Without label',
                            'background-color': '#ffffff'
                          }}
                          className="box-white"
                          sx={{
                            '&.Mui-focused': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(240, 98, 64)'
                              }
                            }
                          }}
                        >
                          {codeData?.data?.data?.map((item: any, i: number) => {
                            if (item?.codel != codeConversionToDropDownValue) {
                              return (
                                <MenuItem value={item?.codel}>
                                  {item?.codeu}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </li>
                  <li className="list-search-btn">
                    <button
                      className="btn-filter"
                      onClick={() => swapCodeConversion()}
                    >
                      <img src={arrowsImg} />
                    </button>
                  </li>
                  <li className="">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={codeConversionToDropDownValue}
                          displayEmpty
                          onChange={changeCodeConversionToDropDown}
                          inputProps={{
                            'aria-label': 'Without label',
                            'background-color': '#ffffff'
                          }}
                          className="box-white"
                          sx={{
                            '&.Mui-focused': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(240, 98, 64)'
                              }
                            }
                          }}
                        >
                          {codeData?.data?.data?.map((item: any, i: number) => {
                            if (
                              item?.codel != codeConversionFromDropDownValue
                            ) {
                              return (
                                <MenuItem value={item?.codel}>
                                  {item?.codeu}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </li>
                </ul>
              </div>

              <div className="col-md-12 text-center mt-5">
                <Button
                  onClick={() => convertCode()}
                  style={{
                    backgroundColor:
                      codeConversionValue?.length <= 0
                        ? 'rgb(255 248 248 / 12%)'
                        : '#f06240',
                    borderRadius: '3px',
                    minWidth: '160px',
                    minHeight: '48px',
                    textTransform: 'none',
                    fontSize: '1.2rem'
                  }}
                  disabled={codeConversionValue?.length <= 0}
                  variant="contained"
                >
                  Convert
                </Button>{' '}
              </div>
            </div>
          </div>
        </section>

        <main className="pb-5">
          {/* <section className="code bg-grey code-conversion-block py-5">
            <div className="container px-4 py-5">
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas pulvinar scelerisque
                <br />
                metus, a porttitor nisl dapibus ac. Suspendisse vitae mi massa.
                Aliquam ac neque nisl.
              </p>
              <div className="row g-3 my-4 pt-5">
                <div className="col-md-5">
                  <OutlinedInput
                    className="box-outline"
                    fullWidth
                    placeholder="Enter code or keyword"
                  />
                </div>
                <div className="col-md-7">
                  <ul className="d-flex g-3 list-search">
                    <li className="">
                      <SelectDropDown />
                    </li>
                    <li className="list-search-btn">
                      <button className="btn-filter">
                        <img src={arrowsImg} />
                      </button>
                    </li>
                    <li className="">
                      <SelectDropDown />
                    </li>
                  </ul>
                </div>

                <div className="col-md-12 text-center mt-5">
                  <Button
                    style={{
                      backgroundColor: '#f06240',
                      borderRadius: '3px',
                      minWidth: '160px',
                      minHeight: '48px',
                      textTransform: 'none',
                      fontSize: '1.2rem'
                    }}
                    variant="contained"
                  >
                    Convert
                  </Button>{' '}
                </div>
              </div>
            </div>
          </section> */}
          <section className="container code-mapping-block py-1">
            <h2 className="block-title text-center pt-md-5 pb-md-3 mb-4">
              {
                <div>
                  <div>
                    {typeof codeConversionResults?.data?.data == 'string' && (
                      <p>
                        There is no match available for{' '}
                        {codeConversionFromDropDownValue.toUpperCase()}{' '}
                        {searchResultCode} in{' '}
                        {codeConversionToDropDownValue.toUpperCase()} code
                        system
                      </p>
                    )}
                  </div>
                  {typeof codeConversionResults?.data?.data == 'object' && (
                    <div>
                      {xvalue.toUpperCase()} to{' '}
                      {yvalue.toUpperCase()} Code Mapping
                      for <span className="txt-red">"{searchResultCode}"</span>
                    </div>
                  )}
                </div>
              }
            </h2>

            {typeof codeConversionResults?.data?.data == 'object' && (
              <ul className="row classification-bar">
                <li className="col-lg-6 mb-4">
                  <h6 className="txt-black-light-color mb-3">
                    {xvalue.toUpperCase()} CODE
                  </h6>
                  {typeof codeConversionResults?.data?.data == 'string' && (
                    <p>{codeConversionResults?.data?.data}</p>
                  )}
                  {typeof codeConversionResults?.data?.data == 'object' && (
                    <span
                      className={
                        codeConversionResults?.data?.data[0][
                          xvalue + '_code'
                        ]?.length == 2
                          ? 'color-bar pointer p-4 bg-light-blue'
                          : codeConversionResults?.data?.data[0]?.[
                              xvalue + '_code'
                            ]?.length == 3
                          ? 'color-bar pointer p-4 bg-light-pink'
                          : codeConversionResults?.data?.data[0]?.[
                              xvalue + '_code'
                            ]?.length == 4
                          ? 'color-bar pointer p-4 bg-light-green'
                          : codeConversionResults?.data?.data[0]?.[
                              xvalue + '_code'
                            ]?.length == 5
                          ? 'color-bar pointer p-4 bg-light-yellow'
                          : 'color-bar pointer p-4 bg-light-blue'
                      }
                      onClick={() => {
                      
                        if (xvalue !== undefined) {
                          if (xvalue == 'sic') {
                            navigateToDetailPage(xvalue, codeConversionResults?.data?.data[0]?.sic_code);
                          } else if (xvalue == 'naics') {
                            navigateToDetailPage(xvalue, codeConversionResults?.data?.data[0]?.naics_code);
                          } else if (xvalue == 'isic') {
                            navigateToDetailPage(xvalue, codeConversionResults?.data?.data[0]?.isic_code);
                          } else if (xvalue == 'nace') {
                            navigateToDetailPage(xvalue, codeConversionResults?.data?.data[0]?.nace_code);
                          } else {
                            // Handle the case when none of the codes are defined
                            console.error("None of the codes are defined, unable to navigate to detail page.");
                          }
                        }
                      }}
                      
                    >
                      <span className="colour-Black">
                        {
                          codeConversionResults?.data?.data[0]?.[
                            xvalue + '_code'
                          ]
                        }{' '}
                        -{' '}
                        {
                          codeConversionResults?.data?.data[0]?.[
                            xvalue + '_desc'
                          ]
                        }
                      </span>
                    </span>
                  )}
                </li>
                <li className="col-lg-6 mb-4">
                  <h6 className="txt-black-light-color mb-3">
                    {yvalue.toUpperCase()} CODE
                  </h6>
                  {typeof codeConversionResults?.data?.data == 'string' && (
                    <p>{codeConversionResults?.data?.data}</p>
                  )}
                  {typeof codeConversionResults?.data?.data == 'object' &&
                    codeConversionResults?.data?.data?.map(
                      (example: any, j: number) => {
                        return (
                          <span
                            className={
                              codeConversionResults?.data?.data[0][
                                yvalue + '_code'
                              ]?.length == 2
                                ? 'color-bar pointer p-4 bg-light-blue'
                                : codeConversionResults?.data?.data[0]?.[
                                    yvalue + '_code'
                                  ]?.length == 3
                                ? 'color-bar pointer p-4 bg-light-pink'
                                : codeConversionResults?.data?.data[0]?.[
                                    yvalue + '_code'
                                  ]?.length == 4
                                ? 'color-bar pointer p-4 bg-light-green'
                                : codeConversionResults?.data?.data[0]?.[
                                    yvalue + '_code'
                                  ]?.length == 5
                                ? 'color-bar pointer p-4 bg-light-yellow'
                                : 'color-bar pointer p-4 bg-light-blue'
                            }
                            onClick={() => {
                            
                              if (yvalue !== undefined) {
                                  if (yvalue == 'sic') {
                                  navigateToDetailPage(yvalue, example?.sic_code);
                                } else if (yvalue == 'naics') {
                                  navigateToDetailPage(yvalue, example?.naics_code);
                                } else if (yvalue == 'isic') {
                                  navigateToDetailPage(yvalue, example?.isic_code);
                                } else if (yvalue == 'nace') {
                                  navigateToDetailPage(yvalue, example?.nace_code);
                                } else {
                                  // Handle the case when none of the codes are defined
                                  console.error("None of the codes are defined, unable to navigate to detail page.");
                                }
                              }
                            }}
                           
                          >
                            <span className="colour-Black">
                              {
                                example?.[
                                  yvalue + '_code'
                                ]
                              }{' '}
                              -{' '}
                              {
                                example?.[
                                  yvalue + '_desc'
                                ]
                              }
                            </span>
                          </span>
                        );
                      }
                    )}
                </li>
              </ul>
            )}
          </section>
          <section className="container py-5"></section>
        </main>
      </Box>
      <Footer
        // style={{
        //   height: '150px',
        //   display: 'block',
        //   position: 'fixed',
        //   bottom: '0',
        //   width: '100%'
        // }}
      />
    </div>
  );
}
