import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Container, Box, Button } from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import TextFields from 'components/TextFields/TextFields';
import { useNavigate } from 'react-router-dom';

export default function Contact(props: any) {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Contact Us - IndustryCodes</title>    
      <meta name="Contact IndustryCodes for inquiries, feedback, or support. Our team is here to assist you with any questions you may have about industry classification systems."/>
      <meta name="keywords" content="Contact us, Inquiries, Feedback, Support, Industry codes, Industry code systems"/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">Contact</h2>

              <a href="" onClick={() => navigateToHome()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>

        <main>
          <section className="bg-grey contact-block py-5">
            <div className="container px-4 py-5">
              <div className="row">
                <div className="col-md-5 mb-5 mb-md-0">
                  <TextFields />
                </div>
                <div className="col-md-6 ms-md-auto">
                  <h2 className="block-title">We’re here to help</h2>
                  <p>Get in touch with sales team</p>
                  <p>
                    We value your input and engagement as we strive to create a
                    comprehensive resource for industry classification systems
                    across the globe. Whether you have inquiries, suggestions,
                    or collaboration proposals, we encourage you to reach out.
                  </p>
                  <p>
                    Our team is dedicated to fostering an inclusive community
                    where experts, researchers, and enthusiasts come together to
                    explore the intricacies of industry codes.
                  </p>
                  <p>
                    Your feedback is vital in helping us improve and expand our
                    platform, ensuring that it remains a valuable hub for
                    understanding and navigating the world of industry
                    classification. Feel free to connect with us, and let's
                    embark on this informative journey together.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Box>
      <Footer />
    </div>
  );
}
