export const COLUMNS = [
  {
    Header : 'Country',
    accessor : 'country',
  },
  {
    Header : 'Primary Code',
    accessor : 'primary_industry_code',
  },
  {
    Header : 'Description',
    accessor : 'pic_eng',
  },
  
]