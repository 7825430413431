import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.scss';
import {
  Box,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import NavBar from 'components/Navbar/NavBar';
import bannerImg from 'assets/banner.png';
import avatarImgOne from 'assets/avatar-1.png';
import avatarImgTwo from 'assets/avatar-2.png';
import avatarImgThree from 'assets/avatar-3.png';
import countryImg from 'assets/countries.svg';
import arrowsImg from 'assets/arrows.png';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import Footer from 'components/Footer/Footer';
import TabLinks from 'components/TabLinks/TabLinks';
import { useNavigate } from 'react-router-dom';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Carousel from 'react-bootstrap/Carousel';
import WorldMap from 'react-svg-worldmap';
import axios from 'axios';

function App(this: any) {
  const countryname = (num: any) => {
    function getGeoInfo(num: any) {
      const alwinz = axios
        .get(BASE_URL + 'countryList')
        .then((data) => {
          return data.data.rows.find((obj: any) => {
            return obj.alpha3_code == num;
          });
        })
        .catch((error) => {
          console.log(error);
        });
      alwinz.then((x) => {
        setconstCountryState([{ country: x.alpha2_code, value: 0 }]);
        console.log(x.alpha2_code);
      });
    }

    getGeoInfo(num);
    setconstCountryState([
      { country: localStorage.getItem('data') || ' ', value: 0 }
    ]);
    callAPI({
      method: 'post',
      resource: BASE_URL + 'industrialClassifications',
      data: {
        alpha3_code: num
      },
      success: (jsonResponse) => {
        oldCodeData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  const [dropDownValue, setdropDownValue] = React.useState('sic');

  const state = { language: '' };
  const searchRef = React.useRef(null);
  const [countryid, setcountryid]: any = useState([]);
  const [codeData, setCodeData]: any = useState([]);
  const [newData, oldCodeData]: any = useState<any[]>([]);
  const [countrylocation, currentcountrylocation]: any = useState([])
  const changeDropDown = (event: any) => {
    setdropDownValue(event.target.value);
  };

  const [message, setMessage] = useState('');
  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const keyPress = (e: any) => {
    if (e.charCode == 13) {
      navigateToStructure();
    }
  };

  const [codeConversionFromDropDownValue, setCodeConversionFromDropDownValue] =
    React.useState('sic');
  const [codeConversionToDropDownValue, setCodeConversionToDropDownValue] =
    React.useState('naics');
  const changeCodeConversionFromDropDown = (event: any) => {
    setCodeConversionFromDropDownValue(event.target.value);
  };
  const changeCodeConversionToDropDown = (event: any) => {
    setCodeConversionToDropDownValue(event.target.value);
  };
  const [codeConversionValue, setCodeConversionValue] = useState('');
  const codeConversionValueChange = (event: any) => {
    setCodeConversionValue(event.target.value);
  };
  const navCodeConversion = () => {
    navigate(
      '/codeConversion/' +
        codeConversionFromDropDownValue +
        '/' +
        codeConversionToDropDownValue +
        '/' +
        codeConversionValue
    );
  };

  let [codeConversionResults, setCodeConversionResults]: any = useState<any[]>(
    []
  );
  const swapCodeConversion = () => {
    var value = codeConversionFromDropDownValue;
    setCodeConversionFromDropDownValue(codeConversionToDropDownValue);
    setCodeConversionToDropDownValue(value);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);
  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const handleLanguage = (langValue: any) => {
    moveToRef(searchRef);
  };
  const [megaMenuStatus, setMegaMenuStatus] = useState(false);
  const menuOpened = (status: any) => {
    setMegaMenuStatus(status);
    const rootElement = document.getElementById('root') as HTMLElement;
    if (status) {
      rootElement.classList.add('hide-overflow');
    } else {
      rootElement.classList.remove('hide-overflow');
    }
  };
  const navigate = useNavigate();

  const navigateToStructure = () => {
    navigate('/search/' + dropDownValue + '/' + message);
    navigate(0);
  };

  const [constCountryState, setconstCountryState] = useState<any[]>([]);

  React.useEffect(() => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'allCodes',
      success: (jsonResponse) => {
        setCodeData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  }, []);
  const [selected, onSelect] = useState(null);

  const stylingFunction = (context: any) => {
    const opacityLevel = 1;
    return {
      fillOpacity: opacityLevel,
      stroke: '#FFF',
      strokeWidth: 1,
      strokeOpacity: 0.2,
      cursor: 'pointer'
    };
  };

  function callmap(x: any) {
    return new Promise((resolve, reject) => {
      callAPI({
        method: 'get',
        resource: BASE_URL + 'countryList',
        success: (data) => {
          const initialvalue = data.data.rows;
          const foundObject = initialvalue.find(
            (obj: any) => obj.alpha2_code === x
          );
          resolve(foundObject);
        },
        error: (error) => {
          console.log(error);
          reject(error);
        }
      });
    });
  }

  useEffect(() => {
    let x : any;
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        selectCountry(response.data.country_code_iso3)
        setconstCountryState([
          { country: response.data.country_code, value: 0 }
        ]);
      })
      .catch((error) => {
        console.log(error);
      });

    const selectCountry = (x:any) => {
      callAPI({
        method: 'post',
        resource: BASE_URL + 'industrialClassifications',
        data: {
          alpha3_code: x
        },
        success: (jsonResponse) => {
          oldCodeData(jsonResponse);
          const countryCode = jsonResponse.data.data[0].data[0].alpha3_code;
          aleena(countryCode)
        },
        error: (error) => console.log(error)
      });
    };

    const aleena = (x: any) => {
      callAPI({
        method: 'get',
        resource: BASE_URL + 'countryList',
        success: (data) => {
          const initialvalue = data.data.rows;
          const foundObject = initialvalue.find(
            (obj: any) => obj.alpha3_code === x
          );
          console.log(foundObject.country);
          setcountryid(foundObject.country)

        },
        error: (error) => console.log(error)
      });
    };



  }, []);

  const aleena = (x: any) => {
    callAPI({
      method: 'get',
      resource: BASE_URL + 'countryList',
      success: (data) => {
        const initialvalue = data.data.rows;
        const foundObject = initialvalue.find(
          (obj: any) => obj.alpha3_code === x
        );
        console.log(foundObject.country);
        setcountryid(foundObject.country)

      },
      error: (error) => console.log(error)
    });
  };


  const selectCountry = async (countryData: any) => {
    setconstCountryState([{ country: countryData.countryCode, value: 0 }]);
    const countrydata: any = await callmap(countryData.countryCode);
    callAPI({
      method: 'post',
      resource: BASE_URL + 'industrialClassifications',
      data: {
        alpha3_code: countrydata?.alpha3_code
      },
      success: (jsonResponse) => {
        const countryCode = jsonResponse.data.data[0].data[0].alpha3_code;
        aleena(countryCode)
        oldCodeData(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };


  const navigateToWorldBankGroup = () => {
    window.open('https://datatopics.worldbank.org/world-development-indicators/', '_blank');
  };
  const navigateToInternationalLabourOrganization = () => {
    window.open('https://ilostat.ilo.org/', '_blank');
  };
  const navigateToUNIDO  = () => {
    window.open('https://stat.unido.org', '_blank');
  };

  return (
    <div className="App">
      <Helmet>
      <title>IndustryCodes - Global Industry Classification Systems Hub</title>    
      <meta name="Explore comprehensive industry classification systems from around the world on IndustryCodes. Discover how sectors are categorized globally, aiding businesses, researchers, and policymakers. Perform inter system code conversion"/>
      <meta name="keywords" content="Industry codes, Industry classification systems, Industry classification codes, SIC, NAICS, ISIC, NACE, Business classification, Global industry classification, Sector classification, Economic sectors, Industry standards"/>
      </Helmet>
      <NavBar onSelectLanguage={handleLanguage} onOpenMenu={menuOpened} />
      <Box sx={{ bgcolor: 'white' }}>
        <section className="banner">
          <div className="container py-5">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
              <div className="col-10 col-sm-8 col-lg-6">
                <img
                  src={bannerImg}
                  className="d-block mx-lg-auto img-fluid"
                  alt="Bootstrap Themes"
                  width="500"
                  height="200"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6">
                <h1 className="display-5 fw-bold mb-5">
                  The best resource for understanding and <br /> researching
                  Industrial Classification Systems across countries.
                </h1>
                <p className="lead">
                  Research over 15 different industrial classification <br />
                  systems with detailed insights to each one of them.
                </p>
                <p>Start researching below by entering a keyword or code </p>
              </div>
            </div>
          </div>
        </section>
        <section className="home-search p-5" ref={searchRef}>
          <div className="container">
            <div className="row g-3">
              <div className="col-md-4">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={dropDownValue}
                      displayEmpty
                      onChange={changeDropDown}
                      inputProps={{
                        'aria-label': 'Without label',
                        'background-color': '#ffffff'
                      }}
                      className="box-white"
                      sx={{
                        '&.Mui-focused': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgb(240, 98, 64)'
                          }
                        }
                      }}
                    >
                      {codeData?.data?.data?.map((item: any, i: number) => {
                        return (
                          <MenuItem key={i} value={item?.codel}>{item?.codeu}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="col-md-6">
                {/* <SearchIcon/> */}
                <TextField
                  placeholder="Search by Keywords or Code"
                  fullWidth
                  style={{ fontSize: '2rem' }}
                  type="text"
                  id="message"
                  name="message"
                  onChange={handleChange}
                  onKeyPress={keyPress}
                  value={message}
                  inputProps={{
                    'aria-label': 'Without label',
                    'background-color': '#ffffff'
                  }}
                  className="box-white"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(240, 98, 64)'
                      }
                    }
                  }}
                />
              </div>
              <div className="col-md-2">
                <Button
                  onClick={() => navigateToStructure()}
                  className="search-button"
                  style={{
                    backgroundColor:
                      message?.trim().length <= 2 ? '' : '#f06240',
                    borderRadius: '3px',
                    minHeight: '48px',
                    height: '100%',
                    textTransform: 'none',
                    fontSize: '1.2rem',
                    width: '100%'
                  }}
                  disabled={message?.trim().length <= 2}
                  variant="contained"
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className="home-list p-5">
          <div className="container tab-link-wrapper">
            <TabLinks />
          </div>
        </section>
        <section className="regions">
          <div className="container px-4 py-5">
            <h2 className="pb-2 text-center">
              World Regions and Industrial Classifications
            </h2>
            <div className="text-center">
              <div className="App">
                <WorldMap
                  size="xxl"
                  data={constCountryState}
                  color={'#F06240'}
                  onClickFunction={(countryData: any) =>
                    selectCountry(countryData)
                  }
                  borderColor={'#252525'}
                />
              </div>
              {/* <img src={countryImg} alt="" /> */}
            </div>

            <div style={{marginBottom: '10px'}}>Country : {countryid}
            </div>


            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 ">
              <div className=" d-flex-xl-column align-items-start">
                <div>
                  <h5 className="mb-2">Classification System</h5>
                  <div>
                    {newData && newData?.data?.data[0] ? (
                      <p>
                        Primary Code :{' '}
                        {newData && newData?.data?.data[0]?.data[0]?.level1_tab}
                        <br />
                        Description :{' '}
                        {newData && newData?.data?.data[0]?.data[0]?.pic_eng}
                        <br />
                      </p>
                    ) : (
                      'No data available'
                    )}
                  </div>
                </div>
                <div>
                  <h5 className="mb-2">Development Indicators</h5>
                  <div>
                    {newData && newData?.data?.data[1] ? (
                      <p>
                        GDP ($bn) :{' '}
                        {newData &&
                          newData?.data?.data[1]?.data[0]?.gdp_$billions}
                        <br />
                        Agriculture (% GDP):{' '}
                        {newData &&
                          newData?.data?.data[1]?.data[0]
                            ?.agriculture_percentage_of_gdp}
                        <br />
                        Industry (% GDP):{' '}
                        {newData &&
                          newData?.data?.data[1]?.data[0]
                            ?.industry_percentage_of_gdp}
                        <br />
                        Manufacturing (% GDP):{' '}
                        {newData &&
                          newData?.data?.data[1]?.data[0]
                            ?.manufacturing_percentage_of_gdp}
                        <br />
                        Services (% GDP):{' '}
                        {newData &&
                          newData?.data?.data[1]?.data[0]
                            ?.services_percentage_of_gdp}
                        <br />
                      </p>
                    ) : (
                      'No data available'
                    )}
                  </div>
                </div>
              </div>
              <div className=" d-flex align-items-start justify-content-end col-lg-5">
                <div>
                  <h5 className="mb-2">Major Activities</h5>
                  <div>
                    {newData && newData?.data?.data[2] ? (
                      <p>
                        {newData &&
                          newData?.data?.data[2]?.data[0]
                            ?.isic_2_digit_description}{' '}
                        :{' '}
                        {new Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                          maximumFractionDigits: 0, 
                          minimumFractionDigits: 0
                        }).format(
                          newData && newData?.data?.data[2]?.data[0]?.value
                        )}
                        <br />
                        {newData &&
                          newData?.data?.data[2]?.data[1]
                            ?.isic_2_digit_description}{' '}
                        :{' '}
                        {new Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                          maximumFractionDigits: 0, 
                          minimumFractionDigits: 0
                        }).format(
                          newData && newData?.data?.data[2]?.data[1]?.value
                        )}
                        <br />
                        {newData &&
                          newData?.data?.data[2]?.data[2]
                            ?.isic_2_digit_description}{' '}
                        :{' '}
                        {new Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                          maximumFractionDigits: 0, 
                          minimumFractionDigits: 0
                        }).format(
                          newData && newData?.data?.data[2]?.data[2]?.value
                        )}
                        <br />
                        {newData &&
                          newData?.data?.data[2]?.data[3]
                            ?.isic_2_digit_description}{' '}
                        :{' '}
                        {new Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                          maximumFractionDigits: 0, 
                          minimumFractionDigits: 0
                        }).format(
                          newData && newData?.data?.data[2]?.data[3]?.value
                        )}
                        <br />
                        {newData &&
                          newData?.data?.data[2]?.data[4]
                            ?.isic_2_digit_description}{' '}
                        :{' '}
                        {new Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                          maximumFractionDigits: 0, 
                          minimumFractionDigits: 0
                        }).format(
                          newData && newData?.data?.data[2]?.data[4]?.value
                        )}
                      </p>
                    ) : (
                      'No data available'
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start justify-content-end col-lg-4">
                <div>
                  <h5 className="mb-2">Employment Stats</h5>
                  <div>
                    {newData && newData?.data?.data[3] ? (
                      <p>
                        {newData && newData?.data?.data[3]?.data[0]?.indicator}{' '}
                        : {newData && newData?.data?.data[3]?.data[0]?.total}
                        <br />
                        {newData &&
                          newData?.data?.data[3]?.data[1]?.indicator}{' '}
                        : {newData && newData?.data?.data[3]?.data[1]?.total}
                        <br />
                        {newData &&
                          newData?.data?.data[3]?.data[2]?.indicator}{' '}
                        : {newData && newData?.data?.data[3]?.data[2]?.total}
                        <br />
                        {newData &&
                          newData?.data?.data[3]?.data[3]?.indicator}{' '}
                        : {newData && newData?.data?.data[3]?.data[3]?.total}
                        <br />
                        {newData &&
                          newData?.data?.data[3]?.data[4]?.indicator}{' '}
                        : {newData && newData?.data?.data[3]?.data[4]?.total}
                      </p>
                    ) : (
                      'No data available'
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{fontStyle:'italic', color:'#5d5d5d' , position:'relative' , top:'30px'}}>Source :{' '}
             <a style={{color:'#5d5d5d' , outline : '0'}} href="/#" onClick={() => navigateToWorldBankGroup()}>The World Bank Group,{' '}</a>
             <a style={{color:'#5d5d5d' , outline : '0'}} href="/#" onClick={() => navigateToInternationalLabourOrganization()}>International Labour Organization,{' '}</a>
             <a style={{color:'#5d5d5d' , outline : '0'}} href="/#" onClick={() => navigateToUNIDO()}>UNIDO</a>
            </div>
          </div>
        </section>
        <section className="code">
          <div className="container px-4 py-5">
            <h2 className="pb-2 text-center text-light">Code Conversion</h2>
            <p className="text-center">
              Code mapping and cross references across multiple industrial
              classification systems. Enter a code and select the desired
              classification systems.
            </p>
            <div className="row g-3 my-4">
              <div className="col-md-5">
                {/* <OutlinedInput
                  className="box-outline"
                  fullWidth
                  placeholder="Search"
                /> */}
                <TextField
                  placeholder={
                    codeConversionFromDropDownValue == 'sic'
                      ? 'Enter 4 digit code'
                      : 'Enter 6 digit code'
                  }
                  fullWidth
                  style={{ fontSize: '2rem' }}
                  type="text"
                  id="codeConversion"
                  name="codeConversionValue"
                  onChange={codeConversionValueChange}
                  value={codeConversionValue}
                  inputProps={{
                    'aria-label': 'Without label',
                    'background-color': '#ffffff'
                  }}
                  className="box-white"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(240, 98, 64)'
                      }
                    }
                  }}
                />
              </div>
              <div className="col-md-7">
                <ul className="d-flex g-3 list-search">
                  <li className="">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={codeConversionFromDropDownValue}
                          displayEmpty
                          onChange={changeCodeConversionFromDropDown}
                          inputProps={{
                            'aria-label': 'Without label',
                            'background-color': '#ffffff'
                          }}
                          className="box-white"
                          sx={{
                            '&.Mui-focused': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(240, 98, 64)'
                              }
                            }
                          }}
                        >
                          {codeData?.data?.data?.map((item: any, i: number) => {
                            if (item?.codel != codeConversionToDropDownValue) {
                              return (
                                <MenuItem key={i} value={item?.codel}>
                                  {item?.codeu}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </li>
                  <li className="list-search-btn">
                    <button
                      className="btn-filter"
                      onClick={() => swapCodeConversion()}
                    >
                      <img src={arrowsImg} />
                    </button>
                  </li>
                  <li className="">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={codeConversionToDropDownValue}
                          displayEmpty
                          onChange={changeCodeConversionToDropDown}
                          inputProps={{
                            'aria-label': 'Without label',
                            'background-color': '#ffffff'
                          }}
                          className="box-white"
                          sx={{
                            '&.Mui-focused': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(240, 98, 64)'
                              }
                            }
                          }}
                        >
                          {codeData?.data?.data?.map((item: any, i: number) => {
                            if (
                              item?.codel != codeConversionFromDropDownValue
                            ) {
                              return (
                                <MenuItem key={i} value={item?.codel}>
                                  {item?.codeu}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </li>
                </ul>
              </div>

              <div className="col-md-12 text-center mt-5">
                <Button
                  onClick={() => navCodeConversion()}
                  style={{
                    backgroundColor:
                      codeConversionValue?.length <= 0
                        ? 'rgb(255 248 248 / 12%)'
                        : '#f06240',
                    borderRadius: '3px',
                    minWidth: '160px',
                    minHeight: '48px',
                    textTransform: 'none',
                    fontSize: '1.2rem'
                  }}
                  disabled={codeConversionValue?.length <= 0}
                  variant="contained"
                >
                  Convert
                </Button>{' '}
              </div>
              {typeof codeConversionResults?.data?.data == 'string' && (
                <p>{codeConversionResults?.data?.data}</p>
              )}

              {typeof codeConversionResults?.data?.data == 'object' &&
                codeConversionResults?.data?.data?.map(
                  (example: any, j: number) => {
                    return (
                      <p>
                        {example?.sic_code + ':' + example?.sic_desc} ------{' '}
                        {example?.naics_code + ':' + example?.naics_desc}
                      </p>
                    );
                  }
                )}
            </div>
          </div>
        </section>

        <section className="download py-5">
          <div className="container px-4 py-5">
            <h2 className="pb-2 text-center">
              Download detailed classifications
            </h2>
            <p className="text-center">
              You can download detailed industry classification with all
              inclusions, exclusions and explanatory notes by selecting the
              options below.
            </p>

            <div className="row g-3 mt-4">
              <div className="col-md-4">
                <SelectDropDown />
              </div>
              <div className="col-md-4">
                <SelectDropDown />
              </div>
              <div className="col-md-4">
                <SelectDropDown />
              </div>
              <div className="col-md-12 text-center mt-5">
                <Button
                  style={{
                    backgroundColor: '#f06240',
                    borderRadius: '3px',
                    minWidth: '160px',
                    minHeight: '48px',
                    textTransform: 'none',
                    fontSize: '1.2rem'
                  }}
                  variant="contained"
                >
                  Download
                </Button>{' '}
              </div>
            </div>
          </div>

          <div className="site_tittle">
            <h1 className="site_tittle_head">Coming Soon...</h1>
          </div>
          <div className="overlay"></div>
        </section>

        <section className="testimonials">
          <div className="container px-4 py-5">
            <h2 style={{ paddingBottom: '3rem' }} className="text-center">
              What’s an Industry Classification System
            </h2>

            <div className="card-text">
              IndustryCodes is your comprehensive guide to industry
              classification systems worldwide. Explore our extensive collection
              of categorization frameworks from various countries, providing
              sector codes, sub-sectors, and industry codes. Our search and
              comparison tools make it effortless to navigate and compare
              classifications, identifying similarities and differences. Stay
              informed with resources and guides that delve into industry
              trends, methodologies, and practical applications.
            </div>
            <br />
            <div>
              Join our engaged community of industry professionals and
              enthusiasts to share insights and contribute to our collective
              knowledge. IndustryCodes is your trusted companion in
              understanding industry classifications across borders. Unlock a
              world of knowledge today!
              <div className="col-md-12 text-center pt-4">
                <Button
                  onClick={() => navigate('/codeSystem')}
                  style={{
                    backgroundColor: '#f06240',
                    borderRadius: '3px',
                    minWidth: '160px',
                    minHeight: '48px',
                    textTransform: 'none',
                    fontSize: '1.2rem'
                  }}
                  variant="contained"
                >
                  Read More
                </Button>{' '}
              </div>
            </div>
            {/* <div
              id="carouselExampleInterval"
              className="carousel slide testimonial-slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item" data-bs-interval="10000">
                  <ul className="row">
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgOne}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Jeffery Erhunse</h5>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgTwo}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Charles Etoroma</h5>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgThree}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Philip Martin</h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <ul className="row">
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgThree}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Jeffery Erhunse</h5>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgThree}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Charles Etoroma</h5>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgThree}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Philip Martin</h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="carousel-item active">
                  <ul className="row">
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgOne}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Jeffery Erhunse</h5>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgTwo}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Charles Etoroma</h5>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgThree}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Philip Martin</h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div> */}
          </div>
        </section>

        <div
          id="carouselExampleInterval"
          className="carousel slide testimonial-slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item" data-bs-interval="10000">
              <ul className="row">
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  <Carousel.Item>
                    qevqervqevqerv
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgOne}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Jeffery Erhunse</h5>
                        </div>
                      </div>
                    </li>
                    {/* <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                      </Carousel.Caption> */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgOne}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Jeffery Erhunse</h5>
                        </div>
                      </div>
                    </li>
                  </Carousel.Item>
                  <Carousel.Item>
                    <li className="col-md-4">
                      <div className="card border-0">
                        <img
                          src={avatarImgOne}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pt-5">
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <h5 className="card-title pt-5">Jeffery Erhunse</h5>
                        </div>
                      </div>
                    </li>
                  </Carousel.Item>
                </Carousel>
              </ul>
            </div>
          </div>
        </div>
      </Box>
      <Footer countryname={countryname} />
    </div>
  );
}

export default App;
