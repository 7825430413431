import * as React from 'react';
import {Helmet} from "react-helmet";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import sicTimeline from 'assets/sic-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function About(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const historyRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/codeSystem');
  };

  React.useEffect(() => {
    getSearchResults();
    window.scrollTo(0, 0);
  }, []);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  const getSearchResults = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/sic-naics',
      success: (jsonResponse) => {
        updateArray(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  const subsectors = ['5', '4', '3', '20', '10', '2', '8', '7', '16', '8'];
  const industry_groups = [
    '23',
    '20',
    '14',
    '140',
    '37',
    '18',
    '41',
    '30',
    '71',
    '22'
  ];
  const naics_industries = [
    '58',
    '31',
    '26',
    '459',
    '67',
    '69',
    '64',
    '53',
    '150',
    '28'
  ];
  const us_detail1 = [
    '86',
    '55',
    '43',
    '619',
    '114',
    '89',
    '113',
    '90',
    '237',
    '58'
  ];
  const us_detail2 = [
    '156',
    '106',
    '95',
    '1974',
    '165',
    '353',
    '226',
    '137',
    '575',
    '101'
  ];
  const total = [
    '576',
    '473',
    '435',
    '1,084',
    '507',
    '1,827',
    '914',
    '425',
    '2,376',
    '382'
  ];

  function updateArray(jsonResponse: any) {
    if (jsonResponse?.data) {
      const updatedData = jsonResponse.data.map((item: any, i: number) => {
        if (item?.sic) {
          const updatedsic = item.sic.map((obj: any, j: number) => ({
            ...obj,
            subsectors: subsectors[j],
            industry_groups: industry_groups[j],
            naics_industries: naics_industries[j],
            us_detail1: us_detail1[j],
            us_detail2: us_detail2[j],
            total: total[j]
          }));

          return {
            ...item,
            sic: updatedsic
          };
        }
        return item;
      });
      setSearchResults(updatedData);
    }
  }

  return (
    <div className="App inner-pages">
      <Helmet>
      <title> Know about SIC Code | Understanding Standard Industrial Classification System</title>    
      <meta name="Learn about the Standard Industrial Classification (SIC) code system, its historical significance, hierarchical structure, and usage in statistical reporting, business identification, economic research, and policy development."/>
      <meta name="keywords" content="SIC code, Standard Industrial Classification System, Industry categorization, Economic analysis, Statistical reporting, Business identification, Policy development, Historical insights."/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Know about SIC code
              </h2>
              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section style={{ padding: '3.5rem 10%' }}>
          <Box
            className="h-tab"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <div>
              The Standard Industrial Classification (SIC) code system stands as
              a pioneering milestone in the organization and understanding of
              industries. Originating in the early 20th century, it emerged from
              the need to systematically categorize businesses for statistical,
              regulatory, and analytical purposes. The SIC system classifies
              industries into hierarchical divisions, groups, and codes,
              creating a structured framework for data representation. Each
              numerical code signifies a specific economic activity, enabling
              accurate reporting, business identification, and economic
              research. While superseded by the North American Industry
              Classification System (NAICS), the SIC code system's historical
              significance endures, offering insights into the evolution of
              industry classification and its vital role in shaping economic
              analysis and policy development.
            </div>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="me-5 vtab-aside  home-tabs"
              style={{ marginTop: '56px' }}
            >
              <Tab
                label="Structural Overview"
                onClick={() => moveToRef(structuralRef)}
              />
              <Tab label="History" onClick={() => moveToRef(historyRef)} />
              <Tab label="Timelines" onClick={() => moveToRef(timelineRef)} />
              <Tab
                label="Usage & Importance"
                onClick={() => moveToRef(usageRef)}
              />
            </Tabs>

            <TabPanel value={value} index={2}>
              <div>
                SIC codes have a hierarchical, top-down structure that begins
                with typical characteristics and narrows down to the specifics.
                The first two digits of the code represent the major industry
                sector to which a business belongs. The third and fourth digits
                describe the sub-classification of the business group and
                specialization, respectively. For example, "20" refers to a
                business that deals in “Manufacturing of Food and Kindred
                Products " Adding "2" as a third digit to get "202" indicates
                that the business operates in Manufacturing of Dairy Products”.
                The fourth digit distinguishes the specific industry sector, so
                a code of "2024" indicates that the business is concerned with
                "Manufacturing of Ice Cream and Frozen Deserts".
              </div>
            </TabPanel>

            <TabPanel value={value} index={0}>
              <div
                className="tab-panel-wrapper"
                style={{ paddingBottom: '.5rem' }}
                ref={structuralRef}
              >
                <div className="code-info-parent">
                  <div className="code-info-wrap">
                    <div className="grid-container">
                      <div>
                        <div> </div>
                      </div>
                      <div
                        className="white"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          D
                        </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          2
                        </div>
                      </div>{' '}
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          0
                        </div>
                      </div>{' '}
                      <div
                        className="pink"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          2
                        </div>
                      </div>{' '}
                      <div
                        className="green"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          4
                        </div>
                      </div>
                      <div className="item7 white"></div>
                      <div className="item8 orange"></div>
                      <div className="item9 orange"></div>
                      <div className="item10 pink"></div>
                      <div className="item11 green"></div>
                      <div
                        className="item32 white table-sector-name"
                        style={{ color: 'black', textAlign: 'end' }}
                      >
                        Division&nbsp;
                      </div>
                      <div
                        className="item33 orange table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Major Group&nbsp;
                      </div>
                      <div
                        className="item13 pink table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Industry Group&nbsp;
                      </div>
                      <div
                        className="item14 green table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Industry&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-panel-wrapper">
                <table id="structural-table">
                  <tr>
                    <th>Division</th>
                    <th>Range</th>
                    <th style={{textAlign:'left', width:'50%'}}>Title</th>
                    <th>Major Group 2-digit</th>
                    <th>Industry Group 3-digit</th>
                    <th>Industry Group 4-digit</th>
                    <th>Total</th>
                    <th>6-digit</th>
                    <th>8-digit</th>
                  </tr>

                  {searchResults?.map((item: any, i: number) => {
                    if (item?.sic) {
                      return item?.sic?.map((data: any, j: number) => {
                        return (
                          <tr
                            key={data.id}
                            className={j % 2 === 0 ? 'even-row' : 'odd-row'}
                          >
                            <td>{data?.division}</td>
                            <td>{data?.code_range}</td>
                            <td style={{textAlign:'left'}}>{data?.short_desc}</td>
                            <td>{data?.subsectors}</td>
                            <td>{data?.industry_groups}</td>
                            <td>{data?.naics_industries}</td>
                            <td>{data?.us_detail1}</td>
                            <td>{data?.us_detail2}</td>
                            <td>{data?.total}</td>
                          </tr>
                        );
                      });
                    }
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{textAlign:'left'}}>Total</td>
                    <td>83</td>
                    <td>416</td>
                    <td>1,005</td>
                    <td>1,504</td>
                    <td>3,888</td>
                    <td>1,876</td>
                  </tr>
                </table>
              </div>
            </TabPanel>

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '32px',
                paddingBottom: '16px'
              }}
              ref={historyRef}
            >
              History
            </h2>

            <div style={{ marginBottom: '24px' }}>
              The history of the Standard Industrial Classification (SIC) code
              system is a journey that spans decades, shaped by the evolving
              economic landscape and the need for standardized industry
              categorization. It originated in the early 20th century when
              governments and businesses recognized the importance of organizing
              economic data for analysis and regulatory purposes. In 1937, the
              U.S. Bureau of the Budget developed the initial version of the SIC
              system, known as the "Standard Industrial Classification Manual,"
              which aimed to categorize industries into major groups based on
              their predominant activities.
            </div>
            <div style={{ marginBottom: '24px' }}>
              The SIC system saw significant milestones over the years. In 1941,
              the first edition of the manual was published, marking a crucial
              step in providing a systematic framework for industry
              classification. A major turning point came in 1967 when the SIC
              system underwent its first substantial revision, resulting in the
              "Standard Industrial Classification Manual, 1967." This revision
              introduced more detailed industry categories to better represent
              the diverse range of economic activities.
            </div>
            <div style={{ marginBottom: '24px' }}>
              In response to technological advancements and shifts in the
              industrial landscape, the SIC system underwent further revisions.
              Notably, the "Standard Industrial Classification Manual, 1972" was
              published, reflecting a comprehensive update to align with the
              changing economic realities. The final version of the SIC system,
              SIC 1987, was introduced in 1987, incorporating emerging
              industries and new business activities.
            </div>
            <div>
              While the SIC system played a crucial role in standardizing
              industry classification for decades, it eventually gave way to the
              North American Industry Classification System (NAICS) in 1997.
              NAICS provided a more modern and flexible classification framework
              that accommodated the dynamic nature of industries in an
              increasingly globalized world. Despite its transition to NAICS,
              the legacy of the SIC system continues to influence industry
              categorization and remains a significant historical milestone in
              the realm of economic analysis and regulation.
            </div>

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Timeline of SIC
            </h2>

            <embed src={sicTimeline} />

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '56px',
                paddingBottom: '16px'
              }}
              ref={usageRef}
            >
              Usage & Importance of SIC
            </h2>

            <div className="usage-table">
              <div className="usage-table-left">
                <h3>Usage</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Statistical Reporting:</strong> Used for collecting
                    and reporting economic data for government agencies and
                    research organizations.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Business Identification:</strong> Employed for
                    business registration, regulatory compliance, and government
                    interactions.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Economic Research:</strong> Fundamental in analyzing
                    industry trends, market dynamics, and economic performance.
                  </li>
                </ul>
              </div>
              <div className="usage-table-right">
                <h3>Importance</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Standardization:</strong> Provides a common language
                    for industry classification, enhancing communication and
                    data consistency.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Development:</strong> Guides policymakers in
                    formulating targeted regulations and incentives for
                    different sectors.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Data Analysis:</strong> Enables accurate
                    cross-sector and cross-industry comparisons for economic
                    research.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Historical Insights:</strong> Offers insights into
                    the evolution of industries and economic structures over
                    time.
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
