// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPagination-ul button {
  font-size: 1.4rem;
  color: #888888; }
  .MuiPagination-ul button.Mui-selected {
    background-color: var(--red);
    color: var(--white); }
    .MuiPagination-ul button.Mui-selected:hover, .MuiPagination-ul button.Mui-selected:focus {
      background-color: var(--red);
      color: var(--white); }
  .MuiPagination-ul button:hover, .MuiPagination-ul button:focus {
    background-color: rgba(240, 98, 64, 0.17);
    color: #888888; }
  .MuiPagination-ul button.MuiPaginationItem-previousNext:focus {
    background-color: transparent; }
    .MuiPagination-ul button.MuiPaginationItem-previousNext:focus::after, .MuiPagination-ul button.MuiPaginationItem-previousNext:focus::before {
      color: var(--red); }
  .MuiPagination-ul button[aria-label="Go to previous page"] {
    color: #000; }
    .MuiPagination-ul button[aria-label="Go to previous page"]::after {
      content: 'Previous';
      text-transform: none;
      padding-left: 1.6rem;
      color: #888888; }
  .MuiPagination-ul button[aria-label="Go to next page"] {
    color: #000; }
    .MuiPagination-ul button[aria-label="Go to next page"]::before {
      content: 'Next';
      text-transform: none;
      padding-right: 1.6rem;
      color: #888888; }
`, "",{"version":3,"sources":["webpack://./src/components/PaginationLink/PaginationLink.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB;EACjB,cAAc,EAAA;EAHlB;IAKM,4BAA4B;IAC5B,mBAAmB,EAAA;IANzB;MASQ,4BAA4B;MAC5B,mBAAmB,EAAA;EAV3B;IAeM,yCAAwC;IACxC,cAAc,EAAA;EAhBpB;IAoBQ,6BAA6B,EAAA;IApBrC;MAuBU,iBAAiB,EAAA;EAvB3B;IA4BM,WAAW,EAAA;IA5BjB;MA8BQ,mBAAmB;MACnB,oBAAoB;MACpB,oBAAoB;MACpB,cAAc,EAAA;EAjCtB;IAqCM,WAAW,EAAA;IArCjB;MAuCQ,eAAe;MACf,oBAAoB;MACpB,qBAAqB;MACrB,cAAc,EAAA","sourcesContent":[".MuiPagination-ul {\n  button {\n    font-size: 1.4rem;\n    color: #888888;\n    &.Mui-selected {\n      background-color: var(--red);\n      color: var(--white);\n      &:hover,\n      &:focus {\n        background-color: var(--red);\n        color: var(--white);\n      }\n    }\n    &:hover,\n    &:focus {\n      background-color:rgba(240, 98, 64 ,0.17) ;\n      color: #888888;\n    }\n    &.MuiPaginationItem-previousNext {\n      &:focus {\n        background-color: transparent;\n        &::after,\n        &::before {\n          color: var(--red);\n        }\n      }\n    }\n    &[aria-label=\"Go to previous page\"] {\n      color: #000;\n      &::after {\n        content: 'Previous';\n        text-transform: none;\n        padding-left: 1.6rem;\n        color: #888888;\n      }\n    }\n    &[aria-label=\"Go to next page\"] {\n      color: #000;\n      &::before {\n        content: 'Next';\n        text-transform: none;\n        padding-right: 1.6rem;\n        color: #888888;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
