import * as React from 'react';
import {Helmet} from "react-helmet";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import isicTimeline from 'assets/isic-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function About(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const historyRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/codeSystem');
  };

  React.useEffect(() => {
    getSearchResults();
    window.scrollTo(0, 0);
  }, []);

  let [searchResults, setSearchResults]: any = useState<any[]>([]);

  const getSearchResults = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/isic',
      success: (jsonResponse) => {
        updateArray(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  const subsectors = [
    '3',
    '5 ',
    '24',
    '1',
    '4',
    '3',
    '3',
    '5',
    '2',
    '6',
    '3',
    '1',
    '7',
    '6',
    '1',
    '1',
    '3',
    '4',
    '3',
    '2',
    '1'
  ];
  const industry_groups = [
    '13',
    '10',
    '71',
    '3',
    '6',
    '8',
    '20',
    '11',
    '6',
    '13',
    '10',
    '2',
    '14',
    '19',
    '3',
    '5',
    '9',
    '5',
    '6',
    '3',
    '1'
  ];
  const naics_industries = [
    '38',
    '14',
    '137',
    '3',
    '8',
    '11',
    '43',
    '20',
    '7',
    '23',
    '18',
    '2',
    '14',
    '26',
    '7',
    '8',
    '9',
    '10',
    '17',
    '3',
    '1'
  ];
  const us_detail1 = [
    '54',
    '29',
    '232',
    '7',
    '18',
    '22',
    '66',
    '36',
    '15',
    '42',
    '31',
    '5',
    '35',
    '51',
    '11',
    '14',
    '21',
    '19',
    '26',
    '8',
    '3'
  ];

  function updateArray(jsonResponse: any) {
    if (jsonResponse) {
      const updatedData = jsonResponse?.data?.map((item: any, i: number) => {
        if (item?.isic) {
          const updatedisic = item.isic.map((obj: any, j: number) => ({
            ...obj,
            subsectors: subsectors[j],
            industry_groups: industry_groups[j],
            naics_industries: naics_industries[j],
            us_detail1: us_detail1[j]
          }));

          return {
            ...item,
            isic: updatedisic
          };
        }
        return item;
      });
      setSearchResults(updatedData);
    }
  }

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Know about ISIC Code | Understanding International Standard Industrial Classification System</title>    
      <meta name="Learn about the International Standard Industrial Classification (ISIC) code system, its historical significance, hierarchical structure, and usage in statistical reporting, business identification, economic research, and policy development."/>
      <meta name="keywords" content="ISIC code, International Standard Industrial Classification System, industry categorization, economic analysis, statistical reporting, business identification, policy development, historical insights."/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Know about ISIC code
              </h2>
              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section style={{ padding: '3.5rem 10%' }}>
          <Box
            className="h-tab"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <div>
              The International Standard Industrial Classification (ISIC)
              system, developed by the United Nations, stands as a cornerstone
              of global economic analysis and understanding. By providing a
              standardized framework for categorizing economic activities across
              countries and regions, ISIC facilitates accurate cross-country
              comparisons, policy coordination, and informed decision-making.
              Since its inception in 1948, ISIC has evolved through several
              revisions to mirror the dynamic shifts in industries,
              technological advancements, and international trade patterns. It
              serves as a universal language for economic classification,
              enabling researchers, policymakers, and analysts to study industry
              trends, assess economic performance, and engage in meaningful
              cross-border discussions. With its enduring significance, ISIC
              continues to play a pivotal role in harmonizing data collection,
              promoting sustainable development, and fostering international
              cooperation in the complex realm of global economics.
            </div>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="me-5 vtab-aside  home-tabs"
              style={{ marginTop: '36px', marginBottom: '24px' }}
            >
              <Tab
                label="Structural Overview"
                onClick={() => moveToRef(structuralRef)}
              />
              <Tab label="History" onClick={() => moveToRef(historyRef)} />
              <Tab label="Timelines" onClick={() => moveToRef(timelineRef)} />
              <Tab
                label="Usage & Importance"
                onClick={() => moveToRef(usageRef)}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div
                className="tab-panel-wrapper"
                style={{ paddingBottom: '.5rem' }}
                ref={structuralRef}
              >
                <div className="code-info-parent">
                  <div className="code-info-wrap">
                    <div className="grid-container">
                      <div>
                        <div> </div>
                      </div>
                      <div
                        className="white"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          D
                        </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          3
                        </div>
                      </div>{' '}
                      <div
                        className="pink"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="green"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          1
                        </div>
                      </div>
                      <div className="item7 white"></div>
                      <div className="item8 orange"></div>
                      <div className="item9 orange"></div>
                      <div className="item10 pink"></div>
                      <div className="item11 green"></div>
                      <div
                        className="item32 white table-sector-name"
                        style={{ color: 'black', textAlign: 'end' }}
                      >
                        Section&nbsp;
                      </div>
                      <div
                        className="item33 orange table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Division&nbsp;
                      </div>
                      <div
                        className="item13 pink table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Group&nbsp;
                      </div>
                      <div
                        className="item14 green table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Class&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-panel-wrapper">
                <table id="structural-table">
                  <tr>
                    <th>Division</th>
                    <th>Range</th>
                    <th style={{textAlign:'left', width:'50%'}}>Title</th>
                    <th>Division 2-digit</th>
                    <th>Group 3-digit</th>
                    <th>Class 4-digit</th>
                    <th>Total</th>
                  </tr>

                  {searchResults?.map((item: any, i: number) => {
                    if (item?.isic) {
                      return item?.isic?.map((data: any, j: number) => {
                        return (
                          <tr key={data.id}
                          className={j % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{data.division}</td>
                            <td>{data?.code_range}</td>
                            <td style={{textAlign:'left'}}>{data?.short_desc}</td>
                            <td>{data?.subsectors}</td>
                            <td>{data?.industry_groups}</td>
                            <td>{data?.naics_industries}</td>
                            <td>{data?.us_detail1}</td>
                          </tr>
                        );
                      });
                    }
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{textAlign:'left'}}>Total</td>
                    <td>88</td>
                    <td>238</td>
                    <td>419</td>
                    <td>745</td>
                  </tr>
                </table>
              </div>
            </TabPanel>

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '32px',
                paddingBottom: '16px'
              }}
              ref={historyRef}
            >
              History
            </h2>
            <div>
              The International Standard Industrial Classification (ISIC) is a
              globally recognized classification system developed by the United
              Nations to provide a harmonized framework for categorizing
              economic activities across countries and regions. It addresses the
              need for a standardized language to classify industries and
              support international cooperation and data analysis.
            </div>

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Timeline of ISIC
            </h2>

            <embed src={isicTimeline} />

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '56px',
                paddingBottom: '16px'
              }}
              ref={usageRef}
            >
              Usage & Importance of ISIC
            </h2>

            <div className="usage-table">
              <div className="usage-table-left">
                <h3>Usage</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Global Comparisons:</strong> SIC codes enable
                    consistent industry classification for international
                    economic analysis, facilitating cross-country comparisons
                    and trade assessments.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Alignment:</strong> Governments and
                    international organizations utilize ISIC classifications to
                    coordinate policies and initiatives on a global scale,
                    promoting sustainable development.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Data Aggregation:</strong> ISIC provides a
                    standardized framework for collecting and reporting economic
                    data at the international level, supporting accurate
                    statistical analysis and research.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Research and Reporting:</strong> Economists,
                    researchers, and analysts rely on ISIC codes to study global
                    industry trends, economic performance, and market dynamics.
                  </li>
                </ul>
              </div>
              <div className="usage-table-right">
                <h3>Importance</h3>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>International Standardization:</strong> ISIC offers
                    a unified classification system, allowing for accurate
                    cross-country benchmarking, analysis, and communication.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Coordination: </strong> ISIC aids
                    international organizations and governments in harmonizing
                    policies, fostering global economic cooperation and
                    development.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Research Consistency:</strong> ISIC ensures
                    consistency in economic research, providing a common
                    framework for analyzing global industry trends and dynamics.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Data Quality:</strong> ISIC contributes to reliable
                    and comparable economic statistics, supporting informed
                    decision-making and policy formulation on a global scale.
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
