import * as React from 'react';
import {Helmet} from "react-helmet";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { callAPI } from 'helpers/api';
import { BASE_URL } from 'config/apiconfig';
import Footer from 'components/Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from 'components/Navbar/NavBar';
import naicsTimeline from 'assets/naics-timeline.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-0`}
      aria-labelledby={`vertical-tab-0`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
function moveToRef(ref: any) {
  scrollToRef(ref);
}

export default function About(props: any) {
  const navigate = useNavigate();
  const structuralRef = React.useRef(null);
  const timelineRef = React.useRef(null);
  const usageRef = React.useRef(null);
  const historyRef = React.useRef(null);

  let [parentCategory, setParentCategory]: any = useState<any[]>([]);
  let [childCategory, setChildCategory]: any = useState<any[]>([]);
  let [codeTypeString, setCodeTypeString]: any = useState<any[]>([]);
  let [codeDetails, setCodeDetails]: any = useState<any[]>([]);
  let [crossRefs, setCrossRefs]: any = useState<any[]>([]);
  let [codeExamples, setCodeExamples]: any = useState<any[]>([]);

  let [sixDigitData, setSixDigitData]: any = useState<any[]>([]);
  let [eightDigitData, setEightDigitData]: any = useState<any[]>([]);

  const [menuValueSelected, setMenuValue] = React.useState('sic');
  const menuChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenuValue(newValue);
  };

  const routeParams = useParams();
  var codeType: any = routeParams?.codeType;
  var codeValue: any = routeParams?.codeValue;

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetTop - 115);

  function moveToRef(ref: any) {
    scrollToRef(ref);
  }

  const navigateToStructure = () => {
    navigate('/codeSystem');
  };

  React.useEffect(() => {
    getSearchResults();
    window.scrollTo(0, 0);
  }, []);

  let [searchResults, setSearchResults]: any = useState<any>();

  const getSearchResults = () => {
    callAPI({
      method: 'get',
      params: {},
      resource: BASE_URL + 'div/sic-naics',
      success: (jsonResponse) => {
        updateArray(jsonResponse);
      },
      error: (error) => console.log(error)
    });
  };

  const subsectors = [
    '5',
    '3',
    '1',
    '3',
    '21',
    '3',
    '9',
    '11',
    '6',
    '5',
    '3',
    '1',
    '1',
    '2',
    '1',
    '4',
    '3',
    '2',
    '4',
    '8'
  ];
  const industry_groups = [
    '19',
    '5',
    '3',
    '10',
    '86',
    '19',
    '24',
    '29',
    '11',
    '11',
    '8',
    '9',
    '1',
    '11',
    '7',
    '18',
    '9',
    '6',
    '14',
    '8'
  ];
  const naics_industries = [
    '42',
    '11',
    '6',
    '28',
    '176',
    '69',
    '48',
    '42',
    '24',
    '27',
    '17',
    '35',
    '1',
    '29',
    '12',
    '30',
    '23',
    '10',
    '30',
    '29'
  ];
  const us_detail1 = [
    '32',
    '14',
    '10',
    '4',
    '249',
    '0',
    '16',
    '25',
    '10',
    '13',
    '11',
    '20',
    '3',
    '29',
    '12',
    '30',
    '23',
    '10',
    '30',
    '29'
  ];
  const us_detail2 = [
    '32',
    '7',
    '4',
    '27',
    '97',
    '69',
    '41',
    '32',
    '19',
    '22',
    '13',
    '29',
    '0',
    '19',
    '10',
    '23',
    '22',
    '7',
    '20',
    '29'
  ];
  const total = [
    '64',
    '21',
    '14',
    '31',
    '346',
    '69',
    '57',
    '57',
    '29',
    '35',
    '24',
    '49',
    '3',
    '44',
    '17',
    '39',
    '25',
    '15',
    '44',
    '29'
  ];

  function updateArray(jsonResponse: any) {
    if (jsonResponse?.data) {
      const updatedData = jsonResponse.data.map((item: any, i: number) => {
        if (item?.naics) {
          const updatedNaics = item.naics.map((obj: any, j: number) => ({
            ...obj,
            subsectors: subsectors[j],
            industry_groups: industry_groups[j],
            naics_industries: naics_industries[j],
            us_detail1: us_detail1[j],
            us_detail2: us_detail2[j],
            total: total[j]
          }));

          return {
            ...item,
            naics: updatedNaics
          };
        }
        return item;
      });
      setSearchResults(updatedData);
    }
  }

  return (
    <div className="App inner-pages">
      <Helmet>
      <title>Know about NAICS Code | Understanding North American Industry Classification System</title>    
      <meta name="Learn about the North American Industry Classification System (NAICS) code system, its historical significance, hierarchical structure, and usage in statistical reporting, business identification, economic research, and policy development."/>
      <meta name="keywords" content="NAICS code, North American Industry Classification System, Industry categorization, Economic analysis, Statistical reporting, Business identification, Policy development, Historical insights."/>
      </Helmet>
      <NavBar className="inner-header" />
      <Box sx={{ bgcolor: '#fff', paddingTop: '94px' }}>
        <section
          className="inner-banner"
          style={{ backgroundColor: '#252525' }}
        >
          <div className="container py-2 vertical-center">
            <div className="inner-banner-wrap d-flex flex-column  justify-content-center align-items-center">
              <h2 className="mb-0 text-start about-header">
                Know about NAICS code
              </h2>
              <a href="" onClick={() => navigateToStructure()}>
                <ArrowBackIcon
                  className="lnk-back-icon"
                  style={{ fill: '#F06240' }}
                />
                <span style={{ color: '#F06240' }}> Back to Home</span>
              </a>
            </div>
          </div>
        </section>
        <section style={{ padding: '3.5rem 10%' }}>
          <Box
            className="h-tab"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <div>
              The North American Industry Classification System (NAICS) stands
              as a robust and collaborative classification framework, uniting
              economic activities across the United States, Canada, and Mexico.
              Introduced in 1997, NAICS represents a modern approach to industry
              categorization, reflecting the dynamic changes in the North
              American business landscape. It plays a pivotal role in
              facilitating cross-border data analysis, policy coordination, and
              trade assessments. The NAICS system's hierarchical structure
              allows for precise industry classification, empowering
              researchers, policymakers, and analysts to comprehend industry
              trends, assess market dynamics, and drive informed
              decision-making. As an adaptable and evolving system, NAICS
              remains a critical tool for ensuring consistent and accurate
              economic reporting, supporting the formulation of effective
              policies, and contributing to the deeper understanding of economic
              activities within the North American context.  
            </div>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="me-5 vtab-aside  home-tabs"
              style={{ marginBottom: '24px', marginTop: '36px' }}
            >
              <Tab
                label="Structural Overview"
                onClick={() => moveToRef(structuralRef)}
              />
              <Tab label="History" onClick={() => moveToRef(historyRef)} />

              <Tab label="Timelines" onClick={() => moveToRef(timelineRef)} />
              <Tab
                label="Usage & Importance"
                onClick={() => moveToRef(usageRef)}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div
                className="tab-panel-wrapper"
                style={{ paddingBottom: '.5rem' }}
                ref={structuralRef}
              >
                <div className="code-info-parent">
                  <div className="code-info-wrap">
                    <div className="grid-container">
                      <div>
                        <div> </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          3
                        </div>
                      </div>
                      <div
                        className="orange"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          2
                        </div>
                      </div>
                      <div
                        className="pink"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px',
                            width: '135px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="green"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          2
                        </div>
                      </div>{' '}
                      <div
                        className="yellow"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px',
                            width: '135px'
                          }}
                        >
                          1
                        </div>
                      </div>{' '}
                      <div
                        className="blue"
                        style={{
                          height: '130px',
                          padding: '100px 0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '230px'
                          }}
                        >
                          3
                        </div>
                      </div>
                      <div className="item6 white"></div>
                      <div className="item77 orange"></div>
                      <div className="item78 orange"></div>
                      <div className="item80 pink"></div>
                      <div className="item81 green"></div>
                      <div className="item11 yellow"></div>
                      <div className="item111 blue"></div>
                      <div
                        className="item96 orange table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Sector&nbsp;
                      </div>
                      <div
                        className="item97 pink table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Subsector&nbsp;
                      </div>
                      <div
                        className="item98 green table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        Industry Group&nbsp;
                      </div>
                      <div
                        className="item99 yellow table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        NAICS Industry&nbsp;
                      </div>
                      <div
                        className="item100 blue table-sector-name"
                        style={{ color: 'white', textAlign: 'end' }}
                      >
                        6-Digit Industry&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-panel-wrapper">
                <table id="structural-table">
                  <tr>
                    <th>Division</th>
                    <th>Range</th>
                    <th style={{textAlign:'left' , width:'50%'}}>Code Title</th>
                    <th>Subsectors 3-digit</th>
                    <th>Industry Groups 4-digit</th>
                    <th>NAICS Industries 5-digit</th>
                    <th>U.S Detail</th>
                    <th>Same as 5-digit</th>
                    <th>Total</th>
                  </tr>

                  {searchResults?.map((item: any, i: number) => {
                    if (item?.naics) {
                      return item?.naics?.map((data: any, j: number) => {
                        return (
                          <tr  key={data.id}
                          className={j % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{data?.division}</td>
                            <td>{data?.code_range}</td>
                            <td style={{textAlign:'left'}}>{data?.short_desc}</td>
                            <td>{data?.subsectors}</td>
                            <td>{data?.industry_groups}</td>
                            <td>{data?.naics_industries}</td>
                            <td>{data?.us_detail1}</td>
                            <td>{data?.us_detail2}</td>
                            <td>{data?.total}</td>
                          </tr>
                        );
                      });
                    }
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{textAlign:'left'}}>Total</td>
                    <td>96</td>
                    <td>308</td>
                    <td>689</td>
                    <td>490</td>
                    <td>522</td>
                    <td>1,012</td>
                  </tr>
                </table>
              </div>
            </TabPanel>

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '32px',
                paddingBottom: '16px'
              }}
              ref={historyRef}
            >
              History
            </h2>

            <div style={{ marginBottom: '24px' }}>
              The North American Industry Classification System (NAICS) is a
              collaborative effort between the United States, Canada, and
              Mexico, aimed at creating a standardized and modern classification
              framework that reflects the evolving economic landscape of North
              America. NAICS emerged as a response to the limitations of the
              existing Standard Industrial Classification (SIC) system, which
              failed to capture the complexity and diversity of emerging
              industries in an era of globalization.
            </div>
            <div style={{ marginBottom: '24px' }}>
              In the landscape of evolving industries and burgeoning
              cross-border trade, the necessity for a resilient and adaptable
              industry classification system becomes evident by 1980. The
              culmination of this realization is SIC 1987, a final version of
              the SIC system released by the U.S. government. However, despite
              improvements, it struggles to keep pace with the shifting economic
              dynamics.
            </div>
            <div style={{ marginBottom: '24px' }}>
              By 1991, the Office of Management and Budget (OMB) in the United
              States initiates pivotal discussions on a novel industry
              classification system. Envisioned to embrace contemporary business
              practices, cross-border trade, and emerging sectors, this system
              would later be known as NAICS.
            </div>
            <div style={{ marginBottom: '24px' }}>
              The seminal moment arrives in 1994 when the three North American
              countries unite to pioneer the North American Industry
              Classification System (NAICS) project. This collaboration lays the
              foundation for a new era of economic classification.
            </div>
            <div style={{ marginBottom: '24px' }}>
              In 1997, NAICS strides into official existence, supplanting the
              SIC system in the United States, Canada, and Mexico. NAICS 1997
              revolutionizes classification by providing a flexible and
              comprehensive taxonomy based on industries' economic activities.
            </div>
            <div style={{ marginBottom: '24px' }}>
              The dawn of 2002 witnesses the inaugural major revision – NAICS
              2002 – designed to accommodate emerging industries and
              technological shifts. This iteration is a result of collective
              input, enhancing both coverage and accuracy.
            </div>
            <div style={{ marginBottom: '24px' }}>
              Continual progression is manifest in NAICS 2007, released to
              harmonize even closer with contemporary business practices and
              mirror the evolving economic landscape.
            </div>
            <div style={{ marginBottom: '24px' }}>
              Enter 2012, and NAICS 2012 comes to the fore. Designed to
              encapsulate new industries, notably e-commerce, it bolsters
              classification precision in reflection of the intricate business
              milieu.
            </div>

            <div>
              The narrative endures with ongoing revisions and updates, ensuring
              NAICS remains in sync with emerging sectors, technological
              advancements, and the evolving economic tapestry. This commitment
              ensures NAICS' perennial relevance and responsiveness to the
              ever-transforming world of business.
            </div>

            <h2
              className="block-title"
              style={{ textAlign: 'center', paddingTop: '56px' }}
              ref={timelineRef}
            >
              Timeline of NAICS
            </h2>

            <embed src={naicsTimeline} />

            <h2
              className="block-title"
              style={{
                textAlign: 'center',
                paddingTop: '56px',
                paddingBottom: '16px'
              }}
              ref={usageRef}
            >
              Usage & Importance of NAICS
            </h2>

            <div className="usage-table">
              <div className="usage-table-left">
                <h4>Usage</h4>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Cross-Border Comparisons:</strong> NAICS enables
                    consistent industry classification across North American
                    countries, facilitating meaningful cross-border comparisons
                    of economic data and trade flows.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Trade Analysis:</strong> Companies append SIC codes
                    to their customer list to determine what industries their
                    customers operate in. For future marketing campaigns,
                    companies know which industries to further market to{' '}
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Development:</strong> Policymakers rely on
                    NAICS classifications to formulate targeted regulations,
                    incentives, and economic policies that promote industry
                    growth and competitiveness.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Data Analysis:</strong> NAICS enhances the accuracy
                    and reliability of economic statistics, providing a common
                    framework for researchers and analysts to study industry
                    trends and economic performance.
                  </li>
                </ul>
              </div>
              <div className="usage-table-right">
                <h4>Importance</h4>
                <ul>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Regional Consistency:</strong> NAICS fosters a
                    unified understanding of economic activities, promoting
                    consistent reporting and analysis across North American
                    countries.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    <strong>Policy Coordination:</strong> By aligning industry
                    classifications, NAICS supports coordinated policy efforts,
                    facilitating effective regulatory harmonization and trade
                    negotiations.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    {' '}
                    <strong>Global Engagement:</strong> NAICS enhances the
                    region's ability to engage in international economic
                    discussions and trade partnerships with a shared
                    classification language.
                  </li>
                  <li style={{ fontSize: '16px' }}>
                    {' '}
                    <strong>Informed Decision-Making:</strong> NAICS empowers
                    governments, businesses, and researchers with accurate and
                    comparable industry data, enabling informed strategic
                    decisions and robust economic research.
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </section>
      </Box>
      <Footer />
    </div>
  );
}
